import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formula } from '../types';
import { formulas } from '../data/formulas';
import apiService from '../../../../Services/apiService';

const Container = styled.div`
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const SimulatorForm = styled.form`
  display: grid;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-weight: 500;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
`;

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background: #0056b3;
  }
`;

const Results = styled.div`
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 4px;
`;

const RaceSelector = styled.div`
  margin-bottom: 2rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 4px;
`;

const RaceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const RaceCard = styled.div<{ selected: boolean }>`
  padding: 1rem;
  border: 2px solid ${props => props.selected ? '#007bff' : '#dee2e6'};
  border-radius: 4px;
  cursor: pointer;
  background: ${props => props.selected ? '#f8f9fa' : 'white'};

  &:hover {
    border-color: #007bff;
  }
`;

const RaceDetails = styled.div`
    margin-top: 1rem;
    padding: 1rem;
    background: white;
    border-radius: 4px;
    border: 1px solid #dee2e6;
`;

const RunnerGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
`;

const RunnerCard = styled.div`
    padding: 0.75rem;
    background: #f8f9fa;
    border-radius: 4px;
    font-size: 0.9rem;
`;

const PredictionGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    margin: 1rem 0;
`;

const PredictionCard = styled.div`
    padding: 0.75rem;
    background: #f8f9fa;
    border-radius: 4px;
    text-align: center;

    strong {
        display: block;
        margin-bottom: 0.5rem;
        color: #007bff;
    }
`;

interface RaceDetails {
    runners?: Array<{
        runnerNumber: number;
        runnerName: string;
        barrier: number;
        handicapWeight: number;
        jockeyName: string;
        trainerName: string;
        form: string;
    }>;
    prizeMoney?: number;
    raceDistance?: number;
    raceStatus?: string;
    predictions?: {
        stabilityScore?: number;
        performanceIndex?: number;
        riskAssessment?: number;
        trackConditionImpact?: number;
        competitiveEdge?: number;
        recoveryRate?: number;
        seasonalAdjustment?: number;
        geneticPotential?: number;
    };
}

interface Race {
    raceStartTime: string;
    raceNumber: number;
    raceName: string;
    meeting: {
        meetingName: string;
        location: string;
        raceType: string;
        weatherCondition: string;
        trackCondition: string;
        meetingDate: string;
        venueMnemonic: string;
    };
    details?: RaceDetails;
    _links?: {
        races?: string;
    };
    predictions?: {
        stabilityScore?: number;
        performanceIndex?: number;
        riskAssessment?: number;
        trackConditionImpact?: number;
        competitiveEdge?: number;
        recoveryRate?: number;
        seasonalAdjustment?: number;
        geneticPotential?: number;
    };
}

interface SimulatorState {
  selectedFormula: Formula | null;
  parameters: Record<string, number>;
  result: number | null;
}

const Simulator: React.FC = () => {
  const [selectedFormula, setSelectedFormula] = useState<Formula | null>(null);
  const [parameters, setParameters] = useState<Record<string, number>>({});
  const [result, setResult] = useState<number | null>(null);
  const [nextToGoRaces, setNextToGoRaces] = useState<Race[]>([]);
  const [selectedRace, setSelectedRace] = useState<Race | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchNextToGoRaces();
  }, []);

  const fetchNextToGoRaces = async () => {
    try {
      setLoading(true);
      const response = await apiService.fetchNextToGoRaces();
      setNextToGoRaces(response.races);
    } catch (error) {
      console.error('Error fetching next to go races:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormulaChange = (formulaName: string): void => {
    const formula = formulas.find(f => f.name === formulaName) || null;
    setSelectedFormula(formula);
    setParameters({});
    setResult(null);
  };

  const handleParameterChange = (paramName: string, value: string): void => {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      setParameters(prev => ({
        ...prev,
        [paramName]: numValue
      }));
    }
  };

  const calculateResult = (e: React.FormEvent): void => {
    e.preventDefault();
    if (!selectedFormula || !selectedRace) return;

    let calculatedResult = 0;

    if (selectedRace.predictions && selectedFormula.name in selectedRace.predictions) {
        calculatedResult = selectedRace.predictions[selectedFormula.name as keyof typeof selectedRace.predictions] || 0;
    } else {
        switch (selectedFormula.name) {
            case "Track Condition Impact":
                calculatedResult = calculateTrackConditionImpact(selectedRace, parameters);
                break;
            case "Seasonal Performance Adjustment":
                calculatedResult = calculateSeasonalAdjustment(selectedRace, parameters);
                break;
            case "Performance Index":
                calculatedResult = calculatePerformanceIndex(selectedRace, parameters);
                break;
            default:
                calculatedResult = Object.values(parameters).reduce((a, b) => a + b, 0);
        }
    }

    setResult(calculatedResult);
  };

  const calculateTrackConditionImpact = (race: Race, params: Record<string, number>): number => {
    const { weatherCondition, trackCondition } = race.meeting;
    const weatherFactor = weatherCondition === 'FINE' ? 1.2 : 0.8;
    const trackFactor = trackCondition === 'GOOD' ? 1.1 : 0.9;

    return (params.W || 1) * weatherFactor * (params.S || 1) * trackFactor;
  };

  const calculateSeasonalAdjustment = (race: Race, params: Record<string, number>): number => {
    const date = new Date(race.raceStartTime);
    const month = date.getMonth();
    const seasonFactor = month >= 5 && month <= 8 ? 0.9 : 1.1; // Winter adjustment

    return (params.T || 1) * (params.H || 1) * seasonFactor;
  };

  const calculatePerformanceIndex = (race: Race, params: Record<string, number>): number => {
    if (!race.details?.runners?.length) return 0;

    // Calculate average weight
    const avgWeight = race.details.runners.reduce(
      (sum, runner) => sum + runner.handicapWeight,
      0
    ) / race.details.runners.length;

    // Use the Performance Index formula: PI = (S * E * A) / (D * L)
    return (
      ((params.S || 1) * (params.E || 1) * (params.A || 1)) /
      ((race.details.raceDistance || 1000) / 1000 * (avgWeight / 55))
    );
  };

  const renderRaceDetails = (race: Race) => {
    if (!race.details) return null;

    return (
        <RaceDetails>
            <h4>Race Details</h4>
            <p>Distance: {race.details.raceDistance}m</p>
            <p>Prize Money: ${race.details.prizeMoney?.toLocaleString()}</p>
            <p>Status: {race.details.raceStatus}</p>

            {race.predictions && (
                <div>
                    <h4>Calculated Predictions</h4>
                    <PredictionGrid>
                        {Object.entries(race.predictions).map(([key, value]) => (
                            <PredictionCard key={key}>
                                <strong>{formatPredictionName(key)}</strong>
                                <p>{typeof value === 'number' ? value.toFixed(2) : value}</p>
                            </PredictionCard>
                        ))}
                    </PredictionGrid>
                </div>
            )}

            {race.details.runners && race.details.runners.length > 0 && (
                <>
                    <h5>Runners</h5>
                    <RunnerGrid>
                        {race.details.runners.map(runner => (
                            <RunnerCard key={runner.runnerNumber}>
                                <strong>{runner.runnerNumber}. {runner.runnerName}</strong>
                                <p>Barrier: {runner.barrier}</p>
                                <p>Weight: {runner.handicapWeight}kg</p>
                                <p>Jockey: {runner.jockeyName}</p>
                                <p>Trainer: {runner.trainerName}</p>
                                <p>Form: {runner.form}</p>
                            </RunnerCard>
                        ))}
                    </RunnerGrid>
                </>
            )}
        </RaceDetails>
    );
  };

  const formatPredictionName = (key: string): string => {
    return key
        .split(/(?=[A-Z])/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
  };

  return (
    <Container>
      <h2>Race Prediction Simulator</h2>

      <RaceSelector>
        <h3>Next To Go Races</h3>
        {loading ? (
          <p>Loading races...</p>
        ) : (
          <RaceGrid>
            {nextToGoRaces.map((race) => (
              <RaceCard
                key={`${race.meeting.meetingName}-${race.raceNumber}`}
                selected={selectedRace === race}
                onClick={() => setSelectedRace(race)}
              >
                <h4>{race.meeting.meetingName} R{race.raceNumber}</h4>
                <p>{race.raceName}</p>
                <small>
                  {race.meeting.raceType} | {race.meeting.weatherCondition} | {race.meeting.trackCondition}
                </small>
              </RaceCard>
            ))}
          </RaceGrid>
        )}
      </RaceSelector>

      <SimulatorForm onSubmit={calculateResult}>
        <InputGroup>
          <Label>Select Formula</Label>
          <Select onChange={(e) => handleFormulaChange(e.target.value)}>
            <option value="">Select a formula...</option>
            {formulas.map(formula => (
              <option key={formula.name} value={formula.name}>
                {formula.name}
              </option>
            ))}
          </Select>
        </InputGroup>

        {selectedFormula?.parameters?.map(param => (
          <InputGroup key={param.name}>
            <Label>{param.name}</Label>
            <Input
              type="number"
              step="0.1"
              placeholder={`Enter ${param.name}`}
              value={parameters[param.name] || ''}
              onChange={(e) => handleParameterChange(param.name, e.target.value)}
            />
            {param.range && <small>Range: {param.range}</small>}
          </InputGroup>
        ))}

        {selectedFormula && selectedRace && (
          <Button type="submit">Calculate</Button>
        )}

        {result !== null && (
          <Results>
            <h3>Simulation Result</h3>
            <p>Score: {result.toFixed(2)}</p>
            {selectedRace && (
              <div>
                <h4>Race Conditions</h4>
                <p>Weather: {selectedRace.meeting.weatherCondition}</p>
                <p>Track: {selectedRace.meeting.trackCondition}</p>
                <p>Race Type: {selectedRace.meeting.raceType}</p>
              </div>
            )}
          </Results>
        )}
      </SimulatorForm>

      {selectedRace && renderRaceDetails(selectedRace)}
    </Container>
  );
};

export default Simulator;