import React from 'react';
import styled from 'styled-components';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, PieChart, Pie, Cell,
} from 'recharts';
import { Collapse } from 'antd';
import { RacingAnalyticsDashboardProps } from './types';

const { Panel } = Collapse;

const DashboardContainer = styled.div`
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  overflow: hidden;
  max-width: 100%;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
`;

const StatCard = styled.div`
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  text-align: center;

  h3 {
    margin: 0;
    color: #666;
    font-size: 14px;
  }

  .value {
    font-size: 24px;
    font-weight: bold;
    color: #1a1a1a;
    margin: 10px 0;
  }
`;

const ChartSection = styled.div`
  height: 300px;
  margin: 16px 0;
  width: 100%;
  overflow: hidden;
`;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const PlacingStatsCard = styled(StatCard)`
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  border: 1px solid #dee2e6;
`;

const PlacingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin: 16px 0;
`;

const PlacingStat = styled.div<{ performance: 'good' | 'average' | 'poor' }>`
  padding: 12px;
  border-radius: 8px;
  background: ${({ performance }) => {
    switch (performance) {
      case 'good': return 'linear-gradient(135deg, #e8f5e9 0%, #c8e6c9 100%)';
      case 'average': return 'linear-gradient(135deg, #fff3e0 0%, #ffe0b2 100%)';
      case 'poor': return 'linear-gradient(135deg, #ffebee 0%, #ffcdd2 100%)';
    }
  }};
  text-align: center;

  .position {
    font-size: 20px;
    font-weight: bold;
    color: ${({ performance }) => {
    switch (performance) {
      case 'good': return '#2e7d32';
      case 'average': return '#f57c00';
      case 'poor': return '#c62828';
    }
  }};
  }

  .rate {
    font-size: 24px;
    font-weight: bold;
    margin: 8px 0;
  }

  .label {
    font-size: 12px;
    color: #666;
  }
`;

const DetailedStatsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 16px 0;

  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }

  th {
    background: #f8f9fa;
    font-weight: 500;
    color: #666;
  }

  td {
    font-size: 14px;
  }
`;

export const RacingAnalyticsDashboard: React.FC<RacingAnalyticsDashboardProps> = ({
  predictionStats,
  historicalData,
  bettingTrends,
  accuracyStats,
  bettingAnalysis
}) => {
  // Helper function to calculate average odds safely
  const calculateAverageOdds = (returns: number, bets: number, divisor: number = 1): string => {
    if (!bets || bets === 0) return '0.00';
    return (returns / bets / divisor).toFixed(2);
  };

  // Helper function to calculate ROI safely
  const calculateROI = (profit: number, bets: number): string => {
    if (!bets || bets === 0) return '0.0';
    return ((profit / bets) * 100).toFixed(1);
  };

  // Helper function to determine performance level
  const getPerformanceLevel = (rate: number): 'good' | 'average' | 'poor' => {
    if (rate >= 35) return 'good';
    if (rate >= 25) return 'average';
    return 'poor';
  };

  return (
    <DashboardContainer>
      <StatsGrid>
        <StatCard>
          <h3>Total Races Analyzed</h3>
          <div className="value">{accuracyStats.totalRaces}</div>
        </StatCard>
        <StatCard>
          <h3>Exact First Place</h3>
          <div className="value">{accuracyStats.exactOrder.first.toFixed(1)}%</div>
        </StatCard>
        <StatCard>
          <h3>Any in First Four</h3>
          <div className="value">{accuracyStats.anyOrder.first.toFixed(1)}%</div>
        </StatCard>
        <StatCard>
          <h3>All Four (Any Order)</h3>
          <div className="value">{accuracyStats.anyOrder.allFour.toFixed(1)}%</div>
        </StatCard>
      </StatsGrid>

      <Collapse defaultActiveKey={['1', '2', '3', '4', '5']}>
        <Panel header="Prediction Accuracy Over Time" key="1">
          <ChartSection>
            <ResponsiveContainer>
              <LineChart data={historicalData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={{ fontSize: 12 }} />
                <YAxis domain={[0, 100]} tick={{ fontSize: 12 }} />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="exactOrderAccuracy"
                  stroke="#8884d8"
                  name="Exact Order"
                />
                <Line
                  type="monotone"
                  dataKey="anyOrderAccuracy"
                  stroke="#82ca9d"
                  name="Any Order"
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartSection>
        </Panel>

        <Panel header="Prediction Distribution" key="2">
          <ChartSection>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={bettingTrends}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(1)}%`}
                >
                  {bettingTrends.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </ChartSection>
        </Panel>

        <Panel header="Position Accuracy" key="3">
          <ChartSection>
            <div style={{ padding: '20px' }}>
              <h4>Exact Order Accuracy</h4>
              <div style={{ marginBottom: '20px' }}>
                <div>First Place: {accuracyStats.exactOrder.first.toFixed(1)}%</div>
                <div>First Two: {accuracyStats.exactOrder.firstTwo.toFixed(1)}%</div>
                <div>First Three: {accuracyStats.exactOrder.firstThree.toFixed(1)}%</div>
                <div>All Four: {accuracyStats.exactOrder.allFour.toFixed(1)}%</div>
              </div>

              <h4>Any Order Accuracy</h4>
              <div>
                <div>Any in First: {accuracyStats.anyOrder.first.toFixed(1)}%</div>
                <div>Any Two in First Two: {accuracyStats.anyOrder.firstTwo.toFixed(1)}%</div>
                <div>Any Three in First Three: {accuracyStats.anyOrder.firstThree.toFixed(1)}%</div>
                <div>All Four in Any Order: {accuracyStats.anyOrder.allFour.toFixed(1)}%</div>
              </div>
            </div>
          </ChartSection>
        </Panel>

        <Panel header="Form Analysis" key="4">
          <ChartSection>
            <ResponsiveContainer>
              <LineChart data={historicalData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={{ fontSize: 12 }} />
                <YAxis tick={{ fontSize: 12 }} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="formRating" stroke="#8884d8" name="Form Rating" />
                <Line type="monotone" dataKey="speedRating" stroke="#82ca9d" name="Speed Rating" />
                <Line type="monotone" dataKey="classRating" stroke="#ffc658" name="Class Rating" />
              </LineChart>
            </ResponsiveContainer>
          </ChartSection>
        </Panel>

        <Panel header="Place Prediction Analysis" key="5">
          <PlacingGrid>
            <PlacingStat performance={getPerformanceLevel(accuracyStats.anyOrder.first)}>
              <div className="position">1st</div>
              <div className="rate">{accuracyStats.anyOrder.first.toFixed(1)}%</div>
              <div className="label">Win Strike Rate</div>
            </PlacingStat>
            <PlacingStat performance={getPerformanceLevel(accuracyStats.anyOrder.firstTwo)}>
              <div className="position">2nd</div>
              <div className="rate">{accuracyStats.anyOrder.firstTwo.toFixed(1)}%</div>
              <div className="label">Place Strike Rate</div>
            </PlacingStat>
            <PlacingStat performance={getPerformanceLevel(accuracyStats.anyOrder.firstThree)}>
              <div className="position">3rd</div>
              <div className="rate">{accuracyStats.anyOrder.firstThree.toFixed(1)}%</div>
              <div className="label">Show Strike Rate</div>
            </PlacingStat>
          </PlacingGrid>

          <DetailedStatsTable>
            <thead>
              <tr>
                <th>Placing Type</th>
                <th>Strike Rate</th>
                <th>ROI</th>
                <th>Avg. Odds</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Win</td>
                <td>{predictionStats.winStrikeRate.toFixed(1)}%</td>
                <td>{calculateROI(
                  bettingAnalysis.betsPerStrategy.winHighestOdds.profit,
                  bettingAnalysis.betsPerStrategy.winHighestOdds.totalBets
                )}%</td>
                <td>${calculateAverageOdds(
                  bettingAnalysis.totalReturns,
                  bettingAnalysis.totalBets
                )}</td>
              </tr>
              <tr>
                <td>Place</td>
                <td>{predictionStats.placeStrikeRate.toFixed(1)}%</td>
                <td>{calculateROI(
                  bettingAnalysis.betsPerStrategy.placeSecond.profit,
                  bettingAnalysis.betsPerStrategy.placeSecond.totalBets
                )}%</td>
                <td>${calculateAverageOdds(
                  bettingAnalysis.totalReturns,
                  bettingAnalysis.totalBets,
                  2
                )}</td>
              </tr>
              <tr>
                <td>Each-Way</td>
                <td>{((bettingAnalysis.betsPerStrategy.eachWayTop.wins +
                       bettingAnalysis.betsPerStrategy.eachWayTop.places) /
                      bettingAnalysis.betsPerStrategy.eachWayTop.totalBets * 100).toFixed(1)}%</td>
                <td>{calculateROI(
                  bettingAnalysis.betsPerStrategy.eachWayTop.profit,
                  bettingAnalysis.betsPerStrategy.eachWayTop.totalBets
                )}%</td>
                <td>${calculateAverageOdds(
                  bettingAnalysis.totalReturns,
                  bettingAnalysis.totalBets,
                  1.5
                )}</td>
              </tr>
            </tbody>
          </DetailedStatsTable>

          <ChartSection>
            <ResponsiveContainer>
              <LineChart data={historicalData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={{ fontSize: 12 }} />
                <YAxis domain={[0, 100]} tick={{ fontSize: 12 }} />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="anyOrderAccuracy"
                  stroke="#82ca9d"
                  name="Place Strike Rate"
                />
                <Line
                  type="monotone"
                  dataKey="marketConfidence"
                  stroke="#8884d8"
                  name="Market Confidence"
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartSection>
        </Panel>
      </Collapse>
    </DashboardContainer>
  );
};