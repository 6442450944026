import { Formula } from '../types';

export const formulas: Formula[] = [
  {
    name: "Stability Score",
    purpose: "Core algorithm for evaluating horse racing performance stability",
    calculation: "Combines multiple factors to assess overall stability and predictability",
    formula: "SS = (K * P * E * R) / (C * F * PP * FI) * T * A",
    parameters: [
      {
        name: "K (Control)",
        description: "Jockey control and rapport with horse",
        range: "1-10"
      },
      {
        name: "P (Predictability)",
        description: "Past performance consistency",
        range: "1-10"
      },
      {
        name: "E (Efficiency)",
        description: "Speed and energy management",
        range: "1-10"
      },
      {
        name: "R (Resilience)",
        description: "Adaptability to track conditions",
        range: "1-10"
      },
      {
        name: "C (Complexity)",
        description: "Simplicity in performance variables",
        range: "1-10"
      },
      {
        name: "F (Flexibility)",
        description: "Adaptable performance in conditions",
        range: "1-10"
      },
      {
        name: "PP (Potential Problems)",
        description: "Fewer issues like injuries",
        range: "1-10"
      },
      {
        name: "FI (Failure Impact)",
        description: "Minimal impact from poor performance",
        range: "1-10"
      },
      {
        name: "T (Trend Factor)",
        description: "Improving historical trends",
        range: "1-10"
      },
      {
        name: "A (Adjustment Factor)",
        description: "Positive expert tips",
        range: "1-10"
      }
    ]
  },
  {
    name: "Performance Index",
    purpose: "Measures overall performance efficiency",
    calculation: "Evaluates the efficiency of performance across various metrics",
    formula: "PI = (S * E * A) / (D * L)",
    parameters: [
      {
        name: "S (Speed)",
        description: "Average speed during races",
        range: "1-10"
      },
      {
        name: "E (Endurance)",
        description: "Ability to maintain performance over time",
        range: "1-10"
      },
      {
        name: "A (Agility)",
        description: "Quickness and maneuverability",
        range: "1-10"
      },
      {
        name: "D (Distance)",
        description: "Average distance covered",
        range: "1-10"
      },
      {
        name: "L (Load)",
        description: "Weight carried during performance",
        range: "1-10"
      }
    ]
  },
  {
    name: "Risk Assessment",
    purpose: "Evaluates potential risks in performance",
    calculation: "Assesses risk factors that could impact performance",
    formula: "RA = (R * V) / (M * S)",
    parameters: [
      {
        name: "R (Risk Factor)",
        description: "Overall risk level",
        range: "1-10"
      },
      {
        name: "V (Volatility)",
        description: "Variability in performance",
        range: "1-10"
      },
      {
        name: "M (Mitigation)",
        description: "Measures in place to reduce risk",
        range: "1-10"
      },
      {
        name: "S (Stability)",
        description: "Consistency in performance",
        range: "1-10"
      }
    ]
  },
  {
    name: "Track Condition Impact",
    purpose: "Calculates the impact of track conditions on performance",
    calculation: "Evaluates how different track conditions affect racing outcomes",
    formula: "TCI = (W * S * D) * (1 + G/10) * (1 - M/10)",
    parameters: [
      {
        name: "W (Weather)",
        description: "Current weather conditions impact",
        range: "1-10"
      },
      {
        name: "S (Surface)",
        description: "Track surface quality",
        range: "1-10"
      },
      {
        name: "D (Drainage)",
        description: "Track drainage effectiveness",
        range: "1-10"
      },
      {
        name: "G (Grade)",
        description: "Track gradient factor",
        range: "1-10"
      },
      {
        name: "M (Maintenance)",
        description: "Recent track maintenance quality",
        range: "1-10"
      }
    ]
  },
  {
    name: "Competitive Edge",
    purpose: "Determines relative advantage against competition",
    calculation: "Analyzes competitive advantages in racing conditions",
    formula: "CE = ((H + F + T) * E) / (C * D)",
    parameters: [
      {
        name: "H (Historical)",
        description: "Past performance against competitors",
        range: "1-10"
      },
      {
        name: "F (Form)",
        description: "Current form rating",
        range: "1-10"
      },
      {
        name: "T (Training)",
        description: "Training preparation quality",
        range: "1-10"
      },
      {
        name: "E (Experience)",
        description: "Racing experience level",
        range: "1-10"
      },
      {
        name: "C (Competition)",
        description: "Strength of competition",
        range: "1-10"
      },
      {
        name: "D (Difficulty)",
        description: "Race difficulty factor",
        range: "1-10"
      }
    ]
  },
  {
    name: "Recovery Rate",
    purpose: "Measures post-race recovery efficiency",
    calculation: "Evaluates recovery time and effectiveness between races",
    formula: "RR = (P * R * N) / (S * I)",
    parameters: [
      {
        name: "P (Physical)",
        description: "Physical condition post-race",
        range: "1-10"
      },
      {
        name: "R (Rest)",
        description: "Quality of rest period",
        range: "1-10"
      },
      {
        name: "N (Nutrition)",
        description: "Nutritional support quality",
        range: "1-10"
      },
      {
        name: "S (Stress)",
        description: "Accumulated stress level",
        range: "1-10"
      },
      {
        name: "I (Intensity)",
        description: "Previous race intensity",
        range: "1-10"
      }
    ]
  },
  {
    name: "Seasonal Performance Adjustment",
    purpose: "Adjusts performance metrics based on seasonal factors",
    calculation: "Accounts for seasonal variations in performance",
    formula: "SPA = (T * H * A) * (1 + S/10) / C",
    parameters: [
      {
        name: "T (Temperature)",
        description: "Temperature impact on performance",
        range: "1-10"
      },
      {
        name: "H (Humidity)",
        description: "Humidity impact on performance",
        range: "1-10"
      },
      {
        name: "A (Adaptation)",
        description: "Seasonal adaptation level",
        range: "1-10"
      },
      {
        name: "S (Seasonality)",
        description: "Historical seasonal performance",
        range: "1-10"
      },
      {
        name: "C (Climate)",
        description: "Overall climate condition factor",
        range: "1-10"
      }
    ]
  },
  {
    name: "Genetic Potential Index",
    purpose: "Evaluates inherited performance capabilities",
    calculation: "Analyzes genetic factors influencing performance potential",
    formula: "GPI = (L * B * P) * (1 + H/10)",
    parameters: [
      {
        name: "L (Lineage)",
        description: "Quality of breeding line",
        range: "1-10"
      },
      {
        name: "B (Breed)",
        description: "Breed-specific advantages",
        range: "1-10"
      },
      {
        name: "P (Physical)",
        description: "Inherited physical attributes",
        range: "1-10"
      },
      {
        name: "H (Heritage)",
        description: "Historical family performance",
        range: "1-10"
      }
    ]
  }
];