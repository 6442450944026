import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { differenceInMinutes, format, formatDistanceToNow, subDays } from 'date-fns';
import {
    FaChevronLeft,
    FaChevronRight,
    FaHorse,
    FaCalendarAlt,
    FaTrophy,
    FaCheckCircle,
    FaExchangeAlt,
    FaShieldAlt,
    FaStar,
    FaUser,
    FaUserFriends,
    FaMix,
    FaClock,
    FaShare,
    FaPlus,
    FaList,
    FaPlay,
    FaChartLine,
    FaUsers,
    FaCopy,
    FaBookOpen,
    FaQuestionCircle,
    FaCoins,
    Fa500Px,
    FaAcquisitionsIncorporated,
} from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { WiDaySunny, WiCloudy } from 'react-icons/wi';
// Note: Replace '../../../Services/apiService' with your actual API service path
import apiService from '../../../Services/apiService';
import { ShareableData } from '../types';
import { Race } from '../../../Services/bettingService';
import { RacingAnalyticsDashboard } from '../RacingAnalyticsDashboard';
import { Meeting } from '../../../Services/apiTypes';
import RacingTutorial from '../Tutorial';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

const Container = styled.div`
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 16px;
  background: #2c1f56;
  color: white;
`;

const CurrentProcessInfo = styled.div`
    background: rgba(255, 255, 255, 0.1);
    padding: 14px 24px;
    border-radius: 8px;
    margin-top: 20px;
    color: #fff;
    font-size: 16px;
    max-width: 600px;
    border: 1px solid rgba(255, 255, 255, 0.2);
`;

const HeaderTitle = styled.h1`
  font-size: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
`;

const FilterSection = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;
`;

const DateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 12px;
  padding: 16px;
`;

const DateCard = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => (selected ? '#40B549' : 'white')};
  color: ${({ selected }) => (selected ? 'white' : '#333')};
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  border: 1px solid ${({ selected }) => (selected ? '#40B549' : '#e5e5e5')};
  transition: all 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Add this line for box shadow

  &:hover {
    border-color: #40B549;
  }

  svg {
    font-size: 20px;
    margin-bottom: 8px;
    color: ${({ selected }) => selected ? 'white' : '#40B549'};
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  margin-top: -18px;
`;

// Define the TOP_JOCKEYS array
const TOP_JOCKEYS = [
    'JAMES MCDONALD',
    'DAMIEN OLIVER',
    'CRAIG WILLIAMS',
    'HUGH BOWMAN',
    'WILLIAM PIKE',
    'KERRIN MCEVOY',
    'NASH RAWILLER',
    'DAMIAN LANE',
    'BRETT PREBBLE',
    'GLEN BOSS',
    'TOMMY BERRY',
    'BLAKE SHINN',
    'BRENTON AVDULLA',
    'MARK ZAHRA',
    'BEN MELHAM',
    'JAMIE KAH',
    'RACHEL KING',
    'LINDA MEECH',
    'MICHAEL RODD',
    'DWAYNE DUNN',
    'LUKE NOLEN',
    'BRAD RAWILLER',
    'MICHAEL WALKER',
    'JASON COLLETT',
    'TIM CLARK',
    'LUKE CURRIE',
    'DEAN YENDALL',
    'BRAD STEWART',
    'JIM BYRNE',
    'JEFF LLOYD',
    'ROBBIE FRADD',
    'LARRY CASSIDY',
    'CHRIS SYMONS',
    'STEVEN ARNOLD',
    'COREY BROWN',
    'CHAD SCHOFIELD',
    'DANIEL STACKHOUSE',
    'DEAN HOLLAND',
    'MICHAEL DEE',
    'BEN THOMPSON',
    'JORDAN CHILDS',
    'JAKE NOONAN',
    'PATRICK MOLONEY',
    'STEPHANIE THORNTON',
    'BEAU MERTENS',
    'CRAIG NEWITT',
    'BRENDON MCCOULL',
    'PAUL GATT',
    'TODD PANNELL',
    'DOM TOURNEUR',
    'WILLIAM BUICK',
    'CRAIG NEWITT',
    'DAMIEN THORNTON',
    'JOHN ALLEN',
    'BEN ALLEN',
    'MICKAEL BARZALONA',
    'JASON MORRIS',
    'RYAN MOORE',
    'HOLLIE DOYLE',
    'ETHAN BROWN',
    'JESS EATON',
    'BILLY EGAN',
    'FRED KERSLEY',
    'THEODORE NUGENT',
    'MADISON LLOYD',
    'WILL PRICE',
    'LACHLAN KING',
    'LEWIS GERMAN',
    'CAMPBELL RAWILLER',
    'HARRY COFFEY',
    'DECLAN BATES',
    'JARROD FRY',
    'ZACH SPAIN',
    'JESSICA PAYNE',
    'MITCHELL AITKEN',
    'LAURA LAFFERTY',
    'ALANA KELLY',
    'SHERIDAN CLARKE',
    'MELISSA JULIUS',
    'TATUM BULL',
    'LACHLAN NEINDORF',
    'KAYLA CROWTHER',
    'JACOB OPPERMAN',
    'BEN PRICE',
    'TEAGAN VOORHAM',
    'CAITLIN JONES',
    'SOPHIE LOGAN',
    'GARY LO',
    'BRANDON GRIFFITHS',
    'DYLAN GIBBONS',
    'REECE JONES',
    'TYLER SCHILLER',
    'JENNY DUGGAN',
    'LOUISE DAY',
    'ELLEN HENNESSY',
    'JEAN VAN OVERMEIRE',
    'BROCK RYAN',
    'ALYSHA COLLETT',
    'SAM CLIPPERTON',
    'REGAN BAYLISS',
    'KATHY OHARA',
    'KEAGAN LATHAM',
    'GRANT BUCKLEY',
    'ASHLEY MORGAN'
];

// Define the JockeyName styled component
const JockeyName = styled.span<{ isTopJockey: boolean }>`
    color: ${({ isTopJockey }) => isTopJockey ? '#2c1f56' : '#666'};
    font-weight: ${({ isTopJockey }) => isTopJockey ? '600' : 'normal'};
    ${({ isTopJockey }) => isTopJockey && css`
        animation: jockeyFlicker 2s infinite;
        background: ${({ theme }) => theme.colors?.highlight || '#f0f8ff'};
        padding: 2px 4px;
        border-radius: 4px;
    `}

    @keyframes jockeyFlicker {
        0%, 100% { opacity: 1; }
        50% { opacity: 0.7; }
    }
`;

// Ensure the JockeyInfo component is defined
const JockeyInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 11px;
    margin-top: 2px;

    svg {
        color: #40B549;
        font-size: 10px;
    }
`;

// Update the WinnerIndicator to handle different positions
const WinnerIndicator = styled.div<{ position: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 160px;
  font-weight: 800;
  color: ${({ position }) => {
    switch (position) {
      case 1:
        return 'rgba(255, 215, 0, 0.15)'; // Gold
      case 2:
        return 'rgba(192, 192, 192, 0.15)'; // Silver
      case 3:
        return 'rgba(205, 127, 50, 0.15)'; // Bronze
      default:
        return 'rgba(128, 128, 128, 0.1)'; // Grey
    }
  }};
  z-index: 0;
  pointer-events: none;
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StepContent = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  padding: 0;
  margin-top: 16px;
  box-sizing: border-box;
`;

const StepIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  background: white;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    padding: 12px;
    .step-label {
      display: none;
    }
  }
`;

const StepItem = styled.div<{ active: boolean; clickable?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  padding: 5px 10px;

  .step-number {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background: ${({ active }) => (active ? '#40B549' : '#e0e0e0')};
    color: white;
  }

  .step-label {
    color: ${({ active }) => (active ? '#333' : '#666')};
    font-size: 13px;
  }
`;

const StepDivider = styled.div`
  flex: 0 0 auto;
  width: 20px;
  height: 1px;
  background: #e0e0e0;
`;

const StepDescription = styled.p`
  text-align: center;
  color: #2c1f56; // Changed to a more prominent color
  margin: 8px 0;
  padding: 10px 20px; // Added more padding for emphasis
  font-size: 16px; // Increased font size
  font-weight: bold; // Made the text bold
  display: inline; // Ensure it stays inline
`;

const TutorialLink = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #40B549;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  text-decoration: underline;

  &:hover {
    color: #379940;
  }
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  min-width: 150px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FilterLabel = styled.label`
  font-size: 14px;
  color: #666;
`;

const FilterSelect = styled.select`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 120px;

  &:focus {
    border-color: #40B549;
    outline: none;
  }
`;

const RaceTypeGroup = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const RaceTypeButton = styled.button<{ active: boolean }>`
  padding: 10px 20px;
  border: 2px solid ${({ active }) => (active ? '#40B549' : '#e0e0e0')};
  background: ${({ active }) =>
        active ? 'linear-gradient(135deg, #40B549 0%, #2D8A34 100%)' : 'white'};
  color: ${({ active }) => (active ? 'white' : '#666')};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
  min-width: 120px;
  font-size: 14px;

  &:hover {
    border-color: #40B549;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

interface Prediction {
    silkUrl: string;
    jockeyName: string;
    ev: number;
    runnerName: string;
    runnerNumber: number;
    prediction: number;
    result: number;
    averageFinishPosition: number;
    consistencyScore: number;
    speedRating: number;
    classLevelFactor: number;
    jockeyWinPercentage: number;
    jockeyPlacePercentage: number;
    trainerSuccessRate: number;
    preferredTrackSurface: boolean;
    weatherConditionFactor: number;
    optimalRaceDistance: boolean;
    ageFactor: number;
    weightFactor: number;
    postPositionFactor: number;
    currentOdds: number;
    bettingVolumeChange: number;
    publicVsProfessionalBetting: number;
    hasRecentInjuries: boolean;
    recoveryTime: number;
    kellyFraction: number;
    probabilityScore: number;
    competitionScore: number;
    stabilityScore: number;
    staminaFactor: number;
    runnerScore: number;
    averageScore: number;
    adjustmentFactor: number;
    finishingStrengthScore: number;
    averageFinishingStrengthScore: number;
    potentialWin: number;
    excluded: boolean;
    runner: RunnerData;
    exactaPairs: any[];
    trifectaSets: any[];
    winOdds: number;
    placeOdds: number;
    showOdds: number;
    winDividend: number;
    placeDividend: number;
    showDividend: number;
    startEfficiency: number;
    fieldStrengthScore: number;
    classPerformance: number;
    fitnessFactor: number;
    trackSuitability: number;
    distanceSuitability: number;
}

interface RunnerData {
    runnerNumber: number;
    runnerName: string;
    stabilityScore: number;
    probabilityOfWinning: number;
    expectedValue: number;
    kellyFraction: number;
    betAmount: number;
    competitionEnergy: number;
    adjustedProbability: number;
    data: RunnerDetails;
    predictedPosition: number;
    actualPosition: number;
    riskScore: number | null;
    adjustmentFactors: AdjustmentFactors;
    calculatedOdds: number;
    impliedProbability: number;
    fractionOdds: string;
    moneyLine: string;
}

interface RunnerDetails {
    prizeMoney: string;
    last20Starts: string | null;
    sire: string;
    dam: string;
    age: number;
    sex: string;
    colour: string;
    formComment: string;
    formComments: FormComment[];
    classLevel: string;
    fieldStrength: number;
    daysSinceLastRun: number;
    handicapWeight: number;
    blinkers: boolean;
    runsSinceSpell: number;
    riderOrDriver: string;
    riderOrDriverSex: string;
    trainerName: string;
    trainerLocation: string;
    runnerStarts: RunnerStarts;
    trainerStarts: TrainerStarts;
    riderDriverStarts: RiderDriverStarts;
}

interface FormComment {
    source: string;
    title: string;
    comment: string;
}

interface StartSummaries {
    overall?: StartSummary;
    track?: StartSummary;
    firm?: StartSummary;
    good?: StartSummary;
    dead?: StartSummary;
    slow?: StartSummary;
    soft?: StartSummary;
    heavy?: StartSummary;
    distance?: StartSummary;
    classSame?: StartSummary;
    classStronger?: StartSummary;
    firstUp?: StartSummary;
    secondUp?: StartSummary;
    trackDistance?: StartSummary;
    last30Days?: StartSummary | null;
    last12Months?: StartSummary | null;
    runner?: StartSummary | null;
    jockey?: StartSummary | null;
    region?: StartSummary | null;
}

interface StartSummary {
    numberOfStarts: number;
    numberOfWins: number;
    numberOfPlacings: number;
}

interface PreviousStart {
    startType: string;
    startDate: string;
    raceNumber: number;
    finishingPosition: string;
    numberOfStarters: number;
    draw: number;
    margin: string | null;
    venueAbbreviation: string;
    distance: number;
    runnerClass: string | null;
    handicap: string | null;
    rider: string | null;
    startingPosition: number;
    odds: string | null;
    winnerOrSecond: string | null;
    positionInRun: string | null;
    trackCondition: string | null;
    time: string | null;
    stewardsComment: string | null;
    runnerName: string | null;
    trainerName: string | null;
}

interface RunnerStarts {
    startSummaries: StartSummaries;
    previousStarts: PreviousStart[];
}

interface TrainerStarts {
    startSummaries: StartSummaries;
    previousStarts: PreviousStart[];
}

interface RiderDriverStarts {
    startSummaries: StartSummaries;
    previousStarts: PreviousStart[];
}

interface AdjustmentFactors {
    ageFactor: number;
    sexFactor: number;
    staminaFactor: number;
    runnerScore: number;
    averageScore: number;
    adjustmentFactor: number;
    finishingStrengthScore: number;
    averageFinishingStrengthScore: number;
}

interface BetRecommendation {
    runnerNumber: number;
    runnerName: string;
    silkUrl: string;
    jockeyName: string;
    ev: number;
    probabilityScore: number;
    stabilityScore: number;
    speedRating: number;
    currentOdds: number;
    recommendedStake: number;
    averageFinishPosition: number;
    consistencyScore: number;
    classLevelFactor: number;
    potentialWin: number;
    excluded: boolean;
    betAmount: number;
    expectedReturn: number;
    recommendedPosition: number;
    kellyFraction: number;
    prediction: number;
    competitionScore: number;
    preferredTrackSurface: boolean;
    optimalRaceDistance: boolean;
    hasRecentInjuries: boolean;
    recoveryTime: number;
    weatherConditionFactor: number;
    bettingVolumeChange: number;
    publicVsProfessionalBetting: number;
    runner: {
        data: {
            prizeMoney: string;
            last20Starts: string | null;
            formComment: string;
            formComments: FormComment[];
            runnerStarts: {
                startSummaries: StartSummaries;
                previousStarts: PreviousStart[];
            };
        };
        predictedPosition: number;
        actualPosition: number;
        adjustmentFactors: AdjustmentFactors;
    };
}

interface RacePrediction {
    meetingName: string;
    raceNumber: number;
    raceName: string;
    venueMnemonic: string;
    predictions: Prediction[] | null;
    startTime: string;
    raceDistance: number;
    error?: string;
    jurisdiction?: string;
    raceType?: string;
    results?: number[];
    meeting?: Meeting;
    exactaDividend?: number;  // Add this
    quinellaDividend?: number;  // Add this
}

interface GroupedRacePredictions {
    [meetingName: string]: RacePrediction[];
}

interface StepInfo {
    label: string;
    description: string;
}

const STEPS: StepInfo[] = [
    {
        label: 'Select Date',
        description: 'Choose a race meeting date from the available options',
    },
    {
        label: 'Select Meetings',
        description: 'Select meetings and process race calculations',
    },
    {
        label: 'View Results',
        description: 'Review calculated race predictions',
    },
    {
        label: 'Finalize Bets',
        description: 'Finalize your selections and proceed',
    },
];

const NavigationBar = styled.div`
  position: fixed;
  bottom: 48px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: white;
  border-top: 1px solid #e5e5e5;
  z-index: 999;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1); // Add this line for top shadow
`;

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: white;
  border-top: 1px solid #eee;
  position: sticky;
  bottom: 0;
`;

const NavigationSteps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Button = styled.button<{ primary?: boolean }>`
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
  min-width: 100px;
  justify-content: center;

  ${({ primary }) =>
        primary
            ? `
        background: #40B549;
        color: white;
        &:hover {
          background: #379940;
        }
      `
            : `
        background: #f5f5f5;
        color: #333;
        &:hover {
          background: #e0e0e0;
        }
      `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CancelButton = styled(Button)`
  margin-top: 20px;
  background: #ff4444;
  color: white;

  &:hover {
    background: #cc0000;
  }
`;

const BetOptions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,0.98);
  padding: 8px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-around;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.2s ease;
  border-radius: 0 0 12px 12px;
  font-size: 11px;
  box-shadow: 0 -2px 6px rgba(0,0,0,0.05);
  z-index: 4;
`;

const RunnerCard = styled.div<{
    rank: number;
    isRecommended?: boolean;
    excluded?: boolean;
    isInResults?: boolean; // New prop to indicate if the runner is in results
    isFormOpen?: boolean;
    isValuePick?: boolean; // Add this prop
}>`
  position: relative;
  background: ${({ isRecommended, excluded, isInResults, isFormOpen }) =>
        excluded ? 'white' :
            isInResults ? 'linear-gradient(to bottom, #ffeb3b, #fffde7)' : // Highlight if in results
                isRecommended ? 'linear-gradient(to bottom, #f0f9f0, white)' : 'white'};
  border: 2px solid ${({ isRecommended, excluded, isInResults, isFormOpen }) =>
        excluded ? '#eee' :
            isInResults ? '#ffeb3b' : // Yellow border if in results
                isRecommended ? '#40B549' : '#eee'};
  border-radius: 12px;
  padding: 20px; // Increased padding for better spacing
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: auto; // Allow height to adjust based on content
  margin-bottom: 16px; // Increased margin for better separation

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);

    ${BetOptions} {
      opacity: 1;
      transform: translateY(0);
    }
  }

  ${({ rank, isRecommended }) => rank === 1 && isRecommended && css`
    animation: ${pulseAndGlow} 2s infinite;
  `}
`;

const SilkImage = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  overflow: hidden;
  background: #f5f5f5;
  border: 1px solid #ddd;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const RunnerIndicators = styled.div`
  position: absolute;
  top: -8px;
  right: 48px; // Increased to avoid overlap with silk
  display: flex;
  gap: 4px;
  align-items: center;
  z-index: 5;
`;

const RunnerHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 48px;
  min-width: 0;
  margin-bottom: 8px;
`;

const RunnerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  padding-top: 8px;
`;

const RunnerStats = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  margin-top: auto;
`;

const StatBox = styled.div`
  background: #f5f5f5;
  padding: 4px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;

  .label {
    color: #666;
    font-size: 10px;
    margin-bottom: 2px;
  }

  .value {
    font-weight: 500;
    color: #333;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin: 8px 0;
`;

const BetTypeTag = styled.span<{ type: string; highlighted?: boolean }>`
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 6px; // Increased gap between icon and text
  box-shadow: ${({ highlighted }) =>
        highlighted ? '0 2px 4px rgba(0,0,0,0.15)' : '0 1px 2px rgba(0,0,0,0.05)'};
  transform: ${({ highlighted }) => highlighted ? 'scale(1.05)' : 'scale(1)'};
  transition: all 0.2s ease;

  svg {
    margin-right: 2px; // Add small space after icon
  }

  ${({ type, highlighted }) => {
        switch (type) {
            case 'win':
                return highlighted
                    ? 'background: linear-gradient(135deg, #4CAF50 0%, #45a049 100%); color: white; border: none;'
                    : 'background: #e8f5e9; color: #2e7d32; border: 1px solid #c8e6c9;';
            case 'place':
                return highlighted
                    ? 'background: linear-gradient(135deg, #2196F3 0%, #1976D2 100%); color: white; border: none;'
                    : 'background: #e3f2fd; color: #1565c0; border: 1px solid #bbdefb;';
            default:
                return 'background: #f5f5f5; color: #666; border: 1px solid #e0e0e0;';
        }
    }}
`;

// Add this keyframes animation near other styled components
const flashAnimation = keyframes`
  0% { background-color: transparent; }
  25% { background-color: rgba(255, 193, 7, 0.2); }
  50% { background-color: rgba(244, 67, 54, 0.2); }
  75% { background-color: rgba(255, 193, 7, 0.2); }
  100% { background-color: transparent; }
`;

// Update the BetMetric styled component
const BetMetric = styled.div<{ highlighted?: boolean; isMatch?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  padding: 3px 6px;
  background: ${({ highlighted }) => highlighted ? '#f8f9fa' : 'transparent'};
  border-radius: 4px;
  width: 100%;
  animation: ${({ isMatch }) => isMatch ? css`${flashAnimation} 2s infinite` : 'none'};

  span:first-child {
    color: #666;
    font-size: 10px;
    margin-right: 6px;
    white-space: nowrap;
  }

  span:last-child {
    font-weight: ${({ highlighted, isMatch }) => (highlighted || isMatch) ? '700' : '600'};
    color: ${({ highlighted, isMatch }) => {
        if (isMatch) return '#f44336';
        return highlighted ? '#2e7d32' : '#333';
    }};
    text-align: right;
    white-space: nowrap;
  }
`;

const BetDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 45px;
  margin-bottom: 24px;
  width: 100%;
`;

const BetCombination = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; // Increased gap between elements
  padding: 4px 8px;
  margin: 6px 0;
`;

const BetAmount = styled.span`
  margin-left: auto;
  font-weight: 500;
  white-space: nowrap;
  padding-left: 8px; // Add space before amount
`;

const BetRunners = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

const VenuePill = styled.div`
  background: #f0f0f0;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;

  @media (max-width: 768px) {
    font-size: 11px;
    padding: 3px 8px;
  }
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  overflow: hidden;
`;

const FilterBar = styled.div`
  padding: 15px;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const FilterChip = styled.div<{ active: boolean }>`
  padding: 4px 8px;
  border-radius: 16px;
  background: ${({ active }) => (active ? '#40B549' : '#fff')};
  color: ${({ active }) => (active ? 'white' : '#666')};
  border: 1px solid ${({ active }) => (active ? '#40B549' : '#ddd')};
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s;

  &:hover {
    border-color: #40B549;
  }
`;

const BankrollSummary = styled.div`
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const ShareButton = styled(Button)`
    background: #4267B2;
    color: white;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
        background: #365899;
    }

    svg {
        font-size: 16px;
    }
`;

const HorizontalTabLayout = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  overflow: hidden;
`;

const HorizontalTabList = styled.div`
  display: flex;
  overflow-x: auto;
  background: white;
  border-bottom: 1px solid #eee;
  padding: 0 40px;
  scrollbar-width: none;
  position: relative;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  width: 100%;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const HorizontalTab = styled.button<{ active: boolean; completed?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  background: ${({ active }) =>
        active
            ? 'linear-gradient(90deg, #40B549 0%, #379940 100%)'
            : 'white'};
  border: none;
  color: ${({ active }) => (active ? '#fff' : '#666')};
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease;
  position: relative;
  text-align: center;
  min-width: 150px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: ${({ active }) =>
        active
            ? 'transparent'
            : 'linear-gradient(90deg, #40B549 0%, #379940 100%)'};
  }

  &:hover {
    background: ${({ active }) =>
        active
            ? 'linear-gradient(90deg, #40B549 0%, #379940 100%)'
            : '#f5f5f5'};
    color: ${({ active }) => (active ? '#fff' : '#333')};
  }

  span {
    margin: 0 auto;
  }
`;

const ScrollButton = styled.button<{ direction: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #40B549;
  border: none;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 11;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);

  &:hover {
    opacity: 1;
  }
`;

const TabListContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const MeetingProgress = styled.div`
  width: 100%;
  height: 4px;
  background: #eee;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 8px;
`;

const ProgressBar = styled.div<{
    progress: number;
    status: 'not-started' | 'in-progress' | 'completed';
}>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background: ${({ status }) => {
        switch (status) {
            case 'completed':
                return 'linear-gradient(90deg, #4CAF50, #45a049)';
            case 'in-progress':
                return 'linear-gradient(90deg, #FF9800, #F57C00)';
            default:
                return 'linear-gradient(90deg, #9E9E9E, #757575)';
        }
    }};
  transition: width 0.3s ease;
`;

const TabContent = styled.div`
  padding: 12px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
`;

const TabHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
`;

const MeetingStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
`;


const RaceSummary = styled.div`
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #eee;
`;


const RaceCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const RaceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 12px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  min-width: 0; // Prevents flex items from overflowing
`;

const RaceInfo = styled.div`
  flex: 1;
  min-width: 0; // Allows text truncation

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const RaceMetadata = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap; // Prevents wrapping
`;

const RaceDistance = styled.span`
  color: #666;
  font-size: 14px;
`;

const RaceTime = styled.span<{ startingSoon?: boolean }>`
  padding: 4px 12px;
  border-radius: 20px;
  background: ${({ startingSoon }) => (startingSoon ? '#fff3e0' : '#f5f5f5')};
  color: ${({ startingSoon }) => (startingSoon ? '#f57c00' : '#666')};
  font-weight: 500;
`;

const RunnersGrid = styled.div`
  display: grid;
  gap: 16px;
  margin: 12px 0;
  padding: 0;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-rows: auto; // Allow rows to adjust based on content

  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1599px) and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const CollaborativeBetting = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  padding: 12px;
  margin: 8px 0;
`;

const BetSplitOption = styled.div`
  margin: 12px 0; // Add more vertical space between friend sections

  strong {
    display: flex;
    align-items: center;
    gap: 8px; // Increased gap between icon and text
    margin-bottom: 8px;

    svg {
      margin-right: 2px; // Add small space after icon
    }
  }
`;

const BetActionBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  background: white;
  border-top: 1px solid #eee;
`;

const CopyButton = styled(Button)`
  background: #2c1f56;
  color: white;

  &:hover {
    background: #231840;
  }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 4px solid rgba(64, 181, 73, 0.1);
  border-radius: 50%;
  border-top-color: #40B549;
  animation: spin 1s ease-in-out infinite;
  margin: 40px auto;

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('images/output-onlinepngtools.png') no-repeat center;
  background-size: cover; /* Adjust to fit the logo */
  min-height: 400px;
  text-align: center;
  padding: 40px;
  background: white;
  border-radius: 12px;
  margin: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  h3 {
    margin: 24px 0 12px;
    color: #2c1f56;
    font-weight: 600;
  }

  p {
    color: #666;
    font-size: 15px;
    max-width: 400px;
    line-height: 1.5;
  }
`;

interface BettingFilter {
    id: string;
    label: string;
    field: keyof Prediction;
    min?: number;
    max?: number;
    active: boolean;
}

interface BankrollStats {
    totalBets: number;
    totalCost: number;
    expectedValue: number;
    riskLevel: string;
}

const pulseAndGlow = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(64, 181, 73, 0.5);
  }
  50% {
    transform: scale(1.02);
    box-shadow: 0 0 20px rgba(64, 181, 73, 0.8);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(64, 181, 73, 0.5);
  }
`;

const shimmerBetKeyframes = keyframes`
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
`;

const glowKeyframes = keyframes`
  0%, 100% { box-shadow: 0 0 5px rgba(255, 215, 0, 0.5), 0 0 10px rgba(255, 215, 0, 0.3); }
  50% { box-shadow: 0 0 15px rgba(255, 215, 0, 0.8), 0 0 20px rgba(255, 215, 0, 0.5); }
`;

const MeetingContainer = styled.div<{ isSelected: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 12px;
  align-items: center;
  padding: 16px;
  background: white;
  border: 1px solid ${({ isSelected }) => (isSelected ? '#40B549' : '#e0e0e0')};
  border-radius: 8px;
  margin: 8px 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    padding: 12px;
    margin: 8px;
  }

  &:hover {
    border-color: #40B549;
    transform: translateY(-1px);
  }
`;

const RaceIndicator = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 45px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 12px;
`;

const VenueCode = styled.div`
  background: #2c1f56;
  color: white;
  padding: 6px 8px;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.5px;
`;

const RacesRemaining = styled.div<{ progress: number }>`
  color: #666;
  padding: 2px 4px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  background: #f5f5f5;
`;

const RaceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 6px;
  margin-top: 4px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 4px;
  }
`;

const RaceItem = styled.div`
  font-size: 12px;
  color: #666;
  display: flex;
  align-items: center;
  gap: 4px;
  background: #f5f5f5;
  padding: 4px 8px;
  border-radius: 12px;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 11px;
    padding: 3px 6px;
  }
`;

const StatPill = styled.div<{ variant?: 'success' | 'warning' | 'info' | 'danger' }>`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 11px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;

  ${({ variant }) => {
        switch (variant) {
            case 'success':
                return 'background: #e8f5e9; color: #2e7d32;';
            case 'warning':
                return 'background: #fff3e0; color: #f57c00;';
            case 'info':
                return 'background: #e3f2fd; color: #1565c0;';
            case 'danger':
                return 'background: #ffebee; color: #c62828;';
            default:
                return 'background: #f5f5f5; color: #666;';
        }
    }}

  @media (max-width: 768px) {
    font-size: 10px;
    padding: 3px 6px;
  }
`;

const MeetingName = styled.h3<{ isSelected: boolean }>`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MeetingInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0; // Prevents flex items from overflowing
`;

const MeetingMetrics = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    gap: 4px;
  }
`;

// Helper function for human readable time
const getTimeDisplay = (raceTime: string) => {
    const diff = differenceInMinutes(new Date(raceTime), new Date());
    if (diff < 60) return `${diff}m`;
    return `${Math.floor(diff / 60)}hr`;
};

interface PreviousStart {
    startType: string;
    startDate: string;
    finishingPosition: string;
    margin: string | null;
    venueAbbreviation: string;
    distance: number;
    trackCondition: string | null;
};

interface RaceAccuracy {
    raceName: string;
    accuracy: number;
    predictions: number[];
    results: number[];
}
interface ReportData {
    totalWins: number;
    totalPlaces: number;
    averageWinningOdds: number;
    averagePlacingOdds: number;
}

// Add these interfaces
interface PredictionStats {
    totalPredictions: number;
    correctWinners: number;
    correctPlacings: number;
    averageOdds: number;
    profitLoss: number;
    winStrikeRate: number;
    placeStrikeRate: number;
    topPickStats: {
        wins: number;
        places: number;
        total: number;
        avgOdds: number;
    };
    predictionAccuracy: {
        raceByRaceAccuracy: RaceAccuracy[];
    };
    reportData: ReportData;
}

// Add these styled components
const StatsContainer = styled.div`
    padding: 24px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
`;

const StatsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
    margin: 24px 0;
`;

const StatCard = styled.div<{ trend?: number }>`
    padding: 16px;
    background: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #eee;

    h4 {
        color: #666;
        font-size: 14px;
        margin: 0 0 8px 0;
    }

    .value {
        font-size: 24px;
        font-weight: 600;
        color: #2c1f56;
    }

    .trend {
        font-size: 12px;
        color: ${({ trend = 0 }) => trend > 0 ? '#2e7d32' : '#c62828'};
    }
`;

const Chart = styled.div`
    height: 300px;
    margin: 24px 0;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #eee;
    padding: 16px;
`;

// Move this function before calculatePredictionStats
const analyzeBettingStrategy = (predictions: Prediction[]): BetRecommendation[] => {
    if (!predictions || predictions.length === 0) return [];

    return predictions.map(prediction => {
        const runnerData = prediction.runner?.data || {};
        const runnerStarts = runnerData.runnerStarts || {
            startSummaries: {
                overall: {
                    numberOfStarts: 0,
                    numberOfWins: 0,
                    numberOfPlacings: 0
                }
            },
            previousStarts: []
        };

        return {
            runnerNumber: prediction.runnerNumber,
            runnerName: prediction.runnerName,
            silkUrl: prediction.silkUrl,
            jockeyName: prediction.jockeyName,
            ev: prediction.ev,
            probabilityScore: prediction.probabilityScore,
            stabilityScore: prediction.stabilityScore,
            speedRating: prediction.speedRating,
            currentOdds: prediction.currentOdds,
            recommendedStake: prediction.prediction * 10,
            averageFinishPosition: prediction.averageFinishPosition,
            consistencyScore: prediction.consistencyScore,
            classLevelFactor: prediction.classLevelFactor,
            potentialWin: prediction.potentialWin,
            excluded: prediction.excluded,
            betAmount: prediction.prediction * 10,
            expectedReturn: prediction.currentOdds * (prediction.prediction * 10),
            recommendedPosition: prediction.averageFinishPosition,
            kellyFraction: prediction.kellyFraction,
            prediction: prediction.prediction,
            competitionScore: prediction.competitionScore,
            preferredTrackSurface: prediction.preferredTrackSurface,
            optimalRaceDistance: prediction.optimalRaceDistance,
            hasRecentInjuries: prediction.hasRecentInjuries,
            recoveryTime: prediction.recoveryTime,
            weatherConditionFactor: prediction.weatherConditionFactor,
            bettingVolumeChange: prediction.bettingVolumeChange,
            publicVsProfessionalBetting: prediction.publicVsProfessionalBetting,
            runner: {
                data: {
                    prizeMoney: runnerData.prizeMoney || '',
                    last20Starts: runnerData.last20Starts,
                    formComment: runnerData.formComment || '',
                    formComments: runnerData.formComments || [],
                    runnerStarts: {
                        startSummaries: runnerStarts.startSummaries,
                        previousStarts: runnerStarts.previousStarts
                    }
                },
                predictedPosition: prediction.runner.predictedPosition,
                actualPosition: prediction.runner.actualPosition,
                adjustmentFactors: prediction.runner.adjustmentFactors
            }
        };
    });
};

const calculatePredictionStats = (racePredictions: GroupedRacePredictions): PredictionStats => {
    let stats = {
        totalPredictions: 0,
        correctWinners: 0,
        correctPlacings: 0,
        averageOdds: 0,
        profitLoss: 0,
        winStrikeRate: 0,
        placeStrikeRate: 0,
        topPickStats: {
            wins: 0,
            places: 0,
            total: 0,
            avgOdds: 0
        },
        predictionAccuracy: {
            raceByRaceAccuracy: []
        },
        reportData: { // Initialize reportData
            totalWins: 0,
            totalPlaces: 0,
            averageWinningOdds: 0,
            averagePlacingOdds: 0
        }
    };

    Object.values(racePredictions).forEach(races => {
        races.forEach(race => {
            if (!race.predictions || !race.results) return;

            const winner = race.results[0];
            const placings = race.results.slice(0, 3);
            const predictions = analyzeBettingStrategy(race.predictions);

            // Sort by prediction confidence (using stabilityScore)
            const sortedPredictions = [...predictions].sort((a, b) => b.stabilityScore - a.stabilityScore);
            const topPick = sortedPredictions[0];

            if (topPick) {
                stats.topPickStats.total++;
                stats.topPickStats.avgOdds += topPick.currentOdds;

                if (topPick.runnerNumber === winner) {
                    stats.topPickStats.wins++;
                    stats.correctWinners++;
                    stats.profitLoss += topPick.currentOdds * 10; // Assuming $10 bet
                }

                if (placings.includes(topPick.runnerNumber)) {
                    stats.topPickStats.places++;
                    stats.correctPlacings++;
                }
            }

            stats.totalPredictions++;
            stats.averageOdds += topPick?.currentOdds || 0;
        });
    });

    // Calculate rates
    stats.winStrikeRate = (stats.correctWinners / stats.totalPredictions) * 100;
    stats.placeStrikeRate = (stats.correctPlacings / stats.totalPredictions) * 100;
    stats.averageOdds /= stats.totalPredictions;
    stats.topPickStats.avgOdds /= stats.topPickStats.total;

    // Calculate reportData
    stats.reportData.totalWins = stats.correctWinners;
    stats.reportData.totalPlaces = stats.correctPlacings;
    stats.reportData.averageWinningOdds = stats.topPickStats.avgOdds; // Example calculation
    stats.reportData.averagePlacingOdds = stats.averageOdds; // Example calculation

    return stats;
};

// Move this styled component outside of the MeetingDateSelector component
const RaceAnalysisTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #eee;
    }

    th {
        font-weight: 500;
        color: #666;
    }

    tr:hover {
        background: #f8f9fa;
    }
`;

const MeetingDateSelector = () => {
    // State variables
    const [jurisdiction, setJurisdiction] = useState<string>('VIC');
    const [raceType, setRaceType] = useState<string>('R');
    const [meetingDates, setMeetingDates] = useState<any[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [meetings, setMeetings] = useState<any[]>([]);
    const [selectedMeetings, setSelectedMeetings] = useState<string[]>([]); // Initialize as empty array
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [bankroll, setBankroll] = useState<{
        bankrollId: string;
        initialBankroll: number;
        currentAmount: number;
    } | null>(null);
    const [isBankrollInitialized, setIsBankrollInitialized] = useState<boolean>(false);
    const [racePredictions, setRacePredictions] = useState<GroupedRacePredictions>({});
    const [processingRaces, setProcessingRaces] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1);
    const [progress, setProgress] = useState<number>(0);
    const [processedRaces, setProcessedRaces] = useState<number>(0);
    const [totalRaces, setTotalRaces] = useState<number>(0);
    const [currentRace, setCurrentRace] = useState<any>(null);
    const [activeFilters, setActiveFilters] = useState<BettingFilter[]>([
        { id: 'highEv', label: 'High EV (>2)', field: 'ev', min: 2, active: false },
        { id: 'stable', label: 'Stable (>8)', field: 'stabilityScore', min: 8, active: false },
        { id: 'highProb', label: 'High Probability (>0.3)', field: 'probabilityScore', min: 0.3, active: false },
    ]);
    const [activeMeetingTab, setActiveMeetingTab] = useState<string>('');
    const [bankrollStats, setBankrollStats] = useState<BankrollStats>({
        totalBets: 0,
        totalCost: 0,
        expectedValue: 0,
        riskLevel: 'Medium',
    });
    const [placedBets, setPlacedBets] = useState<{ [raceId: string]: boolean }>({});
    const [isCancelling, setIsCancelling] = useState(false);
    const [isLoadingMeetings, setIsLoadingMeetings] = useState(false);
    const [showLeftScroll, setShowLeftScroll] = useState(false);
    const [showRightScroll, setShowRightScroll] = useState(false);
    const [displayedMeetings, setDisplayedMeetings] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const meetingsPerPage = 9;
    const [showCompletion, setShowCompletion] = useState(false);
    const [filterType, setFilterType] = useState<'all' | 'nextToGo' | 'upcoming' | 'started' | 'finished'>('all');
    const [sortBy, setSortBy] = useState<'time' | 'venue'>('time');
    const [stats, setStats] = useState<PredictionStats>({
        totalPredictions: 0,
        correctWinners: 0,
        correctPlacings: 0,
        averageOdds: 0,
        profitLoss: 0,
        winStrikeRate: 0,
        placeStrikeRate: 0,
        topPickStats: {
            wins: 0,
            places: 0,
            total: 0,
            avgOdds: 0
        },
        predictionAccuracy: {
            raceByRaceAccuracy: []
        },
        reportData: { // Initialize reportData
            totalWins: 0,
            totalPlaces: 0,
            averageWinningOdds: 0,
            averagePlacingOdds: 0
        }
    });

    // Add these interfaces near the top with other interfaces
    interface BettingStrategy {
        wins: number;
        places: number;
        totalBets: number;
        profit: number;
    }

    interface BettingAnalysis {
        totalInvestment: number;
        totalReturns: number;
        netProfit: number;
        roi: number;
        betsPerStrategy: {
            eachWayTop: BettingStrategy;
            placeSecond: BettingStrategy;
            winHighestOdds: BettingStrategy;
        };
        betStrings: string[];
    }

    // Add this state near other state declarations in the component
    const [bettingAnalysis, setBettingAnalysis] = useState<BettingAnalysis>({
        totalInvestment: 0,
        totalReturns: 0,
        netProfit: 0,
        roi: 0,
        betsPerStrategy: {
            eachWayTop: { wins: 0, places: 0, totalBets: 0, profit: 0 },
            placeSecond: { wins: 0, places: 0, totalBets: 0, profit: 0 },
            winHighestOdds: { wins: 0, places: 0, totalBets: 0, profit: 0 }
        },
        betStrings: []
    });

    // Add these interfaces for tracking prediction accuracy
    interface PredictionAccuracyStats {
        totalPredictions: number;
        exactPredictions: number;
        topThreePredictions: number;
        topFivePredictions: number;
        averagePositionDiff: number;
        within3Positions: number;  // Add this line
        predictionsByPosition: {
            [key: number]: {
                total: number;
                correct: number;
                averageOdds: number;
            }
        };
        raceResults: {
            raceName: string;
            predictedOrder: number[];
            actualOrder: number[];
            accuracy: number;
        }[];
    }

    // Add this to your state declarations
    const [predictionAccuracy, setPredictionAccuracy] = useState<PredictionAccuracyStats>({
        totalPredictions: 0,
        exactPredictions: 0,
        topThreePredictions: 0,
        topFivePredictions: 0,
        averagePositionDiff: 0,
        within3Positions: 0,  // Initialize within3Positions
        predictionsByPosition: {},
        raceResults: []
    });

    const [isTutorialVisible, setTutorialVisible] = useState(false);
    const [goldPredictionsCount, setGoldPredictionsCount] = useState(0);

    // Add state for tracking form position
    const [formPosition, setFormPosition] = useState<{ x: number; y: number } | null>(null);
    const [activeFormRunner, setActiveFormRunner] = useState<number | null>(null);
    const [openFormRunner, setOpenFormRunner] = useState<number | null>(null);

    // Add these new styled components after your existing styled components

    const RaceNavigation = styled.div`
      position: fixed;
      bottom: 120px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      gap: 6px;
      padding: 8px 16px;
      background: transparent;
      border-radius: 20px;
      z-index: 100;
      flex-wrap: wrap;
      max-width: 90%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      transition: all 0.3s ease;
      opacity: 0.5; // Increased base opacity

      &:hover,
      &:focus-within {
        opacity: 1;
      }

      &.scrolling {
        opacity: 1;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: 768px) {
        bottom: 100px;
        gap: 4px;
        padding: 6px 12px;
      }
    `;

    const RaceNumberButton = styled.button<{ isActive: boolean }>`
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: none;
      background: ${({ isActive }) =>
        isActive
          ? 'linear-gradient(135deg, #40B549 0%, #379940 100%)'
          : 'rgba(44, 31, 86, 0.1)'};
      color: ${({ isActive }) => isActive ? 'white' : '#2c1f56'};
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      backdrop-filter: blur(4px);

      &:hover {
        transform: translateY(-2px);
        background: ${({ isActive }) =>
          isActive
            ? 'linear-gradient(135deg, #45c34f 0%, #3ca344 100%)'
            : 'rgba(44, 31, 86, 0.2)'};
      }

      &:active {
        transform: translateY(0);
      }

      @media (max-width: 768px) {
        width: 24px;
        height: 24px;
        font-size: 11px;
      }
    `;

    // Add these new state variables inside the MeetingDateSelector component
    const [activeRaceNumber, setActiveRaceNumber] = useState<number | null>(null);

    // Add this new function inside the MeetingDateSelector component
    const scrollToRace = (raceNumber: number) => {
      const raceElement = document.querySelector(`[data-race-number="${raceNumber}"]`);
      if (raceElement) {
        setActiveRaceNumber(raceNumber);
        raceElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    useEffect(() => {
        const savedBankroll = localStorage.getItem('bankroll');
        if (savedBankroll) {
            setBankroll(JSON.parse(savedBankroll));
            setIsBankrollInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (bankroll) {
            localStorage.setItem('bankroll', JSON.stringify(bankroll));
        }
    }, [bankroll]);

    useEffect(() => {
        const fetchDates = async () => {
            try {
                const data = await apiService.fetchMeetingDates(jurisdiction);
                setMeetingDates(data);
            } catch (error) {
                console.error('Error fetching meeting dates:', error);
            }
        };
        fetchDates();
    }, [jurisdiction]);

    const initializeBankroll = async () => {
        try {
            const response = await apiService.initializeBankroll(200, 150, 50);
            const initialBankroll = {
                bankrollId: response.bankrollId,
                initialBankroll: response.initialBankroll,
                currentAmount: response.initialBankroll,
            };
            setBankroll(initialBankroll);
            localStorage.setItem('bankroll', JSON.stringify(initialBankroll));
            setIsBankrollInitialized(true);
        } catch (error) {
            console.error('Error initializing bankroll:', error);
        }
    };

    const fetchMeetings = async () => {
        const allMeetings = [];
        let page = 1;
        const pageSize = 50;

        try {
            while (true) {
                const data = await apiService.fetchMeetingsByType(selectedDate, raceType, jurisdiction, page, pageSize);

                if (!data || data.length === 0) {
                    break;
                }

                // Filter by race type only
                const activeMeetings = data.filter((meeting: any) => {
                    // Check if meeting type matches
                    if (meeting.raceType !== raceType) {
                        return false;
                    }

                    // Ensure races array exists
                    if (!Array.isArray(meeting.races) || meeting.races.length === 0) {
                        return false;
                    }

                    return true;
                });

                allMeetings.push(...activeMeetings);
                page += 1;
            }

            setMeetings(allMeetings);
            setCurrentPage(0);
        } catch (error) {
            console.error('Error fetching meetings:', error);
        }
    };

    const handleMeetingSelect = (meetingName: string) => {
        setSelectedMeetings((prev) => {
            if (!prev) return [meetingName];
            return prev.includes(meetingName)
                ? prev.filter((name) => name !== meetingName)
                : [...prev, meetingName];
        });
    };

    const processRace = async (race: any) => {
        if (!bankroll || !bankroll.bankrollId) {
            throw new Error('Bankroll not initialized');
        }

        try {
            const prediction = await apiService.calculateRace(
                jurisdiction,
                race.meetingDate,
                race.venueMnemonic,
                race.raceType,
                race.raceNumber,
                bankroll.bankrollId
            );

            // Create the sellCode object
            const sellCode = {
                meetingCode: race.venueMnemonic,
                scheduledType: race.raceType
            };

            // Return race data with sellCode
            return {
                ...race,
                predictions: prediction.predictions,
                results: prediction.results,
                raceStatus: prediction.raceStatus,
                meeting: {
                    ...race.meeting,
                    sellCode: sellCode
                }
            };
        } catch (error) {
            console.error(`Error processing race ${race.raceNumber} at ${race.meetingName}:`, error);
            return {
                ...race,
                predictions: null,
                error: 'Failed to process race',
            };
        }
    };

    const handleProcessRaces = async () => {
        if (!bankroll || !bankroll.bankrollId || !selectedMeetings) {
            console.error('Bankroll not initialized or no meetings selected');
            alert('Please select meetings and ensure bankroll is initialized');
            return;
        }

        setProcessingRaces(true);
        setProgress(0);

        try {
            // Get all races from selected meetings
            const selectedRaces = meetings
                .filter((meeting) => selectedMeetings.includes(meeting.meetingName))
                .flatMap((meeting) =>
                    meeting.races.map((race: any) => ({
                        meetingName: meeting.meetingName,
                        raceNumber: race.raceNumber,
                        raceName: race.raceName,
                        venueMnemonic: meeting.venueMnemonic,
                        meetingDate: meeting.meetingDate,
                        raceType: raceType,
                        startTime: race.raceStartTime,
                        raceDistance: race.raceDistance,
                        raceStatus: race.raceStatus,
                        hasFixedOdds: race.hasFixedOdds,
                        broadcastChannel: race.broadcastChannel,
                    }))
                );

            setTotalRaces(selectedRaces.length);
            setProcessedRaces(0);

            // Process races in batches to limit concurrency
            const batchSize = 5;
            const results: any[] = [];
            for (let i = 0; i < selectedRaces.length; i += batchSize) {
                const batch = selectedRaces.slice(i, i + batchSize);
                const batchPromises = batch.map((race) => processRace(race));
                const batchResults = await Promise.all(batchPromises);
                results.push(...batchResults);

                setProcessedRaces((prev) => prev + batchResults.length);
                setProgress(((i + batchResults.length) / selectedRaces.length) * 100);
            }

            // Group results by meeting
            const groupedResults = results.reduce<GroupedRacePredictions>((acc, race) => {
                if (!acc[race.meetingName]) {
                    acc[race.meetingName] = [];
                }

                acc[race.meetingName].push(race);
                return acc;
            }, {});

            setRacePredictions(groupedResults);
            setProcessingRaces(false);
            setCurrentRace(null); // Clear current race when done
            setActiveMeetingTab(Object.keys(groupedResults)[0]);
        } catch (error) {
            console.error('Error processing races:', error);
            setProcessingRaces(false);
            setCurrentRace(null); // Clear current race on error
        } finally {
            setStep(3); // Move to the new results step
        }
    };

    const handleNext = async () => {
        if (step === 1 && selectedDate) {
            setIsLoadingMeetings(true);
            try {
                await initializeBankroll();
                await fetchMeetings();
                setStep(2);
            } catch (error) {
                console.error('Error loading meetings:', error);
                // Optionally show an error message to the user
            } finally {
                setIsLoadingMeetings(false);
            }
        } else if (step === 2 && selectedMeetings.length > 0) {
            handleProcessRaces();
        } else if (step === 3) {
            setStep(4);
        }
    };

    const isNextDisabled = () => {
        if (step === 1) return !selectedDate;
        if (step === 2) return !selectedMeetings?.length || !isBankrollInitialized;
        if (step === 3) return false;
        return false;
    };

    useEffect(() => {
        if (meetings.length > 0) {
            setDisplayedMeetings(meetings.slice(0, meetingsPerPage));
            setHasMore(meetings.length > meetingsPerPage);
        }
    }, [meetings]);

    const observerTarget = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore && !loading) {
                    loadMore();
                }
            },
            { threshold: 0.1 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [hasMore, loading, displayedMeetings]);

    const loadMore = () => {
        if (loading || !hasMore) return;

        setLoading(true);
        const currentLength = displayedMeetings.length;
        const nextMeetings = meetings.slice(
            currentLength,
            currentLength + meetingsPerPage
        );

        setTimeout(() => {
            setDisplayedMeetings(prev => [...prev, ...nextMeetings]);
            setHasMore(currentLength + nextMeetings.length < meetings.length);
            setLoading(false);

            if (currentLength + nextMeetings.length >= meetings.length) {
                setShowCompletion(true);
                setTimeout(() => setShowCompletion(false), 3000);
            }
        }, 500);
    };

    const handlePlaceBet = async (race: RacePrediction, bets: any[]) => {
        if (!bankroll || placedBets[race.raceNumber]) return;

        const totalStake = bets.reduce((sum: number, bet: any) => sum + bet.recommendedStake, 0);
        const expectedProfit = bets.reduce(
            (sum: number, bet: any) => sum + bet.potentialProfit * bet.probabilityScore,
            0
        );

        if (totalStake > bankroll.currentAmount) {
            alert('Insufficient bankroll to place these bets.');
            return;
        }

        if (!race.jurisdiction || !race.meeting?.sellCode) {
            console.error('Missing required fields for bet placement');
            return;
        }

        const payload = {
            jurisdiction: race.jurisdiction,
            sellCode: race.meeting?.sellCode,
            venueMnemonic: race.venueMnemonic,
            raceNumber: race.raceNumber,
            runnerSelections: bets.map((bet: any) => bet.runnerNumber.toString()).join(','),
            bets: bets.map((bet: any) => {
                const betType = getBetType(bet);
                const amount1 = formatAmount(bet.recommendedStake);
                const amount2 = betType === 'WP' ? formatAmount(bet.recommendedStake / 2) : '00000.0';
                const betString = generateBetString(race, bet.runnerNumber, betType as BetType, amount1, amount2);

                return {
                    betType: betType,
                    amount: bet.recommendedStake,
                    runnerNumber: bet.runnerNumber,
                    propositionId: bet.propositionId,
                    currentOdds: bet.currentOdds,
                    betString: betString,
                };
            }),
        };

        try {
            const response = await apiService.placeBets(bankroll.bankrollId, payload);
            console.log('Bet placement response:', response);

            setBankroll(prev => prev ? {
                ...prev,
                currentAmount: prev.currentAmount - totalStake
            } : prev);

            setBankrollStats(prev => ({
                totalBets: prev.totalBets + bets.length,
                totalCost: prev.totalCost + totalStake,
                expectedValue: prev.expectedValue + expectedProfit,
                riskLevel: 'Medium',
            }));

            setPlacedBets(prev => ({ ...prev, [race.raceNumber]: true }));
            alert('Bets placed successfully!');
        } catch (error) {
            console.error('Error placing bets:', error);
            alert('Failed to place bets. Please try again.');
        }
    };

    const isStartingSoon = (startTime: string) => {
        const raceTime = new Date(startTime).getTime();
        const now = new Date().getTime();
        const diff = raceTime - now;
        return diff > 0 && diff < 30 * 60 * 1000; // Within 30 minutes
    };

    const filterRaces = (races: RacePrediction[]) => {
        return races.filter((race) => {
            return activeFilters.every((filter) => {
                if (!filter.active) return true;
                const prediction = race.predictions?.[0];
                if (!prediction) return false;

                const value = prediction[filter.field];
                if (value === undefined) return false;

                const numericValue = typeof value === 'number' ? value :
                    typeof value === 'string' ? parseFloat(value) : 0;
                if (filter.min !== undefined && numericValue < filter.min) return false;
                if (filter.max !== undefined && numericValue > filter.max) return false;
                return true;
            });
        });
    };

    // Add this function to calculate prediction accuracy
    const calculatePredictionAccuracy = (predictions: Prediction[], results: number[]) => {
        if (!predictions || !results) return null;

        const sortedPredictions = [...predictions].sort((a, b) => a.prediction - b.prediction);
        const predictionOrder = sortedPredictions.map(p => p.runnerNumber);

        let exactMatches = 0;
        let topThreeMatches = 0;
        let topFiveMatches = 0;
        let totalPositionDiff = 0;

        predictionOrder.forEach((runnerNumber, predictedPosition) => {
            const actualPosition = results.indexOf(runnerNumber) + 1;
            if (actualPosition === predictedPosition + 1) exactMatches++;
            if (Math.abs(actualPosition - (predictedPosition + 1)) <= 2) topThreeMatches++;
            if (Math.abs(actualPosition - (predictedPosition + 1)) <= 4) topFiveMatches++;
            totalPositionDiff += Math.abs(actualPosition - (predictedPosition + 1));
        });

        return {
            exactMatches,
            topThreeMatches,
            topFiveMatches,
            averagePositionDiff: totalPositionDiff / predictionOrder.length,
            predictedOrder: predictionOrder,
            actualOrder: results
        };
    };

    // Update the renderRaceResults function to sort runners by prediction
    const renderRaceResults = () => {
        const races = racePredictions[activeMeetingTab] || [];
        return filterRaces(races)
            .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
            .map((race: RacePrediction) => {
                const predictions = race.predictions || [];
                const bets = analyzeBettingStrategy(predictions)
                    .sort((a, b) => a.prediction - b.prediction); // Sort by prediction order

                if (!bets || !bets.length) return null;

                return (
                    <RaceCard
                      key={`${race.meetingName}-${race.raceNumber}`}
                      data-race-number={race.raceNumber} // Add this line
                    >
                        <RaceHeader>
                            <RaceInfo>
                                <h3>
                                    Race {race.raceNumber} - {race.raceName}
                                </h3>
                                <ResultsBar>
                                    <div className="left-content">
                                        <span>Dist: {race.raceDistance}m</span>
                                    </div>
                                    <div className="right-content">
                                        {isRaceComplete(race.startTime) ? (
                                            race.results?.map((position: number, index: number) => (
                                                <ResultNumber key={index} position={index + 1}>
                                                    {position}
                                                </ResultNumber>
                                            ))
                                        ) : (
                                            <span>{formatRaceTime(race.startTime)}</span>
                                        )}
                                    </div>
                                </ResultsBar>
                            </RaceInfo>
                        </RaceHeader>

                        <RunnersGrid>
                            {bets.map((bet, index) => {
                                if (!bet) return null;
                                const isRecommended = !bet.excluded;
                                const isTopPick = index < 4 && isRecommended;
                                const isInResults = race.results?.includes(bet.runnerNumber);
                                const runnerData = bet.runner.data;

                                return (
                                    <RunnerCard
                                        key={bet.runnerNumber}
                                        rank={index + 1}
                                        isRecommended={isRecommended}
                                        excluded={bet.excluded}
                                        isInResults={isInResults}
                                        isFormOpen={openFormRunner === bet.runnerNumber}
                                        isValuePick={bet.currentOdds / 2 > 2}
                                    >
                                        {race.results?.slice(0, 4).includes(bet.runnerNumber) && (
                                            <WinnerIndicator position={race.results.findIndex(num => num === bet.runnerNumber) + 1}>
                                                {race.results.findIndex(num => num === bet.runnerNumber) + 1}
                                            </WinnerIndicator>
                                        )}
                                        <RunnerIndicators>
                                            {bet.currentOdds > 0 && isRecommended && (bet.currentOdds / 3) > 2 && (
                                                <RunnerIcon type="place">
                                                    <FaAcquisitionsIncorporated />
                                                </RunnerIcon>
                                            )}
                                            {bet.currentOdds > 0 && bet.currentOdds === Math.min(...bets.filter(b => b.currentOdds > 0).map(b => b.currentOdds)) && (
                                                <RunnerIcon type="favorite">
                                                    <FaStar />
                                                </RunnerIcon>
                                            )}
                                            {bet.stabilityScore > 11 && (
                                                <RunnerIcon type="stable">
                                                    <FaShieldAlt />
                                                </RunnerIcon>
                                            )}
                                            {bet.probabilityScore > 0.15 && (
                                                <RunnerIcon type="probability">
                                                    <FaCheckCircle />
                                                </RunnerIcon>
                                            )}
                                        </RunnerIndicators>

                                        <SilkImage>
                                            {bet.silkUrl && <img src={bet.silkUrl} alt={`Silk for ${bet.runnerName}`} />}
                                        </SilkImage>

                                        <RunnerHeader>
                                            <RunnerNumber>{bet.runnerNumber}</RunnerNumber>
                                            <div>
                                                <RunnerName>{bet.runnerName}</RunnerName>
                                                {bet.jockeyName && (
                                                    <JockeyInfo>
                                                        <FaUser />
                                                        <JockeyName
                                                            isTopJockey={TOP_JOCKEYS.includes(bet.jockeyName.toUpperCase())}
                                                            className="jockey-name"
                                                        >
                                                            {bet.jockeyName}
                                                        </JockeyName>
                                                    </JockeyInfo>
                                                )}
                                            </div>
                                        </RunnerHeader>

                                        <RunnerStats>
                                            <StatBox highlight={Boolean(bet.speedRating && bet.speedRating > 0.95)}>
                                                <span className="label">Speed</span>
                                                <span className="value">
                                                    {bet.speedRating ? bet.speedRating.toFixed(3) : 'N/A'}
                                                </span>
                                            </StatBox>
                                            <StatBox highlight={Boolean(bet.consistencyScore && bet.consistencyScore > 5)}>
                                                <span className="label">Consistency</span>
                                                <span className="value">
                                                    {bet.consistencyScore ? normalizeValue(bet.consistencyScore) : 'N/A'}
                                                </span>
                                            </StatBox>
                                            <StatBox highlight={Boolean(bet.competitionScore && bet.competitionScore > 11)}>
                                                <span className="label">Competition</span>
                                                <span className="value">
                                                    {bet.competitionScore ? bet.competitionScore.toFixed(1) : 'N/A'}
                                                </span>
                                            </StatBox>
                                        </RunnerStats>

                                        <TagContainer>
                                            {bet.optimalRaceDistance && (
                                                <BetTypeTag type="info">
                                                    <FaClock /> Optimal Distance
                                                </BetTypeTag>
                                            )}
                                            {bet.preferredTrackSurface && (
                                                <BetTypeTag type="success">
                                                    <FaCheckCircle /> Preferred Track
                                                </BetTypeTag>
                                            )}

                                            {bet.weatherConditionFactor > 0.8 && (
                                                <BetTypeTag type="info">
                                                    <WiDaySunny /> Good Conditions
                                                </BetTypeTag>
                                            )}
                                            {determineBetType(bet, bets) === 'win' && bet.currentOdds > 0 && (
                                                <BetTypeTag type="win" highlighted={true}>
                                                    <FaTrophy /> Win ${bet.currentOdds.toFixed(1)}
                                                </BetTypeTag>
                                            )}
                                        </TagContainer>

                                        {bet.hasRecentInjuries && (
                                            <RunnerWarning>
                                                <FaExchangeAlt /> Recent injury - {bet.recoveryTime} days recovery
                                            </RunnerWarning>
                                        )}

                                        <BetDetails>
                                            <BetMetric highlighted={bet.ev > -20}>
                                                <span>EV:</span>
                                                <span>{bet.ev !== undefined ? (bet.ev * 100).toFixed(1) : 'N/A'}%</span>
                                            </BetMetric>
                                            <BetMetric highlighted={bet.probabilityScore > 0.15}>
                                                <span>Probability:</span>
                                                <span>{bet.probabilityScore !== undefined ? (bet.probabilityScore * 100).toFixed(1) : 'N/A'}%</span>
                                            </BetMetric>
                                            <BetMetric highlighted={Boolean(race?.predictions?.[index + 1]?.adjustmentFactor && race?.predictions?.[index + 1]?.adjustmentFactor > 0.9)}>
                                                <span>Suitability:</span>
                                                <span>{race?.predictions?.[index + 1]?.adjustmentFactor !== undefined ? race.predictions[index + 1].adjustmentFactor.toFixed(1) : 'N/A'}</span>
                                            </BetMetric>
                                            <BetMetric highlighted={Boolean(race?.predictions?.[index + 1]?.runner.moneyLine && race?.predictions?.[index + 1]?.runner.moneyLine != "")}>
                                                <span>Money Line:</span>
                                                <span>{race?.predictions?.[index + 1]?.runner.moneyLine || 'N/A'}</span>
                                            </BetMetric>
                                            <BetMetric
                                                highlighted={Boolean(race?.predictions?.[index + 1]?.placeOdds && race?.predictions?.[index + 1]?.placeOdds > 1.5)}
                                                isMatch={bet.prediction === bet.runner.actualPosition}
                                            >
                                                <span>Prediction:</span>
                                                <span>
                                                    {bet.prediction !== undefined ? bet.prediction : 'N/A'} {bet.prediction === bet.runner.actualPosition ? '✓' : ''}
                                                </span>
                                            </BetMetric>
                                            <BetMetric highlighted={bet.stabilityScore > 8}>
                                                <span>Stability:</span>
                                                <span>{bet.stabilityScore !== undefined ? bet.stabilityScore.toFixed(1) : 'N/A'}</span>
                                            </BetMetric>

                                            {(race?.predictions?.[index + 1]?.runner?.data?.daysSinceLastRun ?? 0) > 0 && (
                                                <BetMetric highlighted={(race?.predictions?.[index + 1]?.runner?.data?.daysSinceLastRun ?? 0) > 30}>
                                                    <span>Last Run:</span>
                                                    <span>{race?.predictions?.[index + 1]?.runner?.data?.daysSinceLastRun || 'N/A'} Days Ago</span>
                                                </BetMetric>
                                            )}
                                            <BetMetric highlighted={Boolean(race?.predictions?.[index + 1]?.runner.adjustmentFactors.staminaFactor && race?.predictions?.[index + 1]?.runner.adjustmentFactors.staminaFactor > 0.9)}>
                                                <span>Stamina:</span>
                                                <span>
                                                    {race?.predictions?.[index + 1]?.runner.adjustmentFactors.staminaFactor !== undefined ? race.predictions[index + 1].runner.adjustmentFactors.staminaFactor.toFixed(1) : 'N/A'}
                                                </span>
                                            </BetMetric>
                                        </BetDetails>



                                        <Button onClick={() => handleFormClick(bet.runnerNumber)}>
                                            View Form {openFormRunner === bet.runnerNumber ? '▼' : '▲'}
                                        </Button>
                                        <FormSection isOpen={openFormRunner === bet.runnerNumber}>
                                            {/* Form content */}
                                            <div style={{ padding: '16px' }}>
                                                {bet.runner?.data?.formComment}
                                            </div>
                                        </FormSection>

                                        <BetTrends>
                                            <TrendItem positive={bet.bettingVolumeChange > 0}>
                                                <FaChartLine />
                                                <span>Volume: {bet.bettingVolumeChange > 0 ? '+' : ''}{bet.bettingVolumeChange}%</span>
                                            </TrendItem>
                                            <TrendItem positive={bet.currentOdds > 6}>
                                                <FaCoins />
                                                <span>Paying: ${bet.currentOdds.toFixed(0)}</span>
                                            </TrendItem>
                                            <TrendItem positive={Boolean(race?.predictions?.[index + 1]?.winOdds && race?.predictions?.[index + 1]?.winOdds > 2.3)}>
                                                <span>Price: ${race?.predictions?.[index + 1]?.winOdds.toFixed(0)}</span>
                                            </TrendItem>
                                        </BetTrends>
                                    </RunnerCard>
                                );
                            })}
                        </RunnersGrid>

                        <CollaborativeBetting>
                            <BetSplitOption>
                                <strong>
                                    <FaUser /> Hedge Options
                                </strong>
                                {bets[0] && (
                                    <>
                                        <BetCombination>
                                            <BetTypeTag type="win" highlighted={true}>
                                                <FaTrophy />
                                            </BetTypeTag>
                                            <BetRunners>{bets[0].runnerName}</BetRunners>
                                            <BetAmount>${bets[0].recommendedStake.toFixed(2)}</BetAmount>
                                        </BetCombination>
                                        {bets[1] && bets[2] && (
                                            <BetCombination>
                                                <BetTypeTag type="trifecta">
                                                    <FaStar />
                                                </BetTypeTag>
                                                <BetRunners>
                                                    {bets[0].runnerName}/{bets[1].runnerName}/{bets[2].runnerName}
                                                </BetRunners>
                                                <BetAmount>${(bets[0].recommendedStake * 0.5).toFixed(2)}</BetAmount>
                                            </BetCombination>
                                        )}
                                        {bets[1] && (
                                            <BetCombination>
                                                <BetTypeTag type="quinella">
                                                    <FaExchangeAlt />
                                                </BetTypeTag>
                                                <BetRunners>
                                                    {bets[0].runnerName}+{bets[1].runnerName}
                                                </BetRunners>
                                                <BetAmount>${(bets[0].recommendedStake * 0.75).toFixed(2)}</BetAmount>
                                            </BetCombination>
                                        )}
                                    </>
                                )}
                            </BetSplitOption>

                            <BetSplitOption>
                                <strong>
                                    <FaUserFriends /> Additional Options
                                </strong>
                                {bets[1] && (
                                    <>
                                        <BetCombination>
                                            <BetTypeTag type="place" highlighted={true}>
                                                <FaCheckCircle />
                                            </BetTypeTag>
                                            <BetRunners>{bets[1].runnerName}</BetRunners>
                                            <BetAmount>${bets[1].recommendedStake.toFixed(2)}</BetAmount>
                                        </BetCombination>
                                        {bets[2] && (
                                            <BetCombination>
                                                <BetTypeTag type="blended">
                                                    <FaMix />
                                                </BetTypeTag>
                                                <BetRunners>
                                                    {bets[1].runnerName}+{bets[2].runnerName}
                                                </BetRunners>
                                                <BetAmount>${(bets[1].recommendedStake * 0.75).toFixed(2)}</BetAmount>
                                            </BetCombination>
                                        )}
                                        {bets[3] && (
                                            <BetCombination>
                                                <BetTypeTag type="hedge">
                                                    <FaShieldAlt />
                                                </BetTypeTag>
                                                <BetRunners>{bets[3].runnerName}</BetRunners>
                                                <BetAmount>${(bets[1].recommendedStake * 0.5).toFixed(2)}</BetAmount>
                                            </BetCombination>
                                        )}
                                    </>
                                )}
                            </BetSplitOption>
                        </CollaborativeBetting>
                    </RaceCard>
                );
            });
    };

    const handleGenerateBetStrings = (race: RacePrediction, bets: any[]) => {
        const betStrings = bets.map((bet) => {
            const betType = getBetType(bet);
            const amount1 = formatAmount(bet.recommendedStake);
            const amount2 = betType === 'WP' ? formatAmount(bet.recommendedStake / 2) : '00000.0';
            const betString = generateBetString(race, bet.runnerNumber, betType as BetType, amount1, amount2);

            return betString;
        });

        const betStringsText = betStrings.join('\n');

        if (navigator.clipboard) {
            navigator.clipboard.writeText(betStringsText).then(
                () => {
                    alert('Bet strings copied to clipboard!');
                },
                (err) => {
                    console.error('Could not copy bet strings: ', err);
                }
            );
        } else {
            alert('Clipboard not available');
        }
    };

    const getBetType = (bet: any): string => {
        // For simplicity, let's assume bets are 'WIN' if EV > 0, else 'PLACE'
        return bet.ev > 0 ? 'WIN' : 'PLACE';
    };

    const formatAmount = (amount: number): string => {
        // Ensure amount is rounded to nearest 0.5
        amount = Math.round(amount * 2) / 2;
        // Format amount to have six digits before decimal and one after
        const amountStr = amount.toFixed(1);
        const parts = amountStr.split('.');
        const integerPart = parts[0].padStart(6, '0'); // Adjusted to pad to six digits
        const decimalPart = parts[1];
        return `${integerPart}.${decimalPart}`; // e.g., '000000.5' for $0.50
    };

    // Define BetType at the top level
    type BetType = 'WIN' | 'PLACE' | 'WP' | 'QQ';

    const generateBetString = (
        race: RacePrediction,
        runnerNumber: number | number[],
        betType: BetType,
        amount1: string,
        amount2: string
    ) => {
        if (!race.meeting?.sellCode) return '';

        const raceNum = race.raceNumber.toString().padStart(2, '0');
        const runnerStr = Array.isArray(runnerNumber) ? runnerNumber.join(',') : runnerNumber.toString();

        return `${race.meeting.sellCode.meetingCode.charAt(0)}${race.meeting.sellCode.scheduledType.charAt(0)}-${raceNum}-${betType}-${amount1}-${amount2}/${runnerStr}`;
    };

    const getTotalBetAmount = (bets: any[]): number => {
        return bets.reduce((total, bet) => total + bet.recommendedStake, 0);
    };

    const handleCancel = () => {
        setIsCancelling(true);
        // Add any cleanup logic here
        setTimeout(() => {
            setProcessingRaces(false);
            setProgress(0);
            setIsCancelling(false);
        }, 500);
    };

    const checkScrollButtons = useCallback(() => {
        const tabList = document.querySelector('.horizontal-tab-list');
        if (tabList) {
            setShowLeftScroll(tabList.scrollLeft > 0);
            setShowRightScroll(
                tabList.scrollLeft < (tabList.scrollWidth - tabList.clientWidth)
            );
        }
    }, []);

    useEffect(() => {
        const tabList = document.querySelector('.horizontal-tab-list');
        if (tabList) {
            tabList.addEventListener('scroll', checkScrollButtons);
            // Initial check
            checkScrollButtons();
        }
        return () => {
            if (tabList) {
                tabList.removeEventListener('scroll', checkScrollButtons);
            }
        };
    }, [checkScrollButtons]);

    const handleScroll = (direction: 'left' | 'right') => {
        const tabList = document.querySelector('.horizontal-tab-list');
        if (tabList) {
            const scrollAmount = direction === 'left' ? -200 : 200;
            tabList.scrollLeft += scrollAmount;
        }
    };

    const handleTabChange = (meetingName: string) => {
        setActiveMeetingTab(meetingName);
        const tabList = document.querySelector('.horizontal-tab-list');
        const activeTab = document.querySelector(`[data-meeting="${meetingName}"]`);

        if (tabList && activeTab) {
            const tabRect = activeTab.getBoundingClientRect();
            const containerRect = tabList.getBoundingClientRect();

            const scrollLeft = tabList.scrollLeft + tabRect.left - containerRect.left -
                (containerRect.width / 2) + (tabRect.width / 2);

            tabList.scrollTo({
                left: scrollLeft,
                behavior: 'smooth'
            });
        }
    };

    // Add function to cycle through meetings
    const cycleMeetings = (direction: 'next' | 'prev') => {
        const meetingNames = Object.keys(racePredictions);
        const currentIndex = meetingNames.indexOf(activeMeetingTab);
        let newIndex;

        if (direction === 'next') {
            newIndex = currentIndex + 1 >= meetingNames.length ? 0 : currentIndex + 1;
        } else {
            newIndex = currentIndex - 1 < 0 ? meetingNames.length - 1 : currentIndex - 1;
        }

        handleTabChange(meetingNames[newIndex]);
    };

    const LoadMoreContainer = styled.div`
        text-align: center;
        padding: 20px;
        margin-bottom: 100px;
        color: #666;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        .stats {
            font-size: 14px;
            padding: 8px 16px;
            background: #f5f5f5;
            border-radius: 8px;
            display: block;
            margin: 0;
        }
    `;

    const LoadMoreButton = styled.button`
        padding: 8px 16px;
        border-radius: 6px;
        border: none;
        cursor: pointer;
        font-weight: 500;
        font-size: 13px;
        color: white;
        background: linear-gradient(135deg, #40B549 0%, #2D8A34 100%);
        box-shadow: 0 2px 4px rgba(64, 181, 73, 0.2);
        transition: all 0.2s ease;
        display: block;
        margin: 0;

        &:hover {
            background: linear-gradient(135deg, #4BC556 0%, #37A03F 100%);
            transform: translateY(-1px);
            box-shadow: 0 4px 8px rgba(64, 181, 73, 0.3);
        }

        &:active {
            transform: translateY(0);
            box-shadow: 0 2px 4px rgba(64, 181, 73, 0.2);
        }

        .spinner {
            display: inline-block;
            width: 12px;
            height: 12px;
            border: 2px solid #ffffff;
            border-top-color: transparent;
            border-radius: 50%;
            margin-right: 6px;
            animation: spin 1s linear infinite;
        }

        @keyframes spin {
            to { transform: rotate(360deg); }
        }
    `;

    const renderMeetings = () => {
        if (!displayedMeetings) return null;

        let filteredMeetings = [...displayedMeetings];

        // Apply filters
        if (filterType !== 'all') {
            filteredMeetings = filteredMeetings.filter(meeting => {
                const status = getMeetingStatus(meeting);
                if (filterType === 'nextToGo') {
                    return status === 'upcoming' && meeting.races?.some((race: any) =>
                        new Date(race.raceStartTime) > new Date() &&
                        new Date(race.raceStartTime).getTime() - new Date().getTime() <= 30 * 60 * 1000
                    );
                }
                return status === filterType;
            });
        }

        // Apply sorting
        filteredMeetings.sort((a, b) => {
            if (sortBy === 'time') {
                return new Date(a.races?.[0]?.raceStartTime || 0).getTime() -
                    new Date(b.races?.[0]?.raceStartTime || 0).getTime();
            }
            return a.venueMnemonic.localeCompare(b.venueMnemonic);
        });

        const allSelected = filteredMeetings.length > 0 &&
            filteredMeetings.every(meeting => selectedMeetings?.includes(meeting.meetingName));

        return (
            <MeetingsTable>
                <SelectionControls>
                    <SelectionCount>
                        <strong>{selectedMeetings?.length || 0}</strong> meetings selected
                    </SelectionCount>
                    <SelectAllButton
                        allSelected={allSelected}
                        onClick={() => toggleAllMeetings(filteredMeetings)}
                    >
                        {allSelected ? (
                            <>
                                <FaCheckCircle /> Unselect All
                            </>
                        ) : (
                            <>
                                <FaCheckCircle /> Select All
                            </>
                        )}
                    </SelectAllButton>
                </SelectionControls>

                <TableFilters>
                    <FilterButton
                        active={filterType === 'all'}
                        onClick={() => setFilterType('all')}
                        data-tooltip="All Meetings"
                    >
                        <FaList />
                        <span>All Meetings</span>
                    </FilterButton>
                    <FilterButton
                        active={filterType === 'nextToGo'}
                        onClick={() => setFilterType('nextToGo')}
                        data-tooltip="Next To Go"
                    >
                        <FaClock />
                        <span>Next To Go</span>
                    </FilterButton>
                    <FilterButton
                        active={filterType === 'upcoming'}
                        onClick={() => setFilterType('upcoming')}
                        data-tooltip="Upcoming"
                    >
                        <FaCalendarAlt />
                        <span>Upcoming</span>
                    </FilterButton>
                    <FilterButton
                        active={filterType === 'started'}
                        onClick={() => setFilterType('started')}
                        data-tooltip="In Progress"
                    >
                        <FaPlay />
                        <span>In Progress</span>
                    </FilterButton>
                    <FilterButton
                        active={filterType === 'finished'}
                        onClick={() => setFilterType('finished')}
                        data-tooltip="Finished"
                    >
                        <FaCheckCircle />
                        <span>Finished</span>
                    </FilterButton>
                </TableFilters>

                <TableHeader>
                    <div>Venue</div>
                    <div>Meeting Details</div>
                    <div>Next Race</div>
                    <div>Status</div>
                    <div>Weather</div>
                </TableHeader>

                {filteredMeetings.map((meeting) => {
                    const status = getMeetingStatus(meeting);
                    const isSelected = selectedMeetings?.includes(meeting.meetingName);
                    const nextRace = meeting.races?.find((race: any) =>
                        new Date(race.raceStartTime) > new Date()
                    );

                    return (
                        <TableRow
                            key={meeting.meetingName}
                            isSelected={isSelected}
                            isStarted={status === 'started' || status === 'finished'}
                            onClick={() => handleMeetingSelect(meeting.meetingName)}
                        >
                            <VenueCell>{meeting.venueMnemonic}</VenueCell>

                            <MeetingCell>
                                <div className="meeting-name">{meeting.meetingName}</div>
                                <div className="meeting-info">
                                    <span>{meeting.races?.length || 0} Races</span>
                                    {meeting.trackCondition && (
                                        <span>Track: {meeting.trackCondition}</span>
                                    )}
                                </div>
                            </MeetingCell>

                            <div>
                                {nextRace ? (
                                    `R${nextRace.raceNumber} (${getTimeDisplay(nextRace.raceStartTime)})`
                                ) : (
                                    'Complete'
                                )}
                            </div>

                            <StatusCell status={status}>
                                {status.charAt(0).toUpperCase() + status.slice(1)}
                            </StatusCell>

                            <div>
                                {meeting.weatherCondition ? (
                                    <span>
                                        {meeting.weatherCondition === 'FINE' ? (
                                            <WiDaySunny />
                                        ) : (
                                            <WiCloudy />
                                        )}{' '}
                                        {meeting.weatherCondition}
                                    </span>
                                ) : (
                                    'N/A'
                                )}
                            </div>
                        </TableRow>
                    );
                })}
            </MeetingsTable>
        );
    };

    // Add these new styled components near the other styled components

    const RunnerImage = styled.div`
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid ${({ theme }) => theme.colors.border};
      margin-right: 12px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `;

    const RunnerMainInfo = styled.div`
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
    `;

    const JockeyInfo = styled.div`
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 11px;
      color: #666;
      margin-top: 2px;

      svg {
        color: #40B549;
        font-size: 10px;
      }
    `;

    const TagContainer = styled.div`
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      margin: 8px 0;
    `;

    const RunnerStats = styled.div`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      margin: 12px 0;
      font-size: 12px;
    `;

    const StatBox = styled.div<{ highlight: boolean }>`
      background: ${({ highlight }) => highlight ? '#e8f5e9' : '#f5f5f5'};
      padding: 6px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .label {
        color: #666;
        font-size: 10px;
        margin-bottom: 2px;
      }

      .value {
        color: ${({ highlight }) => highlight ? '#2e7d32' : '#333'};
        font-weight: ${({ highlight }) => highlight ? '600' : '500'};
      }
    `;

    const RunnerWarning = styled.div`
      background: #fff3e0;
      color: #f57c00;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 11px;
      display: flex;
      align-items: center;
      gap: 4px;
      margin-top: 8px;
    `;

    const JockeyStats = styled.div`
      display: flex;
      gap: 8px;
      margin-top: 8px;
      font-size: 11px;
    `;

    const JockeyStat = styled.span<{ value: number }>`
      color: ${({ value }) => value > 20 ? '#2e7d32' : value > 10 ? '#f57c00' : '#666'};
      display: flex;
      align-items: center;
      gap: 4px;
    `;

    // Add this near other styled components
    const FavoriteIcon = styled(FaStar)`
      position: absolute;
      top: -12px;
      right: -12px;
      color: gold;
      font-size: 24px;
      filter: drop-shadow(0 2px 2px rgba(0,0,0,0.2));
    `;

    const shimmerKeyframes = keyframes`
      0% { background-position: -200% center; }
      100% { background-position: 200% center; }
    `;

    const pulseKeyframes = keyframes`
      0% { transform: scale(1); }
      50% { transform: scale(1.1); }
      100% { transform: scale(1); }
    `;

    const sparkleKeyframes = keyframes`
      0%, 100% { border-color: rgba(255, 255, 255, 0.8); }
      25% { border-color: rgba(255, 255, 255, 0.4); }
      50% { border-color: rgba(255, 255, 255, 1); }
      75% { border-color: rgba(255, 255, 255, 0.6); }
    `;

    const rotateKeyframes = keyframes`
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    `;

    const RunnerIcon = styled.div<{ type: 'favorite' | 'stable' | 'probability' | 'place' }>`
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: ${({ type }) => {
        switch (type) {
          case 'favorite':
            return 'linear-gradient(135deg, #FFD700 0%, #FFA500 100%, #FFD700 200%)';
          case 'stable':
            return 'linear-gradient(135deg, #4CAF50 0%, #45a049 100%, #4CAF50 200%)';
          case 'probability':
            return 'linear-gradient(135deg, #2196F3 0%, #1976D2 100%, #2196F3 200%)';
          case 'place':
            return 'linear-gradient(135deg, #FAA700 0%, #FAAC00 100%, #FAA700 200%)';
        }
      }};
      background-size: 200% 100%;
      animation: ${shimmerKeyframes} 3s linear infinite;
      box-shadow: 0 2px 4px rgba(0,0,0,0.2),
                  0 0 10px rgba(255, 255, 255, 0.5),
                  0 0 20px rgba(255, 255, 255, 0.3);
      border: 2px solid white;
      z-index: 5;
      transition: all 0.3s ease;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
        border: 2px solid transparent;
        border-radius: 50%;
        animation: ${sparkleKeyframes} 2s linear infinite,
                   ${rotateKeyframes} 3s linear infinite;
        background: linear-gradient(45deg,
          transparent 40%,
          rgba(255, 255, 255, 0.8) 45%,
          rgba(255, 255, 255, 0) 50%,
          transparent 60%
        );
        pointer-events: none;
      }

      &:hover {
        animation: ${pulseKeyframes} 1s ease infinite;
        box-shadow: 0 4px 8px rgba(0,0,0,0.3),
                    0 0 15px rgba(255, 255, 255, 0.6),
                    0 0 30px rgba(255, 255, 255, 0.4);

        &::before {
          animation: ${sparkleKeyframes} 1s linear infinite,
                     ${rotateKeyframes} 2s linear infinite;
        }
      }

      svg {
        color: white;
        font-size: 14px;
        filter: drop-shadow(0 1px 1px rgba(0,0,0,0.2));
      }
    `;

    const NOTABLE_JOCKEYS = [
        'JAMES MCDONALD',
        'DAMIEN OLIVER',
        'CRAIG WILLIAMS',
        'HUGH BOWMAN',
        'WILLIAM PIKE',
        'KERRIN MCEVOY',
        'GLEN BOSS',
        'BRETT PREBBLE',
        'NASH RAWILLER',
        'DAMIAN LANE'
    ];

    // Add this helper function to determine bet types
    const determineBetType = (bet: BetRecommendation, allBets: BetRecommendation[]) => {
        // Sort by stability score
        const topStabilityBets = [...allBets]
            .sort((a, b) => (b.stabilityScore || 0) - (a.stabilityScore || 0))
            .slice(0, 2);

        // Find highest odds among stable runners (stability > 8)
        const stableHighOddsBets = allBets
            .filter(b => (b.stabilityScore || 0) > 8)
            .sort((a, b) => (b.currentOdds || 0) - (a.currentOdds || 0))
            .slice(0, 1);

        if (topStabilityBets.map(b => b.runnerNumber).includes(bet.runnerNumber)) {
            return 'win';
        } else if (stableHighOddsBets.map(b => b.runnerNumber).includes(bet.runnerNumber)) {
            return 'place';
        }
        return null;
    };

    // Add these interfaces
    interface ShareableData {
        id: string;
        date: string;
        jurisdiction: string;
        raceType: string;
        meetingSelections: string[];
        predictions: GroupedRacePredictions;
        timestamp: number;
    }

    // Add these helper functions
    const generateShareId = (): string => {
        return 'xxxx-xxxx-xxxx-xxxx'.replace(/[x]/g, () => {
            return (Math.random() * 16 | 0).toString(16);
        });
    };

    const createShareableData = (): ShareableData => {
        return {
            id: generateShareId(),
            date: selectedDate,
            jurisdiction,
            raceType,
            meetingSelections: selectedMeetings,
            predictions: racePredictions,
            timestamp: Date.now()
        };
    };

    // Add these functions to handle sharing
    const handleShare = async () => {
        try {
            const shareableData = createShareableData();

            // Store in localStorage (or you could use your API)
            const existingShares = JSON.parse(localStorage.getItem('sharedResults') || '{}');
            existingShares[shareableData.id] = shareableData;
            localStorage.setItem('sharedResults', JSON.stringify(existingShares));

            // Generate shareable URL
            const shareUrl = `${window.location.origin}/shared/${shareableData.id}`;

            // Try to use native sharing if available
            if (navigator.share) {
                await navigator.share({
                    title: 'Race Predictions',
                    text: 'Check out these race predictions!',
                    url: shareUrl
                });
            } else {
                // Fallback to copying to clipboard
                await navigator.clipboard.writeText(shareUrl);
                alert('Share link copied to clipboard!');
            }
        } catch (error) {
            console.error('Error sharing results:', error);
            alert('Failed to share results. Please try again.');
        }
    };




    const RaceHeaderActions = styled.div`
      display: flex;
      align-items: center;
      gap: 12px;
      margin-left: auto; // Push the entire group to the right
    `;

    const RaceActionButton = styled(Button)`
      padding: 8px 12px;
      font-size: 12px;
      min-width: auto;
      background: #40B549;
      color: white;

      &:hover {
        background: #379940;
      }

      &:disabled {
        background: #ccc;
        cursor: not-allowed;
      }
    `;

    // Add the API function
    const handleAddRace = async (race: RacePrediction) => {
        try {
            // Transform the race object to match the expected type
            const transformedRace = {
                ...race,
                horseNumber: race.predictions?.[0]?.runnerNumber || 0,
                horseName: race.predictions?.[0]?.runnerName || ''
            };

            const response = await apiService.addRace(transformedRace);
        } catch (error) {
            console.error('Error adding race:', error);
        }
    };

    const TabyLogo = styled.img`
      margin-bottom: 24px;
      animation: ${pulseAndGlow} 2s infinite;
    `;

    // Add these styled components near the other styled components

    const LoadingOverlay = styled.div`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.9); // Slight transparency for a softer look
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      padding: 40px;
      text-align: center;
      color: #333; // Softer black for better readability
      font-size: 18px;
      font-weight: 500;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    `;

    const ProcessingInfo = styled.div`
      margin: 20px 0;
      color: #333; // Softer black for better readability
      font-size: 18px;
      font-weight: 500;
      background: #fff; // Consistent white background
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    `;

    const LoadingProgressBar = styled.div`
      width: 80%;
      max-width: 600px;
      height: 10px;
      background: #e0e0e0; // Light grey for better contrast
      border-radius: 5px;
      margin-top: 20px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    `;

    const LoadingProgressFill = styled.div<{ progress: number }>`
      height: 100%;
      width: ${({ progress }) => `${progress}%`};
      background: linear-gradient(135deg, #40B549, #2D8A34);
      transition: width 0.3s ease;
      border-radius: 5px;
    `;

    const ProcessingStats = styled.div`
      display: flex;
      justify-content: space-around;
      width: 100%;
      max-width: 600px;
      margin: 20px 0;
      flex-wrap: wrap; // Ensure it wraps on smaller screens

      span {
        background: #f5f5f5; // Light grey for better contrast
        padding: 12px 20px;
        border-radius: 20px;
        font-size: 16px;
        color: #333; // Softer black for better readability
        text-align: center;
        border: 1px solid #ddd; // Light border for definition
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
        margin: 8px; // Add margin for spacing between items
      }
    `;

    // Add these styled components near the other styled components

    const BetOption = styled.button`
      background: none;
      border: none;
      padding: 6px 12px;
      color: #666;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: inherit;
      transition: all 0.2s ease;
      border-radius: 4px;

      &:hover {
        color: #40B549;
        background: #f5f5f5;
      }

      svg {
        font-size: 12px;
      }
    `;

    const BetRunners = styled.span`
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    `;

    // Add this new styled component with the other styled components
    const ResultsInfoBar = styled.div`
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      background: #f8f9fa;
      border-radius: 4px;
      margin: 8px 0;
      font-size: 13px;
    `;

    const ResultNumber = styled.span<{ position: number }>`
      background: ${({ position }) => {
        switch (position) {
          case 1:
            return '#FFD700'; // Gold
          case 2:
            return '#C0C0C0'; // Silver
          case 3:
            return '#CD7F32'; // Bronze
          default:
            return '#e0e0e0'; // Grey
        }
      }};
      color: #333;
      padding: 2px 8px;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 500;
      margin-left: 4px;

      @media (max-width: 480px) {
        padding: 2px 6px;
        font-size: 12px;
      }
    `;

    const ResultsBar = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f8f9fa;
      padding: 8px 16px;
      border-radius: 4px;
      width: 100%;
      margin: 8px 0;
      box-sizing: border-box;

      .left-content {
        font-size: 14px;
        color: #666;
      }

      .right-content {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-left: auto;
      }

      @media (max-width: 480px) {
        padding: 8px;
        font-size: 12px;
      }
    `;

    const DistanceText = styled.span`
      color: #666;
      white-space: nowrap;
      font-size: 14px;
      margin-right: 8px;

      @media (max-width: 480px) {
        font-size: 12px;
      }
    `;

    const ResultsText = styled.span`
      color: #666;
      white-space: nowrap;
      font-size: 14px;
      margin-right: 4px;

      @media (max-width: 480px) {
        font-size: 12px;
      }
    `;

    const AddRaceButton = styled.button`
      background: #4CAF50;
      color: white;
      border: none;
      padding: 6px 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      font-size: 13px;
      white-space: nowrap;

      &:hover {
        background: #45a049;
      }

      @media (max-width: 480px) {
        padding: 4px 8px;
        font-size: 12px;
      }

      svg {
        font-size: 12px;
      }
    `;

    const hasValidResults = (results?: number[]) => {
        return results && results.length > 0 && results.some(result => result !== 0);
    };

    const RunnerName = styled.h4`
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;

    const RunnerNumber = styled.div`
      background: #2c1f56;
      color: white;
      padding: 4px 8px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      margin-right: 8px;
    `;

    // Function to normalize and format the consistency value
    const normalizeValue = (value: number): string => {
        if (value > 100) {
            return '100.0'; // Cap the value at 100
        }
        return value.toFixed(1); // Format to two decimal places
    };

    // Helper function to format race start time
    const formatRaceTime = (startTime: string): string => {
        const raceTime = new Date(startTime);
        const now = new Date();

        if (raceTime > now) {
            return raceTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
            return 'complete';
        }
    };

    // Helper function to determine if the race is complete
    const isRaceComplete = (startTime: string): boolean => {
        const raceTime = new Date(startTime);
        return raceTime <= new Date();
    };

    // Add this function to get yesterday's date in the same format as meetingDates
    const getYesterdayDate = () => {
        const yesterday = subDays(new Date(), 1);
        return {
            meetingDate: format(yesterday, 'yyyy-MM-dd'),
            hasRaces: true
        };
    };

    // Add this function inside the component to determine meeting status
    const getMeetingStatus = (meeting: any): 'upcoming' | 'started' | 'finished' => {
        const now = new Date();
        const firstRace = meeting.races?.[0];
        const lastRace = meeting.races?.[meeting.races.length - 1];

        if (!firstRace || !lastRace) return 'finished';

        const firstRaceTime = new Date(firstRace.raceStartTime);
        const lastRaceTime = new Date(lastRace.raceStartTime);

        if (now < firstRaceTime) return 'upcoming';
        if (now > lastRaceTime) return 'finished';
        return 'started';
    };

    // Add these styled components after the existing styled components and before the component definition

    const MeetingsTable = styled.div`
      background: white;
      border-radius: 12px;
      overflow: hidden;
      margin: 16px;
      box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    `;

    const TableFilters = styled.div`
      display: flex;
      gap: 8px;
      padding: 12px 16px;
      background: #f8f9fa;
      border-bottom: 1px solid #eee;
      flex-wrap: wrap;
      align-items: center;
    `;

    const FilterButton = styled.button<{ active: boolean }>`
      padding: 6px 12px;
      border-radius: 20px;
      border: 2px solid ${({ active }) => active ? '#2c1f56' : '#e0e0e0'};
      background: ${({ active }) =>
            active ? 'linear-gradient(135deg, #2c1f56 0%, #1a1433 100%)' : 'white'};
      color: ${({ active }) => active ? 'white' : '#666'};
      cursor: pointer;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 6px;
      transition: all 0.2s ease;
      position: relative;
      font-weight: 500;
      box-shadow: ${({ active }) =>
            active ? '0 2px 4px rgba(44, 31, 86, 0.2)' : 'none'};

      &:hover {
        border-color: #2c1f56;
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(44, 31, 86, 0.2);
      }

      &:active {
        transform: translateY(0);
      }

      span {
        @media (max-width: 768px) {
          display: none;
        }
      }

      svg {
        font-size: 14px;
        color: ${({ active }) => active ? 'white' : '#2c1f56'};
      }

      @media (max-width: 768px) {
        padding: 8px;
        min-width: 36px;
        justify-content: center;
      }
    `;

    const TableHeader = styled.div`
      display: grid;
      grid-template-columns: 80px 1fr 120px 120px 120px;
      padding: 12px 16px;
      background: #2c1f56;
      border-bottom: 1px solid #1a1433;
      font-weight: 500;
      color: white;
      align-items: center;
      font-size: 13px;

      @media (max-width: 768px) {
        grid-template-columns: 60px 1fr 100px;
        & > *:nth-child(4), & > *:nth-child(5) {
          display: none;
        }
      }
    `;

    const TableRow = styled.div<{ isSelected: boolean; isStarted: boolean }>`
      display: grid;
      grid-template-columns: 80px 1fr 120px 120px 120px;
      padding: 12px 16px;
      border-bottom: 1px solid #eee;
      align-items: center;
      background: ${({ isSelected, isStarted }) =>
            isSelected
                ? 'linear-gradient(to right, #e8f5e9, #f8f9fa)'
                : isStarted
                    ? '#f8f9fa'
                    : 'white'};
      cursor: pointer;
      position: relative;
      transition: all 0.2s ease;

      &:hover {
        background: ${({ isSelected }) =>
            isSelected
                ? 'linear-gradient(to right, #d5ecd7, #f8f9fa)'
                : '#f5f5f5'};
        transform: translateX(2px);
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background: ${({ isSelected }) =>
            isSelected
                ? 'linear-gradient(to bottom, #40B549, #2D8A34)'
                : 'transparent'};
        transition: all 0.2s ease;
      }

      @media (max-width: 768px) {
        grid-template-columns: 60px 1fr 100px;
        & > *:nth-child(4), & > *:nth-child(5) {
          display: none;
        }
      }
    `;

    const VenueCell = styled.div`
      font-weight: 500;
      color: #2c1f56;
      text-transform: uppercase;
    `;

    const MeetingCell = styled.div`
      display: flex;
      flex-direction: column;
      gap: 4px;
      min-width: 0;

      .meeting-name {
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .meeting-info {
        font-size: 12px;
        color: #666;
        display: flex;
        gap: 8px;
      }
    `;

    const StatusCell = styled.div<{ status: 'upcoming' | 'started' | 'finished' }>`
      padding: 4px 12px;
      border-radius: 20px;
      font-size: 12px;
      text-align: center;
      width: fit-content;
      font-weight: 500;

      ${({ status }) => {
            switch (status) {
                case 'upcoming':
                    return `
              background: linear-gradient(135deg, #e8f5e9 0%, #c8e6c9 100%);
              color: #2e7d32;
              border: 1px solid #a5d6a7;
            `;
                case 'started':
                    return `
              background: linear-gradient(135deg, #fff3e0 0%, #ffe0b2 100%);
              color: #f57c00;
              border: 1px solid #ffcc80;
            `;
                case 'finished':
                    return `
              background: linear-gradient(135deg, #f5f5f5 0%, #eeeeee 100%);
              color: #666;
              border: 1px solid #e0e0e0;
            `;
            }
        }}
    `;

    // Add these new styled components after the existing styled components

    const SelectionControls = styled.div`
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 16px;
      background: white;
      border-bottom: 1px solid #eee;
      justify-content: space-between;
    `;

    const SelectAllButton = styled.button<{ allSelected: boolean }>`
      padding: 8px 16px;
      border-radius: 8px;
      border: none;
      background: ${({ allSelected }) =>
            allSelected
                ? 'linear-gradient(135deg, #40B549 0%, #2D8A34 100%)'
                : 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)'};
      color: ${({ allSelected }) => allSelected ? 'white' : '#2c1f56'};
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 6px;
      transition: all 0.2s ease;
      box-shadow: ${({ allSelected }) =>
            allSelected
                ? '0 2px 4px rgba(64, 181, 73, 0.2)'
                : '0 1px 3px rgba(0, 0, 0, 0.1)'};

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background: ${({ allSelected }) =>
            allSelected
                ? 'linear-gradient(135deg, #45c34f 0%, #339939 100%)'
                : 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)'};
      }

      &:active {
        transform: translateY(0);
      }

      svg {
        font-size: 14px;
        color: ${({ allSelected }) => allSelected ? 'white' : '#40B549'};
      }
    `;

    const SelectionCount = styled.span`
      font-size: 13px;
      color: #666;
      display: flex;
      align-items: center;
      gap: 6px;
      background: #f8f9fa;
      padding: 8px 16px;
      border-radius: 8px;
      border: 1px solid #eee;

      strong {
        color: #2c1f56;
        font-weight: 600;
      }

      svg {
        color: #40B549;
      }
    `;

    // Add this function to the component
    const toggleAllMeetings = (meetings: any[]) => {
        const currentlySelectedCount = selectedMeetings?.length || 0;
        const totalMeetings = meetings.length;

        if (currentlySelectedCount === totalMeetings) {
            // If all are selected, unselect all
            setSelectedMeetings([]);
        } else {
            // Otherwise, select all
            setSelectedMeetings(meetings.map(meeting => meeting.meetingName));
        }
    };

    // Add these styled components after the existing styled components
    const FormSection = styled.div<{ isOpen: boolean }>`
      max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      background: #f8f9fa;
      border-radius: 0 0 12px 12px;
      margin-top: ${({ isOpen }) => (isOpen ? '8px' : '0')};
    `;

    const FormHeader = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        font-size: 12px;
        color: #666;
    `;

    const StartHistory = styled.div`
        display: flex;
        gap: 4px;
        margin: 8px 0;
        overflow-x: auto;
        padding-bottom: 4px;

        &::-webkit-scrollbar {
            height: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #ddd;
            border-radius: 2px;
        }
    `;

    const StartRecord = styled.div<{ position: string }>`
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        background: ${({ position }) => {
            if (position === '1') return '#e8f5e9';
            if (position === '2' || position === '3') return '#fff3e0';
            return '#f5f5f5';
        }};
        color: ${({ position }) => {
            if (position === '1') return '#2e7d32';
            if (position === '2' || position === '3') return '#f57c00';
            return '#666';
        }};
    `;

    const BetTrends = styled.div`
        display: flex;
        gap: 8px;
        margin: 8px 0;
    `;

    const TrendItem = styled.div<{ positive: boolean }>`
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 11px;
        background: ${({ positive }) => positive ? '#e8f5e9' : '#fff3e0'};
        color: ${({ positive }) => positive ? '#2e7d32' : '#f57c00'};
    `;

    // Add state for form visibility
    const [openForms, setOpenForms] = useState<{ [key: number]: boolean }>({});

    // Replace the existing toggleForm function with this optimized version
    const toggleForm = (runnerNumber: number, event: React.MouseEvent) => {
        event.stopPropagation();

        if (activeFormRunner === runnerNumber) {
            setActiveFormRunner(null);
            setFormPosition(null);
        } else {
            const rect = event.currentTarget.getBoundingClientRect();
            setFormPosition({
                x: rect.left,
                y: rect.bottom + window.scrollY
            });
            setActiveFormRunner(runnerNumber);
        }
    };

    // Add these styled components with the others
    const FormButton = styled.button`
      width: 100%;
      padding: 8px;
      background: #f8f9fa;
      border: 1px solid #eee;
      border-radius: 8px;
      color: #666;
      font-size: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      transition: all 0.2s;
      margin: 8px 0;

      &:hover {
        background: #fff;
        border-color: #40B549;
        color: #40B549;
      }

      svg {
        font-size: 14px;
      }
    `;

    const FormContent = styled.div`
      padding: 16px;

      .form-comment {
        font-size: 14px;
        color: #666;
        margin-bottom: 12px;
        line-height: 1.4;
      }

      .prize-money {
        font-weight: 500;
        color: #2e7d32;
        margin-bottom: 8px;
      }

      .last-starts {
        font-family: monospace;
        font-size: 16px;
        letter-spacing: 2px;
        color: #333;
      }
    `;

    useEffect(() => {
        const newStats = calculatePredictionStats(racePredictions);
        setStats(newStats);
    }, [racePredictions]);

    // Add near other functions in the component
    const toggleFilter = (filterId: string) => {
        setActiveFilters((filters) =>
            filters.map((filter) => (filter.id === filterId ? { ...filter, active: !filter.active } : filter))
        );
    };

    interface ReportData {
        totalWins: number;
        totalPlaces: number;
        averageWinningOdds: number;
        averagePlacingOdds: number;
    }

    interface RaceAccuracy {
        raceName: string;
        accuracy: number;
        predictions: number[];
        results: number[];
    }

    interface PredictionStats {
        totalPredictions: number;
        correctWinners: number;
        correctPlacings: number;
        averageOdds: number;
        profitLoss: number;
        winStrikeRate: number;
        placeStrikeRate: number;
        topPickStats: {
            wins: number;
            places: number;
            total: number;
            avgOdds: number;
        };
        predictionAccuracy: {
            raceByRaceAccuracy: RaceAccuracy[];
        };
        reportData: ReportData;
    }

    // Add this inside the component, before the return statement
    const calculateAnalyticsData = () => {
        const predictionStats = {
            totalPredictions: 0,
            correctPredictions: 0,
            winStrikeRate: 0,
            placeStrikeRate: 0,
            profitLoss: 0
        };

        const exactOrderAccuracy = {
            first: 0,
            firstTwo: 0,
            firstThree: 0,
            allFour: 0,
            totalRaces: 0
        };

        const anyOrderAccuracy = {
            first: 0,
            firstTwo: 0,
            firstThree: 0,
            allFour: 0,
            totalRaces: 0
        };

        const historicalData: any[] = [];
        const bettingTrends: any[] = [];

        // Process only completed races
        Object.values(racePredictions).forEach(races => {
            races.forEach(race => {
                try {
                    // Validate race data
                    if (!race || !race.predictions || !race.results) {
                        console.warn('Incomplete race data:', race);
                        return;
                    }

                    // Validate predictions and results
                    const validPredictions = race.predictions.filter(p => p && typeof p.runnerNumber === 'number');
                    const validResults = race.results.filter(r => typeof r === 'number');

                    if (validPredictions.length < 4 || validResults.length < 4) {
                        console.warn('Insufficient predictions or results:', {
                            predictionsCount: validPredictions.length,
                            resultsCount: validResults.length
                        });
                        return;
                    }

                    exactOrderAccuracy.totalRaces++;
                    anyOrderAccuracy.totalRaces++;

                    // Get top 4 predictions and results
                    const topFourPredictions = validPredictions
                        .slice(0, 4)
                        .map(p => p.runnerNumber);
                    const topFourResults = validResults.slice(0, 4);

                    // Check exact order matches
                    if (topFourPredictions[0] === topFourResults[0]) {
                        exactOrderAccuracy.first++;
                    }
                    if (topFourPredictions[0] === topFourResults[0] &&
                        topFourPredictions[1] === topFourResults[1]) {
                        exactOrderAccuracy.firstTwo++;
                    }
                    if (topFourPredictions[0] === topFourResults[0] &&
                        topFourPredictions[1] === topFourResults[1] &&
                        topFourPredictions[2] === topFourResults[2]) {
                        exactOrderAccuracy.firstThree++;
                    }
                    if (topFourPredictions.every((num, index) => num === topFourResults[index])) {
                        exactOrderAccuracy.allFour++;
                    }

                    // Check any order matches
                    if (topFourResults.includes(topFourPredictions[0])) {
                        anyOrderAccuracy.first++;
                    }

                    // Check for any two matches in first two positions
                    const firstTwoMatches = topFourPredictions.slice(0, 2).filter(num =>
                        topFourResults.slice(0, 2).includes(num)
                    ).length;
                    if (firstTwoMatches >= 2) {
                        anyOrderAccuracy.firstTwo++;
                    }

                    // Check for any three matches in first three positions
                    const firstThreeMatches = topFourPredictions.slice(0, 3).filter(num =>
                        topFourResults.slice(0, 3).includes(num)
                    ).length;
                    if (firstThreeMatches >= 3) {
                        anyOrderAccuracy.firstThree++;
                    }

                    // Check for all four matches in any position
                    const allMatches = topFourPredictions.filter(num =>
                        topFourResults.includes(num)
                    ).length;
                    if (allMatches === 4) {
                        anyOrderAccuracy.allFour++;
                    }

                    // Get prediction metrics safely
                    const topPrediction = validPredictions[0] || {};

                    // Add historical data point
                    historicalData.push({
                        date: new Date(race.startTime).toLocaleDateString(),
                        exactOrderAccuracy: (exactOrderAccuracy.first / exactOrderAccuracy.totalRaces) * 100,
                        anyOrderAccuracy: (anyOrderAccuracy.first / anyOrderAccuracy.totalRaces) * 100,
                        proMoneyFlow: topPrediction.probabilityScore || 0,
                        marketConfidence: topPrediction.stabilityScore || 0,
                        formRating: topPrediction.competitionScore || 0,
                        speedRating: topPrediction.speedRating || 0,
                        classRating: topPrediction.classLevelFactor || 0
                    });
                } catch (error) {
                    console.error('Error processing race:', error, race);
                }
            });
        });

        // Calculate overall stats
        if (exactOrderAccuracy.totalRaces > 0) {
            predictionStats.winStrikeRate = (exactOrderAccuracy.first / exactOrderAccuracy.totalRaces) * 100;
            predictionStats.placeStrikeRate = (anyOrderAccuracy.first / anyOrderAccuracy.totalRaces) * 100;
        }

        // Update betting trends with actual percentages
        const totalRaces = exactOrderAccuracy.totalRaces;
        if (totalRaces > 0) {
            bettingTrends.push(
                {
                    name: 'Exact First',
                    value: (exactOrderAccuracy.first / totalRaces) * 100
                },
                {
                    name: 'Any in First Four',
                    value: (anyOrderAccuracy.first / totalRaces) * 100
                },
                {
                    name: 'Multiple in First Four',
                    value: (anyOrderAccuracy.firstTwo / totalRaces) * 100
                },
                {
                    name: 'All Four (Any Order)',
                    value: (anyOrderAccuracy.allFour / totalRaces) * 100
                }
            );
        }

        predictionStats.totalPredictions = exactOrderAccuracy.totalRaces * 4; // 4 predictions per race
        predictionStats.correctPredictions = anyOrderAccuracy.first;

        const bettingAnalysis = {
            startingBalance: 300, // $300 starting bank
            dailyBets: 100,
            daysAnalyzed: 10,
            betsPerStrategy: {
                eachWayTop: { wins: 0, places: 0, totalBets: 0, profit: 0 },
                placeSecond: { wins: 0, places: 0, totalBets: 0, profit: 0 },
                winHighestOdds: { wins: 0, places: 0, totalBets: 0, profit: 0 }
            },
            totalInvestment: 0,
            totalReturns: 0,
            netProfit: 0,
            roi: 0,
            projectedYearlyProfit: 0,
            betStrings: [] as string[]
        };

        // Process races for betting analysis
        Object.values(racePredictions).forEach(races => {
            races.forEach(race => {
                // Debug log to check race data
                console.log('Processing race:', {
                    meetingName: race.meetingName,
                    raceNumber: race.raceNumber,
                    sellCode: race.meeting?.sellCode,
                    predictions: race.predictions?.length
                });

                // Validate required data
                if (!race.predictions || !race.meeting?.sellCode) {
                    console.warn('Missing required data for race:', race.raceNumber);
                    return;
                }

                const sortedByConfidence = [...race.predictions]
                    .sort((a, b) => (b.probabilityScore || 0) - (a.probabilityScore || 0));

                const sortedByOdds = [...race.predictions]
                    .sort((a, b) => (b.currentOdds || 0) - (a.currentOdds || 0));

                const topPick = sortedByConfidence[0];
                const secondPick = sortedByConfidence[1];
                const highestOddsPick = sortedByOdds[0];

                if (topPick && secondPick && highestOddsPick) {
                    // Debug log bet data
                    console.log('Generating bets for race:', {
                        topPick: topPick.runnerNumber,
                        secondPick: secondPick.runnerNumber,
                        highestOddsPick: highestOddsPick.runnerNumber
                    });

                    // Each way bet on top pick
                    const topPickEachWayString = generateBetString(
                        race,
                        topPick.runnerNumber,
                        'WP',
                        formatAmount(1.0),
                        formatAmount(1.0)
                    );
                    bettingAnalysis.betStrings.push(topPickEachWayString);

                    // Place bet on second pick
                    const secondPickPlaceString = generateBetString(
                        race,
                        secondPick.runnerNumber,
                        'PLACE',
                        formatAmount(1.0),
                        formatAmount(0.0)
                    );
                    bettingAnalysis.betStrings.push(secondPickPlaceString);

                    // Win bet on highest odds
                    const highestOddsWinString = generateBetString(
                        race,
                        highestOddsPick.runnerNumber,
                        'WIN',
                        formatAmount(1.0),
                        formatAmount(0.0)
                    );
                    bettingAnalysis.betStrings.push(highestOddsWinString);

                    // Debug log generated strings
                    console.log('Generated bet strings:', {
                        topPickEachWayString,
                        secondPickPlaceString,
                        highestOddsWinString
                    });
                }
            });
        });

        // Calculate overall performance
        bettingAnalysis.totalReturns =
            bettingAnalysis.betsPerStrategy.eachWayTop.profit +
            bettingAnalysis.betsPerStrategy.placeSecond.profit +
            bettingAnalysis.betsPerStrategy.winHighestOdds.profit;

        bettingAnalysis.netProfit = bettingAnalysis.totalReturns - bettingAnalysis.totalInvestment;
        bettingAnalysis.roi = (bettingAnalysis.netProfit / bettingAnalysis.totalInvestment) * 100;

        // Project yearly performance
        const daysInYear = 365;
        const projectedDailyProfit = bettingAnalysis.netProfit / bettingAnalysis.daysAnalyzed;
        bettingAnalysis.projectedYearlyProfit = projectedDailyProfit * daysInYear;

        return {
            predictionStats,
            historicalData,
            bettingTrends,
            accuracyStats: {
                exactOrder: {
                    first: (exactOrderAccuracy.first / exactOrderAccuracy.totalRaces) * 100,
                    firstTwo: (exactOrderAccuracy.firstTwo / exactOrderAccuracy.totalRaces) * 100,
                    firstThree: (exactOrderAccuracy.firstThree / exactOrderAccuracy.totalRaces) * 100,
                    allFour: (exactOrderAccuracy.allFour / exactOrderAccuracy.totalRaces) * 100,
                },
                anyOrder: {
                    first: (anyOrderAccuracy.first / anyOrderAccuracy.totalRaces) * 100,
                    firstTwo: (anyOrderAccuracy.firstTwo / anyOrderAccuracy.totalRaces) * 100,
                    firstThree: (anyOrderAccuracy.firstThree / anyOrderAccuracy.totalRaces) * 100,
                    allFour: (anyOrderAccuracy.allFour / anyOrderAccuracy.totalRaces) * 100,
                },
                totalRaces: exactOrderAccuracy.totalRaces
            },
            bettingAnalysis
        };
    };

    const MetricsDisplay = () => {
        return (
            <div>
                <h3>Performance Metrics</h3>
                <div>
                    <strong>Win Strike Rate:</strong> 5.6% <span style={{ color: 'red' }}>↓ Below Market</span>
                    <p>This is the percentage of races where the predicted winner actually won. A lower rate indicates fewer successful predictions compared to the market average.</p>
                </div>
                <div>
                    <strong>Place Strike Rate:</strong> 18.6% <span style={{ color: 'red' }}>↓ Below Market</span>
                    <p>This is the percentage of races where the predicted horse finished in a placing position (e.g., top 3). A lower rate suggests fewer successful place predictions compared to the market average.</p>
                </div>
                <div>
                    <strong>Average Winner Odds:</strong> $13.26 <span style={{ color: 'green' }}>↑ Above Market</span>
                    <p>This represents the average odds of the winning predictions. Higher odds indicate potentially higher returns on successful predictions.</p>
                </div>
                <div>
                    <strong>Profit/Loss:</strong> $900.50 <span style={{ color: 'green' }}>↑ Above Market</span>
                    <p>This is the net profit or loss from the betting strategy. A positive value indicates a profitable strategy compared to the market average.</p>
                </div>
            </div>
        );
    };

    const BettingStrategyTable = styled.table`
        width: 100%;
        border-collapse: collapse;
        margin: 24px 0;
        background: white;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0,0,0,0.1);

        th, td {
            padding: 12px 16px;
            text-align: left;
            border-bottom: 1px solid #eee;
        }

        th {
            background: #f8f9fa;
            font-weight: 500;
            color: #2c1f56;
            font-size: 14px;
        }

        td {
            font-size: 14px;
            color: #666;
        }

        tbody tr:hover {
            background: #f8f9fa;
        }
    `;

    const BetStringsSection = styled.div`
        margin: 24px 0;
        padding: 24px;
        background: #f8f9fa;
        border-radius: 8px;

        h3 {
            margin: 0 0 16px 0;
            color: #2c1f56;
        }

        p {
            margin: 0 0 16px 0;
            color: #666;
        }
    `;

    const BetStringsContainer = styled.pre`
        background: #2c1f56;
        color: #fff;
        padding: 16px;
        border-radius: 8px;
        overflow-x: auto;
        font-family: monospace;
        font-size: 14px;
        line-height: 1.5;
        margin: 16px 0 0 0;

        code {
            display: block;
            white-space: pre-wrap;
        }
    `;

    const CopyButton = styled(Button)`
        background: #40B549;
        color: white;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        font-size: 14px;
        font-weight: 500;

        &:hover {
            background: #379940;
        }

        svg {
            font-size: 16px;
        }
    `;

    // Update the calculateAnalyticsData function to set the betting analysis
    useEffect(() => {
        const analytics = calculateAnalyticsData();
        setBettingAnalysis({
            totalInvestment: analytics.bettingAnalysis.totalInvestment,
            totalReturns: analytics.bettingAnalysis.totalReturns,
            netProfit: analytics.bettingAnalysis.netProfit,
            roi: analytics.bettingAnalysis.roi,
            betsPerStrategy: analytics.bettingAnalysis.betsPerStrategy,
            betStrings: analytics.bettingAnalysis.betStrings
        });
    }, [racePredictions]); // Update when predictions change

    useEffect(() => {
        if (!racePredictions) return;

        const newPredictionAccuracy: PredictionAccuracyStats = {
            totalPredictions: 0,
            exactPredictions: 0,
            topThreePredictions: 0,
            topFivePredictions: 0,
            averagePositionDiff: 0,
            within3Positions: 0,  // Initialize within3Positions
            predictionsByPosition: {},
            raceResults: []
        };

        Object.values(racePredictions).forEach(races => {
            races.forEach(race => {
                if (race.predictions && race.results) {
                    const accuracy = calculatePredictionAccuracy(race.predictions, race.results);
                    if (accuracy) {
                        newPredictionAccuracy.totalPredictions++;
                        newPredictionAccuracy.exactPredictions += accuracy.exactMatches;
                        newPredictionAccuracy.topThreePredictions += accuracy.topThreeMatches;
                        newPredictionAccuracy.topFivePredictions += accuracy.topFiveMatches;
                        newPredictionAccuracy.averagePositionDiff =
                            (newPredictionAccuracy.averagePositionDiff * (newPredictionAccuracy.totalPredictions - 1) +
                                accuracy.averagePositionDiff) / newPredictionAccuracy.totalPredictions;

                        newPredictionAccuracy.raceResults.push({
                            raceName: `${race.meetingName} R${race.raceNumber}`,
                            predictedOrder: accuracy.predictedOrder,
                            actualOrder: accuracy.actualOrder,
                            accuracy: accuracy.exactMatches / accuracy.predictedOrder.length
                        });
                    }
                }
            });
        });

        setPredictionAccuracy(newPredictionAccuracy);
    }, [racePredictions]);

    // Add these new interfaces for detailed analysis
    interface PredictionAnalysis {
        overall: {
            totalRaces: number;
            completedRaces: number;
            totalRunners: number;
            averageRunnersPerRace: number;
            totalPredictions: number;
            successfulPredictions: number;
            accuracy: number;
        };
        positionAccuracy: {
            exactPosition: number;
            within1Position: number;
            within2Positions: number;
            within3Positions: number;
            averagePositionDiff: number;
        };
        winningPicks: {
            top1: number;
            top2: number;
            top3: number;
            top4: number;
            roi: number;
        };
        bettingPerformance: {
            totalBets: number;
            winningBets: number;
            totalStake: number;
            totalReturns: number;
            profitLoss: number;
            roi: number;
            bestBet: {
                race: string;
                runner: string;
                odds: number;
                result: string;
                profit: number;
            };
            betsPerStrategy: {  // Add this field
                eachWayTop: { wins: number; places: number; totalBets: number; profit: number };
                placeSecond: { wins: number; places: number; totalBets: number; profit: number };
                winHighestOdds: { wins: number; places: number; totalBets: number; profit: number };
            };
        };
        patterns: {
            bestTracks: { [key: string]: number };
            bestDistances: { [key: string]: number };
            bestConditions: { [key: string]: number };
            bestPredictionFactors: {
                stabilityScore: number;
                probabilityScore: number;
                speedRating: number;
                classLevel: number;
            };
        };
        timeBasedAnalysis: {
            morningAccuracy: number;
            afternoonAccuracy: number;
            eveningAccuracy: number;
            weekdayAccuracy: number;
            weekendAccuracy: number;
        };
        predictionsByPosition: {
            [key: number]: {
                total: number;
                correct: number;
                averageOdds: number;
            }
        };
        exoticBets: {
            quinella: {
                hits: number;
                attempts: number;
                totalReturns: number;
                avgDividend: number;
            };
            exacta: {
                hits: number;
                attempts: number;
                totalReturns: number;
                avgDividend: number;
            };
            blended: {
                hits: number;
                attempts: number;
                totalReturns: number;
                avgDividend: number;
            };
        };
        oddsAnalysis: {
            averageWinningOdds: number;
            averagePlacingOdds: number;
            winsByOddsRange: {
                [range: string]: number;
            };
            averageOddsWhenCorrect: number;
            averageOddsWhenIncorrect: number;
            quinellaAverageOdds: number;
        };
    }

    // Add this styled component for the new analysis view
    const AnalysisContainer = styled.div`
        padding: 24px;
        background: white;
        border-radius: 12px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    `;

    const AnalysisSection = styled.div`
        margin-bottom: 32px;
    `;

    const AnalysisHeader = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        h3 {
            color: #2c1f56;
            margin: 0;
        }
    `;

    const MetricGrid = styled.div`
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 16px;
        margin-bottom: 24px;
    `;

    const MetricCard = styled.div<{ performance?: 'positive' | 'negative' | 'neutral' }>`
        padding: 16px;
        background: ${({ performance }) => {
            switch (performance) {
                case 'positive': return 'linear-gradient(135deg, #e8f5e9 0%, #c8e6c9 100%)';
                case 'negative': return 'linear-gradient(135deg, #ffebee 0%, #ffcdd2 100%)';
                default: return '#f8f9fa';
            }
        }};
        border-radius: 8px;
        border: 1px solid #eee;

        .label {
            font-size: 12px;
            color: #666;
            margin-bottom: 4px;
        }

        .value {
            font-size: 24px;
            font-weight: 600;
            color: ${({ performance }) => {
            switch (performance) {
                case 'positive': return '#2e7d32';
                case 'negative': return '#c62828';
                default: return '#2c1f56';
            }
        }};
        }

        .trend {
            font-size: 12px;
            color: #666;
            margin-top: 4px;
        }
    `;

    const ChartContainer = styled.div`
        height: 300px;
        margin: 24px 0;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #eee;
        padding: 16px;
    `;

    // Add this function to calculate comprehensive analysis
    const calculateComprehensiveAnalysis = (racePredictions: GroupedRacePredictions): PredictionAnalysis => {
        const analysis: PredictionAnalysis = {
            overall: {
                totalRaces: 0,
                completedRaces: 0,
                totalRunners: 0,
                averageRunnersPerRace: 0,
                totalPredictions: 0,
                successfulPredictions: 0,
                accuracy: 0
            },
            positionAccuracy: {
                exactPosition: 0,
                within1Position: 0,
                within2Positions: 0,
                within3Positions: 0,
                averagePositionDiff: 0
            },
            winningPicks: {
                top1: 0,
                top2: 0,
                top3: 0,
                top4: 0,
                roi: 0
            },
            bettingPerformance: {
                totalBets: 0,
                winningBets: 0,
                totalStake: 0,
                totalReturns: 0,
                profitLoss: 0,
                roi: 0,
                bestBet: {
                    race: '',
                    runner: '',
                    odds: 0,
                    result: '',
                    profit: 0
                },
                betsPerStrategy: {
                    eachWayTop: { wins: 0, places: 0, totalBets: 0, profit: 0 },
                    placeSecond: { wins: 0, places: 0, totalBets: 0, profit: 0 },
                    winHighestOdds: { wins: 0, places: 0, totalBets: 0, profit: 0 }
                }
            },
            patterns: {
                bestTracks: {},
                bestDistances: {},
                bestConditions: {},
                bestPredictionFactors: {
                    stabilityScore: 0,
                    probabilityScore: 0,
                    speedRating: 0,
                    classLevel: 0
                }
            },
            timeBasedAnalysis: {
                morningAccuracy: 0,
                afternoonAccuracy: 0,
                eveningAccuracy: 0,
                weekdayAccuracy: 0,
                weekendAccuracy: 0
            },
            predictionsByPosition: {},
            exoticBets: {
                quinella: { hits: 0, attempts: 0, totalReturns: 0, avgDividend: 0 },
                exacta: { hits: 0, attempts: 0, totalReturns: 0, avgDividend: 0 },
                blended: { hits: 0, attempts: 0, totalReturns: 0, avgDividend: 0 }
            },
            oddsAnalysis: {
                averageWinningOdds: 0,
                averagePlacingOdds: 0,
                winsByOddsRange: {},
                averageOddsWhenCorrect: 0,
                averageOddsWhenIncorrect: 0,
                quinellaAverageOdds: 0
            }
        };

        // Initialize betting tracking
        let totalBetsPlaced = 0;
        let totalWinningBets = 0;
        let totalInvestment = 0;
        let totalReturns = 0;

        // Process each race
        Object.values(racePredictions).forEach(races => {
            races.forEach(race => {
                if (!race.predictions || !race.results) return;

                const sortedPredictions = race.predictions.sort((a, b) => b.stabilityScore - a.stabilityScore);
                const topPicks = sortedPredictions.slice(0, 3);

                const actualTop2 = race.results.slice(0, 2);
                const predictedTop2 = topPicks.slice(0, 2).map(p => p.runnerNumber);

                // Check Exacta (1-2 in exact order)
                if (predictedTop2[0] === actualTop2[0] && predictedTop2[1] === actualTop2[1]) {
                    analysis.exoticBets.exacta.hits++;
                    analysis.exoticBets.exacta.totalReturns += race.exactaDividend || 0;
                }
                analysis.exoticBets.exacta.attempts++;

                // Check Quinella (1-2 in any order)
                if (actualTop2.includes(predictedTop2[0]) && actualTop2.includes(predictedTop2[1])) {
                    analysis.exoticBets.quinella.hits++;
                    analysis.exoticBets.quinella.totalReturns += race.quinellaDividend || 0;
                }
                analysis.exoticBets.quinella.attempts++;

                // Check Blended (at least one correct in top 2)
                if (actualTop2.some(num => predictedTop2.includes(num))) {
                    analysis.exoticBets.blended.hits++;
                    const correctPick = topPicks.find(p => actualTop2.includes(p.runnerNumber));
                    if (correctPick) {
                        analysis.exoticBets.blended.totalReturns += correctPick.placeOdds;
                    }
                }
                analysis.exoticBets.blended.attempts++;

                // Analyze odds
                sortedPredictions.slice(0, 3).forEach((prediction) => {
                    if (prediction.runnerNumber === race.results?.[0]) {
                        oddsCollector.winningOdds.push(prediction.currentOdds);
                        oddsCollector.correctPredictionOdds.push(prediction.currentOdds);
                    } else {
                        oddsCollector.incorrectPredictionOdds.push(prediction.currentOdds);
                    }

                    if (race.results?.slice(0, 3).includes(prediction.runnerNumber)) {
                        oddsCollector.placingOdds.push(prediction.currentOdds);
                    }
                });

                // Check quinella (first two in any order)
                const predictedTop2ForQuinella = sortedPredictions.slice(0, 2).map(p => p.runnerNumber);
                if (predictedTop2ForQuinella.every(num => race.results?.slice(0, 2).includes(num))) {
                    const averageOdds = (sortedPredictions[0].currentOdds + sortedPredictions[1].currentOdds) / 2;
                    oddsCollector.quinellaOdds.push(averageOdds);
                }
            });
        });

        // Calculate averages
        const calculateAverage = (arr: number[]) => arr.length > 0 ? arr.reduce((a, b) => a + b, 0) / arr.length : 0;

        // Update overall betting performance
        analysis.bettingPerformance.totalBets = totalBetsPlaced;
        analysis.bettingPerformance.winningBets = totalWinningBets;
        analysis.bettingPerformance.totalStake = totalInvestment;
        analysis.bettingPerformance.totalReturns = totalReturns;
        analysis.bettingPerformance.profitLoss = totalReturns - totalInvestment;
        analysis.bettingPerformance.roi = ((totalReturns - totalInvestment) / totalInvestment) * 100;

        // Calculate averages
        analysis.exoticBets.exacta.avgDividend =
            analysis.exoticBets.exacta.hits > 0 ?
                analysis.exoticBets.exacta.totalReturns / analysis.exoticBets.exacta.hits : 0;

        analysis.exoticBets.quinella.avgDividend =
            analysis.exoticBets.quinella.hits > 0 ?
                analysis.exoticBets.quinella.totalReturns / analysis.exoticBets.quinella.hits : 0;

        analysis.exoticBets.blended.avgDividend =
            analysis.exoticBets.blended.hits > 0 ?
                analysis.exoticBets.blended.totalReturns / analysis.exoticBets.blended.hits : 0;

        analysis.oddsAnalysis = {
            averageWinningOdds: calculateAverage(oddsCollector.winningOdds),
            averagePlacingOdds: calculateAverage(oddsCollector.placingOdds),
            averageOddsWhenCorrect: calculateAverage(oddsCollector.correctPredictionOdds),
            averageOddsWhenIncorrect: calculateAverage(oddsCollector.incorrectPredictionOdds),
            quinellaAverageOdds: calculateAverage(oddsCollector.quinellaOdds),
            winsByOddsRange: oddsCollector.winningOdds.reduce((acc: { [range: string]: number }, odds) => {
                const range = getOddsRange(odds);
                acc[range] = (acc[range] || 0) + 1;
                return acc;
            }, {})
        };

        return analysis;
    };

    // Update the renderPredictionStats function
    const renderPredictionStats = () => {
        const analysis = calculateComprehensiveAnalysis(racePredictions);

        return (
            <AnalysisContainer>
                <AnalysisHeader>
                    <h2>Prediction Performance Analysis</h2>
                </AnalysisHeader>

                <AnalysisSection>
                    <h3>Overall Performance</h3>
                    <MetricGrid>
                        <MetricCard performance={analysis.overall.accuracy > 30 ? 'positive' : 'negative'}>
                            <div className="label">Overall Accuracy</div>
                            <div className="value">{analysis.overall.accuracy.toFixed(1)}%</div>
                        </MetricCard>
                        <MetricCard>
                            <div className="label">Total Races Analyzed</div>
                            <div className="value">{analysis.overall.totalRaces}</div>
                        </MetricCard>
                        <MetricCard>
                            <div className="label">Average Runners</div>
                            <div className="value">{analysis.overall.averageRunnersPerRace.toFixed(1)}</div>
                        </MetricCard>
                    </MetricGrid>
                </AnalysisSection>

                <AnalysisSection>
                    <h3>Position Accuracy</h3>
                    <ChartContainer>
                        {/* Add position accuracy chart here */}
                    </ChartContainer>
                </AnalysisSection>

                <AnalysisSection>
                    <h3>Betting Performance</h3>
                    <MetricGrid>
                        <MetricCard performance={analysis.bettingPerformance.roi > 0 ? 'positive' : 'negative'}>
                            <div className="label">Return on Investment</div>
                            <div className="value">{analysis.bettingPerformance.roi.toFixed(1)}%</div>
                        </MetricCard>
                        <MetricCard>
                            <div className="label">Win Strike Rate</div>
                            <div className="value">
                                {((analysis.bettingPerformance.winningBets / analysis.bettingPerformance.totalBets) * 100).toFixed(1)}%
                            </div>
                        </MetricCard>
                    </MetricGrid>
                </AnalysisSection>

                <AnalysisSection>
                    <h3>Best Performing Patterns</h3>
                    <MetricGrid>
                        {Object.entries(analysis.patterns.bestTracks)
                            .sort(([, a], [, b]) => b - a)
                            .slice(0, 3)
                            .map(([track, count]) => (
                                <MetricCard key={track}>
                                    <div className="label">Track</div>
                                    <div className="value">{track}</div>
                                    <div className="trend">{count} successful predictions</div>
                                </MetricCard>
                            ))
                        }
                    </MetricGrid>
                </AnalysisSection>

                <AccuracyChart>
                    <h3>Exotic Bet Performance</h3>
                    <DetailedTable>
                        <thead>
                            <tr>
                                <th>Bet Type</th>
                                <th>Strike Rate</th>
                                <th>Avg Dividend</th>
                                <th>ROI</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Exacta (1-2)</td>
                                <td>
                                    {((analysis.exoticBets.exacta.hits / analysis.exoticBets.exacta.attempts) * 100).toFixed(1)}%
                                    <small style={{ color: '#666', marginLeft: '8px' }}>
                                        ({analysis.exoticBets.exacta.hits}/{analysis.exoticBets.exacta.attempts})
                                    </small>
                                </td>
                                <td>${analysis.exoticBets.exacta.avgDividend.toFixed(2)}</td>
                                <td>
                                    {((analysis.exoticBets.exacta.totalReturns / analysis.exoticBets.exacta.attempts - 1) * 100).toFixed(1)}%
                                </td>
                            </tr>
                            <tr>
                                <td>Quinella</td>
                                <td>
                                    {((analysis.exoticBets.quinella.hits / analysis.exoticBets.quinella.attempts) * 100).toFixed(1)}%
                                    <small style={{ color: '#666', marginLeft: '8px' }}>
                                        ({analysis.exoticBets.quinella.hits}/{analysis.exoticBets.quinella.attempts})
                                    </small>
                                </td>
                                <td>${analysis.exoticBets.quinella.avgDividend.toFixed(2)}</td>
                                <td>
                                    {((analysis.exoticBets.quinella.totalReturns / analysis.exoticBets.quinella.attempts - 1) * 100).toFixed(1)}%
                                </td>
                            </tr>
                            <tr>
                                <td>Blended</td>
                                <td>
                                    {((analysis.exoticBets.blended.hits / analysis.exoticBets.blended.attempts) * 100).toFixed(1)}%
                                    <small style={{ color: '#666', marginLeft: '8px' }}>
                                        ({analysis.exoticBets.blended.hits}/{analysis.exoticBets.blended.attempts})
                                    </small>
                                </td>
                                <td>${analysis.exoticBets.blended.avgDividend.toFixed(2)}</td>
                                <td>
                                    {((analysis.exoticBets.blended.totalReturns / analysis.exoticBets.blended.attempts - 1) * 100).toFixed(1)}%
                                </td>
                            </tr>
                        </tbody>
                    </DetailedTable>
                </AccuracyChart>

                <OddsAnalysisCard>
                    <h3>Odds Analysis</h3>
                    <div className="odds-grid">
                        <div className="odds-stat">
                            <div className="label">Average Winning Odds</div>
                            <div className="value">${analysis.oddsAnalysis.averageWinningOdds.toFixed(2)}</div>
                        </div>
                        <div className="odds-stat">
                            <div className="label">Average Placing Odds</div>
                            <div className="value">${analysis.oddsAnalysis.averagePlacingOdds.toFixed(2)}</div>
                        </div>
                        <div className="odds-stat">
                            <div className="label">Quinella Average Odds</div>
                            <div className="value">${analysis.oddsAnalysis.quinellaAverageOdds.toFixed(2)}</div>
                        </div>
                        <div className="odds-stat">
                            <div className="label">Avg Odds (Correct Picks)</div>
                            <div className="value">${analysis.oddsAnalysis.averageOddsWhenCorrect.toFixed(2)}</div>
                        </div>
                    </div>

                    <DetailedTable>
                        <thead>
                            <tr>
                                <th>Odds Range</th>
                                <th>Number of Wins</th>
                                <th>Win Strike Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(analysis.oddsAnalysis.winsByOddsRange).map(([range, count]) => (
                                <tr key={range}>
                                    <td>{range}</td>
                                    <td>{count}</td>
                                    <td>
                                        {((count / Object.values(analysis.oddsAnalysis.winsByOddsRange)
                                            .reduce((a, b) => a + b, 0)) * 100).toFixed(1)}%
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </DetailedTable>
                </OddsAnalysisCard>
            </AnalysisContainer>
        );
    };

    // Add these styled components
    const AnalyticsDashboard = styled.div`
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
    `;

    const SummaryCards = styled.div`
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 16px;
    `;

    const AccuracyChart = styled.div`
        background: white;
        border-radius: 12px;
        padding: 20px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    `;

    const DetailedTable = styled.table`
        width: 100%;
        border-collapse: collapse;
        background: white;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(0,0,0.05);

        th, td {
            padding: 12px 16px;
            text-align: left;
            border-bottom: 1px solid #eee;
        }

        th {
            background: #f8f9fa;
            font-weight: 500;
            color: #2c1f56;
        }

        tbody tr:hover {
            background: #f8f9fa;
        }
    `;

    // Add this function to calculate the average odds for winning predictions
    const calculateAverageWinningOdds = (racePredictions: GroupedRacePredictions) => {
        let totalOdds =0;
        let winCount =0;

        Object.values(racePredictions).forEach(races => {
            races.forEach(race => {
                if (race.predictions && race.results) {
                    const winner = race.results[0];
                    const winningPrediction = race.predictions.find(p => p.runnerNumber === winner);

                    if (winningPrediction) {
                        totalOdds += winningPrediction.currentOdds;
                        winCount++;
                    }
                }
            });
        });

        return winCount > 0 ? totalOdds / winCount : 0;
    };

    // Add this function to render the average odds chart
    const AverageOddsChart = () => {
        const averageWinningOdds = calculateAverageWinningOdds(racePredictions);

        const data = [
            { name: 'Average Winning Odds', value: averageWinningOdds },
        ];

        return (
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        );
    };

    // Update the StepContent for step 4 to include the new chart
    <StepContent isActive={step === 4}>
        <AnalyticsDashboard>
            <h2>Prediction Analysis & Results</h2>

            <SummaryCards>
                <MetricCard
                    performance={predictionAccuracy.exactPredictions / predictionAccuracy.totalPredictions >0.3 ? 'positive' : 'negative'}
                >
                    <div className="label">Exact Position Accuracy</div>
                    <div className="value">
                        {((predictionAccuracy.exactPredictions / predictionAccuracy.totalPredictions) *100).toFixed(1)}%
                    </div>
                    <div className="trend">
                        {predictionAccuracy.exactPredictions} correct from {predictionAccuracy.totalPredictions} predictions
                    </div>
                </MetricCard>

                <MetricCard
                    performance={predictionAccuracy.within3Positions / predictionAccuracy.totalPredictions >0.5 ? 'positive' : 'negative'}
                >
                    <div className="label">Within 3 Positions</div>
                    <div className="value">
                        {((predictionAccuracy.within3Positions / predictionAccuracy.totalPredictions) *100).toFixed(1)}%
                    </div>
                </MetricCard>

                <MetricCard>
                    <div className="label">Average Position Difference</div>
                    <div className="value">{predictionAccuracy.averagePositionDiff.toFixed(1)}</div>
                </MetricCard>
            </SummaryCards>

            <AccuracyChart>
                <h3>Race-by-Race Results</h3>
                <DetailedTable>
                    <thead>
                        <tr>
                            <th>Race</th>
                            <th>Accuracy</th>
                            <th>Predicted Top 3</th>
                            <th>Actual Top 3</th>
                        </tr>
                    </thead>
                    <tbody>
                        {predictionAccuracy.raceResults.map((result, index) => (
                            <tr key={index}>
                                <td>{result.raceName}</td>
                                <td>{(result.accuracy * 100).toFixed(1)}%</td>
                                <td>{result.predictedOrder.slice(0, 3).join('-')}</td>
                                <td>{result.actualOrder.slice(0, 3).join('-')}</td>
                            </tr>
                        ))}
                    </tbody>
                </DetailedTable>
            </AccuracyChart>

            <AnalysisSection>
                <h3>Average Winning Odds</h3>
                <AverageOddsChart />
            </AnalysisSection>
        </AnalyticsDashboard>
    </StepContent>

    const handleOpenTutorial = () => {
        setTutorialVisible(true);
    };

    // Add near the top with other styled components
    const OddsAnalysisCard = styled.div`
      background: white;
      border-radius: 12px;
      padding: 20px;
      margin: 20px 0;
      box-shadow: 0 2px 8px rgba(0,0.05);

      h3 {
        margin-bottom: 16px;
        color: #2c1f56;
      }

      .odds-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 16px;
        margin-bottom: 20px;
      }

      .odds-stat {
        background: #f8f9fa;
        padding: 16px;
        border-radius: 8px;

        .label {
          font-size: 12px;
          color: #666;
          margin-bottom: 4px;
        }

        .value {
          font-size: 20px;
          font-weight: 600;
          color: #2c1f56;
        }
      }
    `;

    // Add near other helper functions
    const getOddsRange = (odds: number): string => {
        if (odds <= 2) return '≤$2';
        if (odds <= 5) return '$2.01-$5';
        if (odds <= 10) return '$5.01-$10';
        if (odds <= 20) return '$10.01-$20';
        return '>$20';
    };

    // Create a separate collector object outside the analysis object
    const oddsCollector = {
        winningOdds: [] as number[],
        placingOdds: [] as number[],
        correctPredictionOdds: [] as number[],
        incorrectPredictionOdds: [] as number[],
        quinellaOdds: [] as number[]
    };

    // Add this function to process the odds data
    const processOddsWinsData = (racePredictions: GroupedRacePredictions) => {
        const oddsWinsData: { [key: string]: { totalWins: number; totalRaces: number } } = {};

        Object.values(racePredictions).forEach(races => {
            races.forEach(race => {
                if (race.predictions && race.results) {
                    const winner = race.results[0];
                    const winningPrediction = race.predictions.find(p => p.runnerNumber === winner);

                    if (winningPrediction) {
                        const oddsRange = getOddsRange(winningPrediction.currentOdds);

                        if (!oddsWinsData[oddsRange]) {
                            oddsWinsData[oddsRange] = { totalWins: 0, totalRaces: 0 };
                        }

                        oddsWinsData[oddsRange].totalWins++;
                        oddsWinsData[oddsRange].totalRaces++;
                    }
                }
            });
        });

        return Object.entries(oddsWinsData).map(([range, data]) => ({
            oddsRange: range,
            winRate: (data.totalWins / data.totalRaces) * 100,
            totalWins: data.totalWins
        })).sort((a, b) => parseFloat(a.oddsRange.replace(/[^\d.]/g, '')) - parseFloat(b.oddsRange.replace(/[^\d.]/g, '')));
    };

    // Add this to your component's render method or in the analysis section
    const OddsWinsChart = () => {
        const oddsWinsData = processOddsWinsData(racePredictions);

        return (
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={oddsWinsData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="oddsRange" />
                    <YAxis label={{ value: 'Win Rate (%)', angle: -90, position: 'insideLeft' }} />
                    <Tooltip
                        formatter={(value: number, name, props) => [
                            `${value.toFixed(1)}%`,
                            `Win Rate for ${props.payload.oddsRange}`,
                            `Total Wins: ${props.payload.totalWins}`
                        ]}
                    />
                    <Bar dataKey="winRate" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        );
    };

    // Add this to your analysis section, for example in renderPredictionStats
    <AnalysisSection>
        <h3>Wins by Odds Range</h3>
        <OddsWinsChart />
    </AnalysisSection>

    const handleFormClick = (runnerNumber: number) => {
        setOpenFormRunner(openFormRunner === runnerNumber ? null : runnerNumber);
    };

    const ValueIcon = styled.div`
      position: absolute;
      top: -8px;
      right: -8px;
      background: linear-gradient(135deg, #ffd700 0%, #ffa000 100%);
      border-radius: 50%;
      padding: 8px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      svg {
        color: white;
        font-size: 14px;
        filter: drop-shadow(0 1px 1px rgba(0,0,0,0.2));
      }

      &:hover::after {
        content: 'Value Pick';
        position: absolute;
        top: 100%;
        right: 0;
        background: #333;
        color: white;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        margin-top: 4px;
        white-space: nowrap;
      }
    `;

    // Add new state for scroll detection
    const [isScrolling, setIsScrolling] = useState(false);
    let scrollTimeout: NodeJS.Timeout;

    // Add this useEffect to handle scroll detection
    useEffect(() => {
      const handleScroll = () => {
        setIsScrolling(true);
        clearTimeout(scrollTimeout);

        scrollTimeout = setTimeout(() => {
          setIsScrolling(false);
        }, 1500); // Hide 1.5 seconds after scrolling stops
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
        clearTimeout(scrollTimeout);
      };
    }, []);

    return (
        <Container>
            <StepIndicator>
                {STEPS.map((stepInfo, index) => (
                    <React.Fragment key={index}>
                        <StepItem
                            active={step === index + 1}
                            clickable={step > index + 1}
                            onClick={() => step > index + 1 && setStep(index + 1)}
                        >
                            <div className="step-number">{index + 1}</div>
                            <div className="step-label">{stepInfo.label}</div>
                        </StepItem>
                        {index < STEPS.length - 1 && <StepDivider />}
                    </React.Fragment>
                ))}
            </StepIndicator>


            <RacingTutorial isVisible={isTutorialVisible} onClose={() => setTutorialVisible(false)} />


            <StepDescription>
                {STEPS[step - 1].description}
                <TutorialLink onClick={handleOpenTutorial}>
                    <FaQuestionCircle />
                </TutorialLink>
            </StepDescription>



            <ContentWrapper>
                {/* Step Contents */}
                {/* Step 1: Select Date */}
                <StepContent isActive={step === 1}>
                    <FilterSection>
                        <FilterGroup>
                            <FilterLabel>Jurisdiction</FilterLabel>
                            <FilterSelect value={jurisdiction} onChange={(e) => setJurisdiction(e.target.value)}>
                                <option value="VIC">Victoria</option>
                                <option value="NSW">New South Wales</option>
                                <option value="QLD">Queensland</option>
                                <option value="SA">South Australia</option>
                                <option value="WA">Western Australia</option>
                                <option value="TAS">Tasmania</option>
                                <option value="NT">Northern Territory</option>
                                <option value="ACT">ACT</option>
                            </FilterSelect>
                        </FilterGroup>

                        <FilterGroup>
                            <FilterLabel>Race Type</FilterLabel>
                            <RaceTypeGroup>
                                <RaceTypeButton active={raceType === 'R'} onClick={() => setRaceType('R')}>
                                    R
                                </RaceTypeButton>
                                <RaceTypeButton active={raceType === 'H'} onClick={() => setRaceType('H')}>
                                    H
                                </RaceTypeButton>
                                <RaceTypeButton active={raceType === 'G'} onClick={() => setRaceType('G')}>
                                    G
                                </RaceTypeButton>
                            </RaceTypeGroup>
                        </FilterGroup>
                    </FilterSection>

                    <DateGrid>
                        {/* Add yesterday's date first */}
                        <DateCard
                            key="yesterday"
                            selected={selectedDate === getYesterdayDate().meetingDate}
                            onClick={() => {
                                setSelectedDate(getYesterdayDate().meetingDate);
                            }}
                        >
                            <FaCalendarAlt />
                            <div>{format(subDays(new Date(), 1), 'MMM dd, yyyy')}</div>
                        </DateCard>

                        {/* Render the rest of the meeting dates */}
                        {meetingDates.map((date) => (
                            <DateCard
                                key={date.meetingDate}
                                selected={selectedDate === date.meetingDate}
                                onClick={() => {
                                    setSelectedDate(date.meetingDate);
                                }}
                            >
                                <FaCalendarAlt />
                                <div>{format(new Date(date.meetingDate), 'MMM dd, yyyy')}</div>
                            </DateCard>
                        ))}
                    </DateGrid>
                </StepContent>

                {/* Step 2: Select Meetings */}
                <StepContent isActive={step === 2}>
                    {isLoadingMeetings ? (
                        <LoadingContainer>
                            <LoadingSpinner />
                            <h3>Loading Available Meetings...</h3>
                            <p>Please wait while we fetch the racing schedule</p>
                        </LoadingContainer>
                    ) : (
                        <>
                            {renderMeetings()}
                            {hasMore ? (
                                <LoadMoreContainer ref={observerTarget}>
                                    <p className="stats">
                                        Showing {displayedMeetings.length} of {meetings.length} meetings
                                    </p>
                                    <LoadMoreButton onClick={loadMore} disabled={loading}>
                                        {loading ? (
                                            <>
                                                <span className="spinner" />
                                                Loading...
                                            </>
                                        ) : (
                                            'Load More Meetings'
                                        )}
                                    </LoadMoreButton>
                                </LoadMoreContainer>
                            ) : showCompletion && (
                                <LoadMoreContainer>
                                    <p className="stats">All {meetings.length} meetings loaded</p>
                                </LoadMoreContainer>
                            )}
                        </>
                    )}
                </StepContent>

                {/* Step 3: View Results */}
                <StepContent isActive={step === 3}>
                    <TabListContainer>
                        <ScrollButton
                            direction="left"
                            onClick={() => cycleMeetings('prev')}
                            style={{ left: '4px' }}
                        >
                            <FaChevronLeft />
                        </ScrollButton>

                        <HorizontalTabList className="horizontal-tab-list">
                            {Object.keys(racePredictions).map((meetingName) => (
                                <HorizontalTab
                                    key={meetingName}
                                    data-meeting={meetingName}
                                    active={activeMeetingTab === meetingName}
                                    onClick={() => handleTabChange(meetingName)}
                                >
                                    <span>{meetingName}</span>
                                </HorizontalTab>
                            ))}
                        </HorizontalTabList>

                        <ScrollButton
                            direction="right"
                            onClick={() => cycleMeetings('next')}
                            style={{ right: '4px' }}
                        >
                            <FaChevronRight />
                        </ScrollButton>
                    </TabListContainer>

                    {/* Add this new section */}
                    <RaceNavigation className={isScrolling ? 'scrolling' : ''}>
                        {racePredictions[activeMeetingTab]?.map((race: RacePrediction) => (
                            <RaceNumberButton
                                key={race.raceNumber}
                                isActive={activeRaceNumber === race.raceNumber}
                                onClick={() => scrollToRace(race.raceNumber)}
                            >
                                {race.raceNumber}
                            </RaceNumberButton>
                        ))}
                    </RaceNavigation>

                    <TabContent>
                        <FilterBar>
                            {activeFilters.map((filter) => (
                                <FilterChip key={filter.id} active={filter.active} onClick={() => toggleFilter(filter.id)}>
                                    {filter.label}
                                </FilterChip>
                            ))}
                        </FilterBar>

                        {renderRaceResults()}
                    </TabContent>
                </StepContent>

                {/* Step 4: Review Predictions */}
                <StepContent isActive={step === 4}>
                    <RacingAnalyticsDashboard
                        predictionStats={{
                            totalPredictions: stats.totalPredictions,
                            correctPredictions: stats.correctWinners,
                            winStrikeRate: stats.winStrikeRate,
                            placeStrikeRate: stats.placeStrikeRate,
                            profitLoss: 0
                        }}
                        historicalData={Object.entries(racePredictions).map(([meetingName, races]) => {
                            const meetingStats = races.reduce((acc, race) => {
                                if (race.predictions && race.results) {
                                    const exactOrder = race.predictions.findIndex(p => p.runnerNumber === race?.results?.[0]) === 0;
                                    const anyOrder = race.predictions.slice(0, 4).some(p => race?.results?.slice(0, 4).includes(p.runnerNumber));
                                    acc.exactOrderCount += exactOrder ? 1 : 0;
                                    acc.anyOrderCount += anyOrder ? 1 : 0;
                                    acc.totalRaces += 1;
                                }
                                return acc;
                            }, { exactOrderCount: 0, anyOrderCount: 0, totalRaces: 0 });

                            return {
                                date: meetingName,
                                exactOrderAccuracy: (meetingStats.exactOrderCount / meetingStats.totalRaces) *100 || 0,
                                anyOrderAccuracy: (meetingStats.anyOrderCount / meetingStats.totalRaces) * 100 || 0,
                                proMoneyFlow: 0,
                                marketConfidence: 0,
                                formRating: races.reduce((acc, race) =>
                                    acc + (race.predictions?.[0]?.competitionScore || 0), 0) / races.length || 0,
                                speedRating: races.reduce((acc, race) =>
                                    acc + (race.predictions?.[0]?.speedRating || 0), 0) / races.length || 0,
                                classRating: races.reduce((acc, race) =>
                                    acc + (race.predictions?.[0]?.classLevelFactor || 0), 0) / races.length || 0
                            };
                        })}
                        bettingTrends={[
                            { name: 'Correct Predictions', value: stats.correctWinners },
                            { name: 'Other Results', value: stats.totalPredictions - stats.correctWinners }
                        ]}
                        accuracyStats={{
                            exactOrder: {
                                first: stats.winStrikeRate,
                                firstTwo: Object.values(racePredictions).reduce((acc, races) => {
                                    const exactaCount = races.reduce((rAcc, race) => {
                                        if (race.predictions && race.results) {
                                            const predictedTop2 = race.predictions.slice(0, 2).map(p => p.runnerNumber);
                                            const actualTop2 = race.results.slice(0, 2);
                                            return rAcc + (predictedTop2[0] === actualTop2[0] && predictedTop2[1] === actualTop2[1] ? 1 : 0);
                                        }
                                        return rAcc;
                                    }, 0);
                                    return acc + exactaCount;
                                }, 0) / stats.totalPredictions * 100,
                                firstThree: Object.values(racePredictions).reduce((acc, races) => {
                                    const trifectaCount = races.reduce((rAcc, race) => {
                                        if (race.predictions && race.results) {
                                            const predictedTop3 = race.predictions.slice(0, 3).map(p => p.runnerNumber);
                                            const actualTop3 = race.results.slice(0, 3);
                                            return rAcc + (predictedTop3.every((num, i) => num === actualTop3[i]) ? 1 : 0);
                                        }
                                        return rAcc;
                                    }, 0);
                                    return acc + trifectaCount;
                                }, 0) / stats.totalPredictions * 100,
                                allFour: Object.values(racePredictions).reduce((acc, races) => {
                                    const superfectaCount = races.reduce((rAcc, race) => {
                                        if (race.predictions && race.results) {
                                            const predictedTop4 = race.predictions.slice(0, 4).map(p => p.runnerNumber);
                                            const actualTop4 = race.results.slice(0, 4);
                                            return rAcc + (predictedTop4.every((num, i) => num === actualTop4[i]) ? 1 : 0);
                                        }
                                        return rAcc;
                                    }, 0);
                                    return acc + superfectaCount;
                                }, 0) / stats.totalPredictions * 100
                            },
                            anyOrder: {
                                first: stats.winStrikeRate,
                                firstTwo: Object.values(racePredictions).reduce((acc, races) => {
                                    const anyTop2Count = races.reduce((rAcc, race) => {
                                        if (race.predictions && race.results) {
                                            const predictedTop2 = race.predictions.slice(0, 2).map(p => p.runnerNumber);
                                            const actualTop2 = race.results.slice(0, 2);
                                            return rAcc + (predictedTop2.every(num => actualTop2.includes(num)) ? 1 : 0);
                                        }
                                        return rAcc;
                                    }, 0);
                                    return acc + anyTop2Count;
                                }, 0) / stats.totalPredictions * 100,
                                firstThree: Object.values(racePredictions).reduce((acc, races) => {
                                    const anyTop3Count = races.reduce((rAcc, race) => {
                                        if (race.predictions && race.results) {
                                            const predictedTop3 = race.predictions.slice(0, 3).map(p => p.runnerNumber);
                                            const actualTop3 = race.results.slice(0, 3);
                                            return rAcc + (predictedTop3.every(num => actualTop3.includes(num)) ? 1 : 0);
                                        }
                                        return rAcc;
                                    }, 0);
                                    return acc + anyTop3Count;
                                }, 0) / stats.totalPredictions * 100,
                                allFour: Object.values(racePredictions).reduce((acc, races) => {
                                    const anyTop4Count = races.reduce((rAcc, race) => {
                                        if (race.predictions && race.results) {
                                            const predictedTop4 = race.predictions.slice(0, 4).map(p => p.runnerNumber);
                                            const actualTop4 = race.results.slice(0, 4);
                                            return rAcc + (predictedTop4.every(num => actualTop4.includes(num)) ? 1 : 0);
                                        }
                                        return rAcc;
                                    }, 0);
                                    return acc + anyTop4Count;
                                }, 0) / stats.totalPredictions * 100
                            },
                            totalRaces: stats.totalPredictions
                        }}
                        bettingAnalysis={{
                            startingBalance: 0,
                            dailyBets: 0,
                            daysAnalyzed: 0,
                            betsPerStrategy: {
                                eachWayTop: {
                                    wins: 0,
                                    places: 0,
                                    totalBets: 0,
                                    profit: 0
                                },
                                placeSecond: {
                                    places: 0,
                                    totalBets: 0,
                                    profit: 0
                                },
                                winHighestOdds: {
                                    wins: 0,
                                    totalBets: 0,
                                    profit: 0
                                }
                            },
                            totalInvestment: 0,
                            totalReturns: 0,
                            netProfit: 0,
                            roi: 0,
                            projectedYearlyProfit: 0,
                            betStrings: [],
                            totalBets: 0  // Add this line to include the required totalBets property
                        }}
                    />
                </StepContent>
            </ContentWrapper>

            {!processingRaces && (
                <NavigationBar>
                    <Button onClick={() => step > 1 && setStep(step - 1)} disabled={step <= 1}>
                        <FaChevronLeft />
                    </Button>

                    <NavigationSteps>
                        {STEPS.map((_, index) => (
                            <div
                                key={index}
                                style={{
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    background: step === index + 1 ? '#40B549' : '#ddd',
                                    margin: '0 4px',
                                }}
                            />
                        ))}
                    </NavigationSteps>

                    <Button
                        primary
                        onClick={handleNext}
                        disabled={isNextDisabled() || step >= 4}
                    >
                        <FaChevronRight />
                    </Button>
                </NavigationBar>
            )}


            {processingRaces && (
                <LoadingOverlay>

                    <ProcessingInfo>
                        Calculating advanced predictions for your selected race meetings
                    </ProcessingInfo>
                    <LoadingProgressBar>
                        <LoadingProgressFill progress={progress} />
                    </LoadingProgressBar>
                    <ProcessingStats>
                        <span>Processed: {processedRaces}/{totalRaces} races</span>
                        <span>Progress: {Math.round(progress)}%</span>
                    </ProcessingStats>
                    {currentRace && (
                        <CurrentProcessInfo>
                            Currently processing: {currentRace.meetingName} - Race {currentRace.raceNumber}
                        </CurrentProcessInfo>
                    )}
                    <CancelButton
                        onClick={handleCancel}
                        disabled={isCancelling}
                    >
                        {isCancelling ? 'Cancelling...' : 'Cancel Processing'}
                    </CancelButton>
                </LoadingOverlay>
            )}
        </Container>
    );
};

export default MeetingDateSelector;
