import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { AccountModel, CreateAccountModel, UpdateAccountModel, TokenResponseModel, BetPlacementRequest, Meeting } from './apiTypes';
import { BASE_URL } from '../config';
import { RacePrediction } from '../Components/Racing/types';

interface NextToGoRace {
    raceStartTime: string;
    raceNumber: number;
    raceName: string;
    meeting: {
        meetingName: string;
        location: string;
        raceType: string;
        weatherCondition: string;
        trackCondition: string;
        meetingDate: string;
        venueMnemonic: string;
    };
    details?: any;
    _links?: {
        races?: string;
    };
    predictions?: {
        stabilityScore?: number;
        performanceIndex?: number;
        riskAssessment?: number;
        trackConditionImpact?: number;
        competitiveEdge?: number;
        recoveryRate?: number;
        seasonalAdjustment?: number;
        geneticPotential?: number;
    };
}

class ApiService {


    private axiosInstance: AxiosInstance;
    private calculatedMeetings: Map<string, any> = new Map();
    private eventEmitter = new EventTarget();

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: BASE_URL,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        this.axiosInstance.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                const token = localStorage.getItem('accessToken');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );
    }

    // Account Management Endpoints

    async getAccount(id: string): Promise<AccountModel> {
        const response = await this.axiosInstance.get(`/accounts/${id}`);
        return response.data;
    }

    async listAccounts(pageNumber = 1, pageSize = 10): Promise<AccountModel[]> {
        const response = await this.axiosInstance.get('/accounts/accounts/list', {
            params: { pageNumber, pageSize }
        });
        return response.data;
    }

    async createAccount(data: CreateAccountModel): Promise<AccountModel> {
        const response = await this.axiosInstance.post('/accounts/accounts/create', data);
        return response.data;
    }

    async updateAccount(id: string, data: UpdateAccountModel): Promise<AccountModel> {
        const response = await this.axiosInstance.patch(`/accounts/accounts/${id}/update`, data);
        return response.data;
    }

    async deleteAccount(id: string): Promise<void> {
        await this.axiosInstance.delete(`/accounts/accounts/${id}/delete`);
    }

    // Provider Management Endpoints

    async listProviders(
        accountId: string,
        search = '',
        pageNumber = 1,
        pageSize = 10
    ): Promise<any[]> {
        const response = await this.axiosInstance.get(`/accounts/${accountId}/providers`, {
            params: { search, pageNumber, pageSize },
        });
        return response.data;
    }

    async getProvider(accountId: string, providerId: string): Promise<any> {
        const response = await this.axiosInstance.get(`/accounts/${accountId}/providers/${providerId}`);
        return response.data;
    }

    // Authentication Endpoints

    async login(data: { username: string; password: string }): Promise<TokenResponseModel> {
        const response = await this.axiosInstance.post('/accounts/login', data);
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return response.data;
    }

    async refreshToken(): Promise<TokenResponseModel> {
        const response = await this.axiosInstance.post('/accounts/refresh-token');
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return response.data;
    }

    async logout(): Promise<void> {
        await this.axiosInstance.post('/accounts/logout');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }

    async addRace(race: RacePrediction) {
        const response = await this.axiosInstance.post('/labs/punting/race-horsing/add', race);
        return response.data;
    }

    // Meeting Management Endpoints

    async fetchMeetings(
        date: string,
        jurisdiction: string = 'VIC',
        raceType: string,
        pageNumber: number = 1,
        pageSize: number = 25
    ): Promise<Meeting[]> {
        const response = await this.axiosInstance.get(`/api/meetings/${date}/${raceType}`, {
            params: { jurisdiction, pageNumber, pageSize }
        });
        return response.data;
    }

    async fetchMeetingsByType(
        date: string,
        type: string,
        jurisdiction: string = 'VIC',
        pageNumber: number = 1,
        pageSize: number = 25
    ): Promise<Meeting[]> {
        const response = await this.axiosInstance.get(`/api/meetings/${date}/type/${type}`, {
            params: { jurisdiction, pageNumber, pageSize }
        });
        return response.data;
    }

    async getMeetingData(id: string): Promise<any> {
        const response = await this.axiosInstance.get(`/data/${id}`);
        return response.data;
    }

    async createMeeting(data: any): Promise<any> {
        const response = await this.axiosInstance.post('/data', data);
        return response.data;
    }

    async updateMeeting(id: string, data: any): Promise<any> {
        const response = await this.axiosInstance.put(`/data/${id}`, data);
        return response.data;
    }

    async deleteMeeting(id: string): Promise<void> {
        await this.axiosInstance.delete(`/data/${id}`);
    }

    // Predictions Endpoint

    async generateRacehorsePredictions(formData: FormData): Promise<any> {
        const response = await this.axiosInstance.post('/predictions/racehorses/generate', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response.data;
    }

    // New API Methods

    /**
     * Fetch available meeting dates based on jurisdiction.
     * @param jurisdiction - The selected jurisdiction (e.g., VIC).
     */
    async fetchMeetingDates(jurisdiction: string): Promise<any[]> {
        const response = await this.axiosInstance.get(
            `/api/meetings/dates?jurisdiction=${jurisdiction}`
        );
        return response.data;
    }

    /**
     * Initialize bankroll with specified parameters.
     * @param initialBankroll - The initial bankroll amount.
     * @param profitThreshold - The profit threshold.
     * @param stopLossThreshold - The stop loss threshold.
     */
    async initializeBankroll(
        initialBankroll: number = 200,
        profitThreshold: number = 150,
        stopLossThreshold: number = 50
    ): Promise<any> {
        const response = await this.axiosInstance.post('/bankroll/initialize', null, {
            params: { initialBankroll, profitThreshold, stopLossThreshold }
        });
        return response.data;
    }

    /**
     * Calculate race predictions based on provided parameters.
     * @param jurisdiction - The jurisdiction.
     * @param meetingDate - The meeting date.
     * @param venue - The venue mnemonic.
     * @param raceType - The race type (R/H/G).
     * @param raceNumber - The race number.
     * @param bankrollId - The bankroll ID.
     */
    async calculateRace(
        jurisdiction: string,
        meetingDate: string,
        venue: string,
        raceType: string,
        raceNumber: number,
        bankrollId: string
    ): Promise<any> {
        const response = await this.axiosInstance.get(
            `/labs/punting/race-horsing/${jurisdiction}/${meetingDate}/${venue}/${raceType}/race/${raceNumber}/calculate/${bankrollId}`
        );
        return response.data;
    }

    async fetchMeetingDetails(
        meetingName: string,
        meetingDate: string,
        jurisdiction: string,
        raceType: string
    ) {
        const response = await this.axiosInstance.post(`/api/meetings/details`, {
            meetingName,
            meetingDate,
            jurisdiction,
            raceType,
        });
        return response.data;
    }

    // Betting Endpoint

    /**
     * Place bets using the specified bankroll ID and payload.
     * @param bankrollId - The bankroll ID.
     * @param payload - The bet placement payload.
     */
    async placeBets(bankrollId: string, payload: BetPlacementRequest): Promise<any> {
        const response = await this.axiosInstance.post(`/bankroll/${bankrollId}/place`, payload);
        return response.data;
    }

    async getRaceDetails(venueMnemonic: string, raceNumber: number): Promise<RacePrediction> {
        const response = await this.axiosInstance.get(`/races/${venueMnemonic}/${raceNumber}`);
        if (!response.data) {
            throw new Error('Failed to fetch race details');
        }
        return response.data;
    }

    async fetchRaceByLink(raceLink: string): Promise<any> {
        const response = await this.axiosInstance.get(raceLink);
        return response.data;
    }

    // Add method to subscribe to race updates
    onRaceUpdate(callback: (race: any) => void) {
        const handler = (event: any) => callback(event.detail);
        this.eventEmitter.addEventListener('raceUpdate', handler);
        return () => this.eventEmitter.removeEventListener('raceUpdate', handler);
    }

    async fetchNextToGoRaces(jurisdiction: string = 'VIC'): Promise<any> {
        const today = new Date().toISOString().split('T')[0];
        const raceTypes = ['R', 'G', 'H'];
        const utcNow = new Date().toISOString();
        const processedRaces: any[] = [];

        try {
            for (const raceType of raceTypes) {
                const response = await this.axiosInstance.get(
                    `/api/meetings/${today}/type/${raceType}`, {
                        params: {
                            jurisdiction,
                            pageNumber: 1,
                            pageSize: 50
                        }
                    }
                );

                // Process meetings in parallel
                await Promise.all(response.data.map(async (meeting: any) => {
                    try {
                        const bankrollResponse = await this.initializeBankroll();
                        const predictions = await this.calculateRace(
                            meeting.location,
                            meeting.meetingDate,
                            meeting.venueMnemonic,
                            meeting.raceType,
                            meeting.nextRaceNumber || 1,
                            bankrollResponse.bankrollId
                        );

                        const raceData = {
                            ...predictions,
                            meeting,
                            lastUpdated: utcNow
                        };

                        const raceTime = new Date(raceData.raceStartTime);
                        if (raceTime > new Date(utcNow)) {
                            processedRaces.push(raceData);
                            processedRaces.sort((a, b) => 
                                new Date(a.raceStartTime).getTime() - new Date(b.raceStartTime).getTime()
                            );
                            
                            // Emit the race update immediately
                            this.eventEmitter.dispatchEvent(new CustomEvent('raceUpdate', {
                                detail: {
                                    races: processedRaces.slice(0, 10),
                                    nextStepDescription: "Review the next-to-go races and their predictions."
                                }
                            }));
                        }
                    } catch (error) {
                        console.error(`Error calculating predictions for ${meeting.meetingName}:`, error);
                    }
                }));
            }

            return {
                races: processedRaces.slice(0, 10),
                nextStepDescription: "Review the next-to-go races and their predictions."
            };
        } catch (error) {
            console.error('Error fetching next to go races:', error);
            throw error;
        }
    }

    // Add method to update specific meeting predictions
    async updateMeetingPredictions(meeting: any): Promise<void> {
        const meetingKey = `${meeting.meetingName}-${meeting.meetingDate}-${meeting.raceType}`;
        try {
            const bankrollResponse = await this.initializeBankroll();
            const predictions = await this.calculateRace(
                meeting.location,
                meeting.meetingDate,
                meeting.venueMnemonic,
                meeting.raceType,
                meeting.nextRaceNumber || 1,
                bankrollResponse.bankrollId
            );

            this.calculatedMeetings.set(meetingKey, {
                ...predictions,
                meeting,
                lastUpdated: new Date()
            });
        } catch (error) {
            console.error(`Error updating meeting ${meetingKey}:`, error);
        }
    }

    // Add method to clear old meetings
    clearOldMeetings(): void {
        const now = new Date();
        Array.from(this.calculatedMeetings.entries()).forEach(([key, data]) => {
            const raceTime = new Date(data.raceStartTime);
            if (raceTime < now) {
                this.calculatedMeetings.delete(key);
            }
        });
    }
}

const apiService = new ApiService();
export default apiService;