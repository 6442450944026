import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PortalPage from '../Pages/Portal';
import LoginPage from '../Pages/LoginPage';
import AccountPage from '../Pages/AccountPage';
import DashboardPage from '../Pages/DashboardPage';
import SecureRoute from './SecureRoute/SecureRoute';
import { useAuth } from '../Providers/authContext';
import LiveStreamPage from '../Pages/LiveStreamPage';
import SignupPage from '../Pages/SignupPage';
import AccountSettingsPage from '../Pages/AccountSettingsPage';
import MeetingDateSelector from './Racing/MeetingDateSelector';
import SharedResults from './Racing/SharedResults';
import Simulator from './Racing/algorithm-docs/components/Simulator';

const MainRoutes: React.FC = () => {
    const { isAuthenticated, loading } = useAuth();

    if (loading) return <p>Loading...</p>;

    return (
        <>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/sim" element={<Simulator />} />

                <Route
                    path="/account"
                    element={
                        <SecureRoute requiredRole="User">
                            <AccountPage />
                        </SecureRoute>
                    }
                />

                <Route
                    path="/"
                    element={
                        <SecureRoute requiredRole="User">
                            <DashboardPage />
                        </SecureRoute>
                    }
                />

                <Route
                    path="/account-settings"
                    element={
                        isAuthenticated ? <AccountSettingsPage /> : <Navigate to="/login" replace />
                    }
                />

                <Route path="/live" element={<LiveStreamPage />} />

                <Route path="/select-races" element={<MeetingDateSelector />} />

                <Route path="/shared/:id" element={<SharedResults />} />

                <Route
                    path="*"
                    element={
                        isAuthenticated ? <Navigate to="/" replace /> : <Navigate to="/login" replace />
                    }
                />

                <Route path="/portal" element={<PortalPage />} />
            </Routes>
        </>
    );
};

export default MainRoutes;
