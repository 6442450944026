import React from 'react';
import { useAuth } from '../Providers/authContext';
import MeetingDateSelector from '../Components/Racing/MeetingDateSelector';
import IntroComponent from '../Components/IntroComponent';

const DashboardPage: React.FC = () => {
    const { isAuthenticated, roles, username, logout } = useAuth();

    return (
        <div>
            <MeetingDateSelector />
        </div>
    );
};

export default DashboardPage;
