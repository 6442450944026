import React, { useState } from "react";
import styled, { css } from "styled-components";
import tutorialImage from '../../../tutorial.png';
const FullScreenOverlay = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
`;

const TutorialWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px 20px;
  background: white;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 16px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ff4d4d;
  color: white;
  transition: background-color 0.3s;
  z-index: 3;

  &:hover {
    background-color: #e60000;
  }
`;

const Label = styled.div<{ top: string; left: string }>`
  position: absolute;
  background: rgba(255, 0, 0, 0.9);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  z-index: 2;
  transform: translateY(-50%);
`;

const TutorialImage = styled.div<{ highlight: string | undefined }>`
  position: relative;
  img {
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
  }

  .highlight-box {
    position: absolute;
    border: 2px solid red;
    border-radius: 5px;
    background: rgba(255, 0, 0, 0.1);
  }

  ${({ highlight }) =>
    highlight === "horse-number" &&
    css`
      .horse-number {
        top: 8%;
        left: 3%;
        width: 8%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "horse-name" &&
    css`
      .horse-name {
        top: 8%;
        left: 12%;
        width: 45%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "jockey-silks" &&
    css`
      .jockey-silks {
        top: 8%;
        right: 3%;
        width: 8%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "speed-metric" &&
    css`
      .speed-metric {
        top: 22%;
        left: 3%;
        width: 28%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "consistency-metric" &&
    css`
      .consistency-metric {
        top: 22%;
        left: 33%;
        width: 20%;
        height: 10%;
        border: 2px solid red;
        border-radius: 5px;
        background: rgba(255, 0, 0, 0.1);
      }
    `}

  ${({ highlight }) =>
    highlight === "competition-metric" &&
    css`
      .competition-metric {
        top: 22%;
        left: 63%;
        width: 28%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "conditions" &&
    css`
      .conditions {
        top: 36%;
        left: 3%;
        width: 90%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "ev-metric" &&
    css`
      .ev-metric {
        top: 50%;
        left: 3%;
        width: 28%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "probability" &&
    css`
      .probability {
        top: 50%;
        right: 3%;
        width: 28%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "stability" &&
    css`
      .stability {
        top: 50%;
        left: 33%;
        width: 28%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "market-activity" &&
    css`
      .market-activity {
        top: 75%;
        left: 3%;
        width: 90%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "view-form" &&
    css`
      .view-form {
        top: 64%;
        left: 3%;
        width: 90%;
        height: 12%;
      }
    `}

  ${({ highlight }) =>
    highlight === "gold-card" &&
    css`
      .gold-card {
        top: 0;
        left: 3%;
        width: 30%;
        height: 100%;
        border: 2px solid gold;
        background: rgba(255, 215, 0, 0.1);
      }
    `}

  ${({ highlight }) =>
    highlight === "green-card" &&
    css`
      .green-card {
        top: 0;
        left: 35%;
        width: 30%;
        height: 100%;
        border: 2px solid #4CAF50;
        background: rgba(76, 175, 80, 0.1);
      }
    `}

  ${({ highlight }) =>
    highlight === "white-card" &&
    css`
      .white-card {
        top: 0;
        right: 3%;
        width: 30%;
        height: 100%;
        border: 2px solid #ccc;
        background: rgba(204, 204, 204, 0.1);
      }
    `}

  ${({ highlight }) =>
    highlight === "value-bet" &&
    css`
      .value-bet {
        top: 35%;
        left: 75%;
        width: 15%;
        height: 8%;
        border: 2px solid red;
        border-radius: 5px;
        background: rgba(255, 0, 0, 0.1);
      }
    `}
`;

const StepContent = styled.div`
  margin-top: 30px;
  text-align: center;
  max-width: 800px;
  width: 100%;
  padding: 0 20px;

  h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #333;
  }

  p {
    font-size: 1.3rem;
    line-height: 1.5;
    margin-bottom: 25px;
    color: #555;
  }
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
  max-width: 400px;
  padding: 0 20px;

  button {
    padding: 12px 24px;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
    min-width: 120px;

    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
`;

// Add interface for the step type
interface TutorialStep {
  title: string;
  description: string;
  highlight: string | undefined;
  label?: string;
  arrow?: {
    top: string;
    left: string;
    rotation?: string;
  };
}

const RacingTutorial: React.FC<{ isVisible: boolean; onClose: () => void }> = ({ isVisible, onClose }) => {
  const [step, setStep] = useState(0);

  // Update the steps array with corrected descriptions for card colors
  const steps: TutorialStep[] = [
    {
      title: "Welcome to the Racing Metrics Tutorial!",
      description: "This guide will help you understand every detail of the racing card. Click 'Next' to begin.",
      highlight: undefined,
    },
    {
      title: "Gold Card - Verified Predictions",
      description: "Gold cards showcase our past successful predictions. These are races where Taby correctly predicted the horse would finish in positions 1-4. They're shown to demonstrate our prediction accuracy and build confidence in our system.",
      highlight: "gold-card",
      label: "Verified Prediction",
      arrow: { top: "50%", left: "1%", rotation: "rotate(-45deg)" }
    },
    {
      title: "Green Card - Current Predictions",
      description: "Green cards show Taby's current predictions for upcoming races. These are horses that our algorithm predicts will perform well, but the race hasn't happened yet.",
      highlight: "green-card",
      label: "Current Prediction",
      arrow: { top: "50%", left: "33%", rotation: "rotate(-45deg)" }
    },
    {
      title: "White Card - Other Runners",
      description: "White cards represent other horses in the race that Taby isn't specifically highlighting for performance. While these horses may still win, they don't meet our prediction criteria.",
      highlight: "white-card",
      label: "Other Runner",
      arrow: { top: "50%", left: "65%", rotation: "rotate(-45deg)" }
    },
    {
      title: "Horse Number",
      description: "The number (1) in the top-left corner is the horse's racing number. This is what you'll see on the horse during the race.",
      highlight: "horse-number",
      label: "Racing Number",
      arrow: { top: "8%", left: "1%", rotation: "rotate(-45deg)" }
    },
    {
      title: "Horse Name and Jockey",
      description: "SMOKESCREEN ridden by OLIVIA WILDENBACH - This shows the horse's name and the jockey who will be riding in this race.",
      highlight: "horse-name",
      label: "Horse & Jockey",
      arrow: { top: "8%", left: "15%", rotation: "rotate(0deg)" }
    },
    {
      title: "Jockey Silks",
      description: "The colored icon represents the jockey's racing silks - the unique colors and patterns they wear during the race.",
      highlight: "jockey-silks",
      label: "Jockey Colors",
      arrow: { top: "8%", left: "90%", rotation: "rotate(45deg)" }
    },
    {
      title: "Speed Rating",
      description: "Speed (1.00) shows the horse's raw speed capability. A value of 1.00 is average, higher values indicate faster horses.",
      highlight: "speed-metric",
      label: "Speed",
      arrow: { top: "22%", left: "5%", rotation: "rotate(-45deg)" }
    },
    {
      title: "Consistency Rating",
      description: "Consistency (0.60) indicates how reliably the horse performs. Higher values mean more predictable performance.",
      highlight: "consistency-metric",
      label: "Consistency",
      arrow: { top: "22%", left: "38%", rotation: "rotate(0deg)" }
    },
    {
      title: "Competition Level",
      description: "Competition (25.9) shows the quality of opponents this horse typically faces. Higher values indicate stronger competition.",
      highlight: "competition-metric",
      label: "Competition",
      arrow: { top: "22%", left: "65%", rotation: "rotate(45deg)" }
    },
    {
      title: "Race Conditions",
      description: "Shows optimal distance and track conditions. The checkmark indicates favorable conditions for this horse.",
      highlight: "conditions",
      label: "Conditions",
      arrow: { top: "36%", left: "5%", rotation: "rotate(0deg)" }
    },
    {
      title: "Expected Value",
      description: "EV (47.2%) represents the predicted return on investment. Higher values suggest better betting opportunities.",
      highlight: "ev-metric",
      label: "EV",
      arrow: { top: "50%", left: "5%", rotation: "rotate(-45deg)" }
    },
    {
      title: "Win Probability",
      description: "Shows the calculated probability (24.8%) of this horse winning the race.",
      highlight: "probability",
      label: "Win Probability",
      arrow: { top: "50%", left: "65%", rotation: "rotate(45deg)" }
    },
    {
      title: "Stability Rating",
      description: "Stability (1.8) indicates how reliable our predictions are for this horse. Higher values mean more confident predictions.",
      highlight: "stability",
      label: "Stability",
      arrow: { top: "50%", left: "35%", rotation: "rotate(0deg)" }
    },
    {
      title: "Market Activity",
      description: "Volume (-2%) and Pro Money (100%) show betting market activity. Pro Money indicates professional bettor involvement.",
      highlight: "market-activity",
      label: "Market Indicators",
      arrow: { top: "75%", left: "5%", rotation: "rotate(-45deg)" }
    },
    {
      title: "Form History",
      description: "Click 'View Form' to see detailed race history and past performance data for this horse.",
      highlight: "view-form",
      label: "Historical Data",
      arrow: { top: "64%", left: "45%", rotation: "rotate(0deg)" }
    },
    {
      title: "Value Bet Indicator",
      description: "When you see 'Win $1.65', it means Taby has identified this as a high-value betting opportunity. This shows the potential return on a $1 bet, and its presence indicates that our algorithm has high confidence in this prediction having positive expected value.",
      highlight: "value-bet",
      label: "Value Bet",
      arrow: { top: "35%", left: "80%", rotation: "rotate(0deg)" }
    },
    {
      title: "Ready to Bet!",
      description: "You now understand all the key metrics! Use this information to make informed betting decisions. Good luck!",
      highlight: undefined
    }
  ];

  // Add a helper function to safely check string includes
  const safeIncludes = (text: string | undefined, searchStr: string): boolean => {
    return text ? text.includes(searchStr) : false;
  };

  return (
    <FullScreenOverlay isVisible={isVisible}>
      <TutorialWrapper>
        <CloseButton onClick={onClose}>×</CloseButton>
        <TutorialImage highlight={steps[step].highlight}>
          <img src={tutorialImage} alt="Racing Metrics" />
          {steps[step].highlight && (
            <>
              <div className={`highlight-box ${steps[step].highlight}`} />
              {steps[step].label && (
                <Label
                  top={safeIncludes(steps[step].highlight, 'metric') ? '50%' : '0'}
                  left={safeIncludes(steps[step].highlight, 'left') ? '0' : '50%'}
                >
                  {steps[step].label}
                </Label>
              )}
            </>
          )}
        </TutorialImage>

        <StepContent>
          <h2>{steps[step].title}</h2>
          <p>{steps[step].description}</p>
        </StepContent>

        <NavigationButtons>
          <button onClick={() => setStep(step - 1)} disabled={step === 0}>
            Previous
          </button>
          <button
            onClick={() => setStep(step + 1)}
            disabled={step === steps.length - 1}
          >
            Next
          </button>
        </NavigationButtons>
      </TutorialWrapper>
    </FullScreenOverlay>
  );
};

export default RacingTutorial;
